import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "antd";
import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FilterModal = ({ children, open = false, onCancel }) => {
  return (
    <Modal className="custom-modal" footer={<></>}>
      <h2 className="text-lg flex items-center space-x-4">
        <FontAwesomeIcon
          className="bg-yellow-400 p-2 rounded-lg"
          icon={faFilter}
        />{" "}
        <span>Filter</span>
      </h2>
      <br />
      {children}
    </Modal>
  );
};

// FilterModal.prototype = {
//   open: PropTypes.bool,
//   onCancel: PropTypes.func,
// };

export default FilterModal;
