import { combineReducers } from "redux";
import bookServicesNowReducer from "./reducers/bookNowServicesReducer";
import serviceWeekdaysReducer from "./reducers/serviceWeekdaysReducer";
import searchedEntityServices from "./reducers/searchPageServicesReducer";

// Updated Slices
import entitySlice from "./slices/entity.slice";
import serviceSlice from "./slices/service.slice";
import scheduleSlice from "./slices/schedule.slice";
import dashboardSlice from "./slices/dashboard.slice";
import bookingSlice from "./slices/booking.slice";
import dependantSlice from "./slices/dependant.slice";
import appSlice from "./slices/app.slice";
import authSlice from "./slices/auth.slice";
import taskSlice from "./slices/task.slice";
import paymentSlice from "./slices/payment.slice";

const rootReducer = combineReducers({
  app: appSlice,
  auth: authSlice,
  bookNowService: bookServicesNowReducer,
  serviceWeekdays: serviceWeekdaysReducer,
  searchedEntityServices,
  booking: bookingSlice,
  dashboard: dashboardSlice,
  dependant: dependantSlice,
  entity: entitySlice,
  schedule: scheduleSlice,
  service: serviceSlice,
  task: taskSlice,
  payment: paymentSlice,
});

export default rootReducer;
