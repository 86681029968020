import {
  CLEAR_BOOKING_FIELDS,
  FETCH_BOOK_NOW_SERVICES_FAILED,
  FETCH_BOOK_NOW_SERVICES_REQUEST,
  FETCH_BOOK_NOW_SERVICES_SUCCESS,
  FETCH_TIME_SLOTS_FAILED,
  FETCH_TIME_SLOTS_REQUEST,
  FETCH_TIME_SLOTS_SUCCESS,
  SET_BOOKINGS_TRAVEL_INFO,
  SET_BOOKING_DATE_SELECTED,
  SET_BOOKING_RESULT,
  SET_TIMESLOT_CHOSEN,
} from "../actionCreators/bookNowActionCreator";

const initialState = {
  loading: false,
  timeSlotsLoading: false,
  timeSlots: [],
  data: [],
  timeslotChosen: null,
  bookingsTravelInfo: {},
  bookingSelectedTime: null,
  bookingResult: {},
};

const bookNowServicesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_BOOK_NOW_SERVICES_REQUEST:
      return { ...state, loading: true, data: [] };
    case FETCH_BOOK_NOW_SERVICES_SUCCESS:
      return { ...state, loading: false, data: payload };
    case FETCH_BOOK_NOW_SERVICES_FAILED:
      return { ...state, loading: false };

    case FETCH_TIME_SLOTS_REQUEST:
      return {
        ...state,
        timeSlotsLoading: true,
      };
    case FETCH_TIME_SLOTS_SUCCESS:
      return {
        ...state,
        timeSlotsLoading: false,
        timeSlots: payload,
      };
    case FETCH_TIME_SLOTS_FAILED:
      return {
        ...state,
        timeSlotsLoading: false,
        timeSlots: [],
      };
    case SET_TIMESLOT_CHOSEN:
      return {
        ...state,
        timeslotChosen: payload,
      };
    case SET_BOOKINGS_TRAVEL_INFO:
      return {
        ...state,
        bookingsTravelInfo: payload,
      };
    case SET_BOOKING_DATE_SELECTED:
      return {
        ...state,
        bookingSelectedTime: payload,
      };
    case SET_BOOKING_RESULT:
      return {
        ...state,
        bookingResult: payload,
      };
    case CLEAR_BOOKING_FIELDS:
      return {
        ...state,
        timeslotChosen: null,
        bookingsTravelInfo: {},
        timeSlots: [],
      };
    default:
      return state;
  }
};

export default bookNowServicesReducer;
