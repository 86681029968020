import moment from "moment";
import dateTimeFormat from "date-format";

export const getTime = (time) => {
  return moment(time, " HH:ssm:d").format("LT");
};

export const getCalDate = (date) => {
  return moment(date).format("LL");
};
export const getRawDayMonthYear = (date) => {
  return dateTimeFormat.asString("dd-MM-yyyy", new Date(date));
};

export const getStartTimeFromFullString = (time) => {
  const startTime = time?.split("-")[0].trim();
  return getTime(startTime);
};
export const getEndTimeFromFullString = (time) => {
  const endTime = time?.split("-")[1].trim();
  return getTime(endTime);
};

export const getBookingEntityTimeSlot = (time) => {
  const startTime = time?.split("-")[0].trim();
  const endTime = time?.split("-")[1].trim();
  return `${getTime(startTime)} → ${getTime(endTime)} `;
};

export const toDateString = (date) => {
  if (!date) return;
  return new Date(date).toDateString();
};

export const getFullDateWithTime = (date) => {
  const newDate = new Date(date)
  return new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    minute: "numeric",
    hour: "numeric",
  }).format(newDate)
};
