import { createSlice } from "@reduxjs/toolkit";
import getExtraReducers from "../extraReducersCreator";
import authThunks from "../services/auth.service";
import { HTTPStatusType } from "../../utils/http_status_type";

const initialState = {
  user: null,
  loading: false,
  sessionHasExpired: false,
  thunkRequestStatus: HTTPStatusType.IDLE,
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setSessionExpired: (state, { payload }) => {
      state.sessionHasExpired = payload ?? false;
    },
  },
  extraReducers: getExtraReducers(authThunks),
});

export const { setSessionExpired } = authSlice.actions;

export const getAuthState = (state) => state.auth;

export default authSlice.reducer;
