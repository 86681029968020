import { useEffect, useState } from "react";
import { getUsername } from "../persistent_data/local_storage";

const useUserEmail = () => {
  const [profile, setProfile] = useState();
  useEffect(() => {
    setProfile(getUsername());
  }, []);
  return profile;
};

export default useUserEmail;
