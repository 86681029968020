import CasClient, { constant } from "react-cas-client";
import envConfigs from "../configs/env.config";

const useLogout = () => {
  const casClient = new CasClient(
    envConfigs[process.env.REACT_APP_ENV || "development"].casUrl,
    {
      version: constant.CAS_VERSION_2_0,
    }
  );
  return () => {
    localStorage.removeItem("userProfile");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("username");
    localStorage.removeItem("sessionId");
    casClient.logout("/");
  };
};

export default useLogout;
