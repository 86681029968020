import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { APIClient } from "../../services/api";

const useOrganizationServices = () => {
  const [services, setServices] = React.useState({
    list: [],
    paginated: [],
  });
  const [loading, setLoading] = React.useState(false);
  const { organizationId } = useParams();
  const fetchServices = useCallback(async () => {
    const api = new APIClient();
    setLoading(true);
    try {
      const response = await api.get(
        `/intel/fetch/lab/services/by/lab/registration/number/?reg_number=${organizationId}`
      );
      setLoading(false);
      setServices((prev) => ({
        ...prev,
        list: response.data.data,
        paginated: response.data.data?.slice(0, 10),
      }));
      // console.log(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
      return [{ services: [], loading }];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);
  useEffect(() => {
    fetchServices();
    // console.log(services);
  }, [fetchServices]);

  const onPaginate = (pageIndex) => {
    const dataIndex = pageIndex * 10;
    setServices((prev) => ({
      ...prev,
      paginated: prev.list.slice(dataIndex - 10, dataIndex),
    }));
  };
  return [services, loading, onPaginate];
};

export default useOrganizationServices;
