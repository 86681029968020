import React, { useCallback, useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, Rate, Slider, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { fetchEntityByCountryThunk } from "../../store/thunks/entity.thunk";
import countries from "../../data/country-by-abbreviation";
import searchCurrencySliderLabel from "../../utils/searchCurrencySliderLabel";
import { getUserIpLocation } from "../../utils/persistent_data/local_storage";

import { fetchEntityServicesThunk } from "../../store/thunks/service.thunk";
import useCustomSelector from "../../utils/custom_hooks/useCustomSelector";
function ServiceSearchEngine({ onComplete = () => {} }) {
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState("");
  const entityState = useCustomSelector((state) => state.entity);

  const marks = React.useMemo(
    () => searchCurrencySliderLabel(currency),
    [currency]
  );

  const getEntitiesByCountry = useCallback(
    (country_code) => {
      dispatch(fetchEntityByCountryThunk(country_code));
    },
    [dispatch]
  );

  const onSearchHandler = (values) => {
    dispatch(
      fetchEntityServicesThunk({
        service_name: values?.service_name,
        type: values?.type,
        external_entity_name: values?.external_entity_name,
        external_entity_country: values?.external_entity_country,
        rate: values?.rate,
        city: values?.city,
        min: values?.price[0],
        max: values?.price[1],
      })
    );
    onComplete();
  };

  useEffect(() => {
    const userLocation = getUserIpLocation();
    if (userLocation?.country_code !== null) {
      getEntitiesByCountry(userLocation?.country_code);
    }
  }, [getEntitiesByCountry]);

  return (
    <Form
      layout="vertical"
      onFinish={onSearchHandler}
      size="large"
      className="py-5 px-4 w-full"
    >
      <div className="grid gap-4 grid-cols-1 lg:grid-cols-2">
        <Form.Item name="service_name" label="Search By Service Name">
          <Input name="service_name" placeholder="Service name" />
        </Form.Item>
        <Form.Item initialValue={"all"} name="type" label="Filter By Type">
          <Select
            placeholder="Select Type"
            options={[{ value: "all", label: "All" }]}
          />
        </Form.Item>
        <Form.Item
          initialValue={"all"}
          label="Filter By Country"
          name="external_entity_country"
        >
          <Select
            onSelect={(value) => {
              const countryCode = countries.find(
                (country) => country.country === value
              );
              getEntitiesByCountry(countryCode?.abbreviation);
              setCurrency(countryCode.currencyCode);
            }}
            placeholder="Select country of service "
          >
            <Select.Option value="all">All Countries Selected</Select.Option>
            {countries.map((country, index) => (
              <Select.Option key={index.toString()} value={country.country}>
                {country.country} ({country.abbreviation})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          initialValue={"all"}
          name="entity"
          label="Filter Organization Name"
        >
          <Select
            loading={entityState.loading}
            placeholder="Select Organization"
          >
            <Select.Option value="all">
              All Organizations Selected
            </Select.Option>
            {entityState.state?.organizationsByCountry?.map((entity, index) => (
              <Select.Option
                key={index.toString()}
                value={JSON.stringify(entity)}
              >
                {entity?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          initialValue={"all"}
          name="currency"
          label="Filter By Currency"
        >
          <Select
            placeholder="Select All County's Currency"
            onSelect={(value) => {
              setCurrency(value);
            }}
          >
            <Select.Option value="all">All Currency Selected</Select.Option>
            {countries.map((country, index) => (
              <Select.Option key={index} value={country.currencyCode}>
                {country.country} ({country.currencyCode})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item initialValue={currency} label="City" name="city">
          <Input />
        </Form.Item>
        <Form.Item
          name="rating"
          label="Filter By Rating"
          className="no-border bg-none"
        >
          <Rate count={5} />
        </Form.Item>

        <Form.Item
          name="price"
          label="Filter By Price range"
          className="no-border bg-none"
          initialValue={[0, 10000]}
        >
          <Slider
            disabled={!currency}
            range
            marks={marks}
            max={10000}
            min={0}
          />
        </Form.Item>
      </div>
      <Button
        className="btn btn-primary"
        type="primary"
        block
        htmlType="submit"
        icon={<SearchOutlined />}
      >
        Search
      </Button>
    </Form>
  );
}

export default ServiceSearchEngine;
