import { APIClient } from "./api";

export default class BookingService extends APIClient {
  constructor() {
    super();
    this.addAccessToken();
  }
  getExternalEntityBookings = async (entityId) => {
    return await this.get(
      `/booking/manager/koldchain/organization/${entityId}/booking/list/`
    );
  };

  getClientBookingEntityServicesList = async (queryParams) =>
    await this.get(
      this.appendQueryToEndpoint(
        queryParams,
        "booking/client/list/entity/services"
      )
    );

  clientCreateBookingService = async (payload) =>
    this.post("/booking/client/create/booking/", payload);

  getOrganizations = () =>
    this.get("/booking/manager/koldchain/organizations/list/");

  markBookingAsAttended = async (bookingID) =>
    this.get(`/booking/manager/mark/booking/${bookingID}/attended/`);

  // getExternalEntityDetailInfo = async (entityId) => {
  //   return await this.get(
  //     `/intel/fetch/lab/by/registration/number/?reg_number=${entityId}`
  //   );
  // };
}
