import { createSlice } from "@reduxjs/toolkit";
import { HTTPStatusType } from "../../utils/http_status_type";
import getExtraReducers from "../extraReducersCreator";
import * as thunks from "../thunks/entity.thunk";

const initialState = {
  thunkRequestStatus: HTTPStatusType.IDLE,
  organizations: [],
  organizationsByCountry: [],
};

const extraReducers = getExtraReducers(thunks);
const entitySlice = createSlice({
  name: "entitySlice",
  initialState,
  reducers: {
    setOrganisationEntities: (state, { payload }) => {
      state.organizations = [...payload];
    },
    setFetchedOrganisationByCountry: (state, { payload }) => {
      state.organizationsByCountry = payload;
    },
  },
  extraReducers: extraReducers,
  // extraReducers: {
  //   // PENDING
  //   [fetchEntityThunk.pending.type]: (state, action) => {
  //     state.fetchingStatus = HTTPStatusType.PENDING
  //     state.loading = true
  //   },

  //   // FULFILLED
  //   [fetchEntityThunk.fulfilled.type]: (state, {payload}) => {
  //     state.fetchingStatus = HTTPStatusType.FULFILED
  //     state.loading = false
  //     state.list = payload
  //   },

  //   // REJECTED
  //   [fetchEntityThunk.rejected.type]: (state, action) => {
  //     state.fetchingStatus = HTTPStatusType.REJECTED
  //     state.loading = false
  //   },
  // }
});

export const getOrganizations = (state) => state.entity?.organizations;
export const { setOrganisationEntities, setFetchedOrganisationByCountry } =
  entitySlice.actions;
export default entitySlice.reducer;
