import React, { useEffect, useState } from "react";
import ContentWrapper from "@components/ContentWrapper";
import { Button, Form, Input, Modal, Select, Switch, Table } from "antd";
import CustomButton from "@components/form/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentState } from "../../store/slices/payment.slice";
import {
  createCommissionThunk,
  listCommissionsThunk,
} from "../../store/thunks/payment.thunk";
import countries from "@data/country-by-abbreviation";

const getCountryOptions = countries.map((country, index) => ({
  label: country.country,
  value: country.abbreviation,
}));

const CommissionRate = () => {
  const [form] = Form.useForm();
  const paymentState = useSelector(getPaymentState);
  const dispatch = useDispatch();
  const [allCountries, toggleSelectAllCountries] = useState(true);
  const [openModal, setModal] = useState(false);

  const handleCloseModal = () => setModal(false);

  const handleFinish = (values) => {
    dispatch(
      createCommissionThunk({
        ...values,
        country: allCountries ? "all" : values?.country,
        service_type: "Test",
      })
    )
      .unwrap()
      .then((res) => {
        form.resetFields();
        setModal(false);
      });
  };

  const columns = [
    {
      title: "Commission Rate(%)",
      dataIndex: "commission_percentage",
      key: "commission_percentage",
    },
    {
      title: "Commission Applied Country(s)",
      dataIndex: "country",
      key: "country",
    },
  ];

  const validateCommission = (rule, value) => {
    if (value > 100) {
      // eslint-disable-next-line no-undef
      return Promise.reject("Commission should be less than 100.");
    } else {
      // eslint-disable-next-line no-undef
      return Promise.resolve();
    }
  };

  useEffect(() => {
    dispatch(listCommissionsThunk());
  }, [dispatch]);

  return (
    <ContentWrapper title="Commission Rate">
      <Button className="btn btn-sm btn-primary" onClick={() => setModal(true)}>
        Add Commission Rate
      </Button>
      <br />
      <br />
      <Table
        columns={columns}
        dataSource={paymentState.commission}
        loading={paymentState.loading}
      />
      <Modal
        onCancel={handleCloseModal}
        className="custom-modal"
        open={openModal}
        footer={<></>}
      >
        <h3 className="text-2xl font-bold">Commision Rate</h3>
        <br />
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <Form.Item
            label="Default Commission Rate (%)"
            name="commission"
            rules={[
              { required: true },
              {
                validator: validateCommission,
              },
            ]}
          >
            <Input type="number" placeholder="Enter rate for commission" />
          </Form.Item>

          <div className="flex flex-row justify-between pb-5">
            <h2>Apply commission to all countries</h2>
            <Switch
              className="switch"
              checked={allCountries}
              onChange={(checked) => toggleSelectAllCountries(checked)}
            />
          </div>

          {!allCountries && (
            <Form.Item label="Apply Commission Rate to" name="country">
              <Select
                options={[
                  { label: "Select country", value: "", disabled: true },
                  ...getCountryOptions,
                ]}
              />
            </Form.Item>
          )}

          <CustomButton type="submit" title="Create" />
        </Form>
      </Modal>
    </ContentWrapper>
  );
};

export default CommissionRate;
