import { HTTPStatusType } from "../utils/http_status_type";

const getExtraReducers = (thunks) => {
  let _thunks = {};
  Object.keys(thunks).forEach((value, index) => {
    _thunks[thunks[value].pending.type] = (state, action) => {
      state.thunkRequestStatus = HTTPStatusType.PENDING;
      state.loading = true;
    };
    _thunks[thunks[value].fulfilled.type] = (state, action) => {
      state.thunkRequestStatus = HTTPStatusType.FULFILED;
      state.loading = false;
    };
    _thunks[thunks[value].rejected.type] = (state, { payload }) => {
      state.thunkRequestStatus = HTTPStatusType.REJECTED;
      state.loading = false;
    };
  });
  return _thunks;
};
export default getExtraReducers;
