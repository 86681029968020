import React, { useEffect } from "react";
import { Table } from "antd";
import { useDispatch } from "react-redux";
import { Archive } from "react-bootstrap-icons";
import { useState } from "react";
import ArchivePromptModal from "@components/modals/ArchievePromptModal";
import useCustomSelector from "@utils/custom_hooks/useCustomSelector";
import { fetchApprovedEntityServicesThunk } from "@store/thunks/service.thunk";
import ContentWrapper from "@components/ContentWrapper";

const ListApprovedServices = () => {
  const serviceState = useCustomSelector((state) => state.service);
  const dispatch = useDispatch();


  const [currentItemToDelete, setCurrentItemToDelete] = useState(null);

  const columns = [
    {
      title: "Tests",
      dataIndex: "test",
      key: "id",
      render: (test) => <>{`${test?.name} - ${test?.type}`}</>,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "id",
    },
    {
      title: "Charge",
      dataIndex: "price",
      key: "id",
    },
    {
      title: "Actions",
      key: "actions",
      render: (value) => (
        <div>
          <Archive
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => setCurrentItemToDelete(value)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchApprovedEntityServicesThunk());
  }, [dispatch]);

  return (
    <>
      <ArchivePromptModal
        onClose={() => setCurrentItemToDelete(null)}
        visible={Boolean(currentItemToDelete)}
        serviceId={currentItemToDelete?.service_id}
      />
      <ContentWrapper hasContainer
      title='List of Services'
        loading={serviceState.loading}
      >
          <Table
            columns={columns}
            dataSource={serviceState.state.entity.approved}
            pagination={{ pageSize: 20 }}
          />
      
      </ContentWrapper>
    </>
  );
};

export default ListApprovedServices;
