import { Button, Modal, Typography } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { markBookingAsPaidThunk } from "../../store/thunks/booking.thunk";

const MarkAsPaidModal = ({ booking, isVisible, onClose }) => {
  const dispatch = useDispatch();

  const handleMarkAsPaid = async () => {
    onClose();
    dispatch(markBookingAsPaidThunk(booking.id));
  };
  return (
    <Modal visible={isVisible} onCancel={onClose} footer={null}>
      <div className="d-flex justify-content-center">
        <Typography>Are you sure you want to mark as paid?</Typography>
      </div>
      <div
        style={{ columnGap: "1em" }}
        className="d-flex justify-content-center mt-2"
      >
        <Button onClick={handleMarkAsPaid} type="primary">
          Yes
        </Button>
        <Button onClick={onClose}>No</Button>
      </div>
    </Modal>
  );
};

export default MarkAsPaidModal;
