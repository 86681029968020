export const FETCH_SCHEDULE_WEEK_DAYS_LOADING =
  "FETCH_SCHEDULE_WEEK_DAYS_LOADING";
export const FETCH_SCHEDULE_WEEK_DAYS_SUCCESS =
  "FETCH_SCHEDULE_WEEK_DAYS_SUCCESS";
export const FETCH_SCHEDULE_WEEK_DAYS_FAILUIRE =
  "FETCH_SCHEDULE_WEEK_DAYS_FAILUIRE";

export const FETCH_SCHEDULE_TIME_SLOTS_LOADING =
  "FETCH_SCHEDULE_TIME_SLOTS_LOADING";
export const FETCH_SCHEDULE_TIME_SLOTS_SUCCESS =
  "FETCH_SCHEDULE_TIME_SLOTS_SUCCESS";
export const FETCH_SCHEDULE_TIME_SLOTS_FAILUIRE =
  "FETCH_SCHEDULE_TIME_SLOTS_FAILUIRE";
