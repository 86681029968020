import {
  PAGINATE_SEARCH_ENTITY_SERVICES,
  SEARCH_ENTITY_SERVICES_FAILED,
  SEARCH_ENTITY_SERVICES_REQUEST,
  SEARCH_ENTITY_SERVICES_SUCCESS,
} from "../actionCreators/searchFeaturedServicesCreator";

const initialState = {
  loading: false,
  list: [],
  paginated: [],
};

const searchPageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_ENTITY_SERVICES_REQUEST:
      return { ...state, list: [], paginated: [], loading: true };
    case SEARCH_ENTITY_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: payload,
        paginated: payload.slice(0, 10),
      };
    case SEARCH_ENTITY_SERVICES_FAILED:
      return {
        ...state,
        loading: false,
        list: [],
        paginated: [],
      };

    case PAGINATE_SEARCH_ENTITY_SERVICES: {
      const dataIndex = 10 * payload;
      return {
        ...state,
        paginated: state.list.slice(dataIndex - 10, dataIndex),
      };
    }

    default:
      return state;
  }
};
export default searchPageReducer;
