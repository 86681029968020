import {
  Button,
  Select,
  Form,
  Col,
  Row,
  Collapse,
  Input,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import ViewModal from "@components/modals/ViewModal";
import ContentWrapper from "../../components/ContentWrapper";
import QrCodeScanner from "../../components/modals/QrCodeScanner";
import useCustomSelector from "../custom_hooks/useCustomSelector";
import exportFromJSON from "export-from-json";
import { ScanOutlined } from "@ant-design/icons";
import { priceRange } from "../bookingsFilter.utils";
import { fetchFeaturedServicesThunk } from "../../store/thunks/service.thunk";
import {
  filteringClientBookings,
  resetFilteringBookingHistory,
  searchBookingByBookedCode,
} from "../../store/slices/booking.slice";

function BookingFilterSearchHOC(WrappedComponent, title) {
  const View = () => {
    const serviceState = useCustomSelector((state) => state.service);
    const bookingState = useCustomSelector((state) => state.booking);

    const dispatch = useDispatch();
    const [currentBooking, setCurrentBooking] = useState(null);
    const [scanningCurrentBooking, setScanningCurrentBooking] = useState(false);
    const [codeSearch, setCodeSearch] = useState("");
    const [filterParameter, setFilterParameter] = useState({
      service_status: null,
      service_name: null,
      client_name: null,
      service_types: [],
      price_ranges: [],
      client_email: null,
    });

    const handleFileDownload = () => {
      exportFromJSON({
        data: dataToExport,
        fileName: title.replaceAll(" ", "_"),
        exportType: exportFromJSON.types.xls,
      });
    };
    const dataToExport = useMemo(() => {
      return bookingState.state.list.map((booking) => ({
        "Service Name": booking.booked_entity_service?.service_name,
        "Service Type": booking.booked_entity_service?.service,
        "Booking code": booking.code,
        "Client Name": `${booking.booked_by?.firstName} ${booking.booked_by?.lastName}`,
        Currency: booking.booked_entity_service?.price?.currency,
        Price: booking.booked_entity_service?.price?.amount,
        "Has paid": booking.payed,
      }));
    }, [bookingState.state.list]);

    const handleClose = () => {
      setCurrentBooking(null);
    };
    const handleCloseScanner = () => {
      setScanningCurrentBooking(false);
    };
    const handleScanOpen = () => {
      setScanningCurrentBooking(true);
    };
    const handleScan = (value) => {
      setCodeSearch(value);
      dispatch(searchBookingByBookedCode(value));
    };
    const onSearch = (e) => {
      setCodeSearch(e.target.value);
      dispatch(searchBookingByBookedCode(e.target.value));
    };
    const filterHandler = useCallback(
      (extraParams) => {
        const params = {
          ...filterParameter,
          ...extraParams,
        };
        setFilterParameter(params);
        dispatch(filteringClientBookings(params));
      },
      [dispatch, filterParameter]
    );

    const handleSelectorChange = (key, value) =>
      filterHandler({ [key]: value });

    const handleInputsChange = (e) => {
      filterHandler({ [e.target.name]: e.target.value });
    };

    const handleClearFilter = useCallback(() => {
      setFilterParameter({
        service_status: null,
        service_name: null,
        client_name: null,
        service_types: [],
        price_ranges: [],
        client_email: null,
      });
      dispatch(resetFilteringBookingHistory());
    }, [dispatch]);

    // useEffect(() => {
    //   dispatch(fetchFeaturedServicesThunk());
    //   return () => {
    //     handleClearFilter();
    //   };
    // }, [dispatch, handleClearFilter]);
    return (
      <>
        <ContentWrapper title={title} hasContainer>
          {/* <div className="d-flex justify-content-end py-2">
            <Button onClick={handleFileDownload}>Down Excel File</Button>
          </div> */}
          {/* <Collapse accordion>
            <Collapse.Panel header="Search bar">
              <Row
                align="center"
                justify="center"
                style={{ columnGap: "1em", rowGap: "1em" }}
              >
                <Col xs={24} lg={10}>
                  <Input.Search
                    value={codeSearch}
                    placeholder="Search by booking code"
                    onChange={onSearch}
                  />
                </Col>
                <Col xs={24} lg={2}>
                  <Typography.Title className="text-center" level={4}>
                    OR
                  </Typography.Title>
                </Col>
                <Col xs={24} lg={11}>
                  <Button
                    onClick={handleScanOpen}
                    style={{ width: "100%" }}
                    icon={<ScanOutlined style={{ fontSize: "1.2rem" }} />}
                  >
                    Scan QR-Code
                  </Button>
                </Col>
              </Row>
            </Collapse.Panel>
            <Collapse.Panel header="Filter bar">
              <Form>
                <Row gutter={[14, 14]}>
                  <Col xs={12} sm={11} md={12}>
                    <Form.Item>
                      <Input
                        value={filterParameter.service_name}
                        name="service_name"
                        onChange={handleInputsChange}
                        placeholder="Search by Service name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={11} md={12}>
                    <Form.Item>
                      <Select
                        onChange={(value) =>
                          handleSelectorChange("service_types", value)
                        }
                        value={filterParameter.service_types}
                        mode="multiple"
                        loading={serviceState.loading}
                        placeholder="Choose service types"
                      >
                        {serviceState.state.featuredServices?.map(
                          (_feature, index) => (
                            <Select.Option
                              key={index}
                              value={_feature?.service?.details?.name}
                            >
                              {_feature?.service?.details?.name}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={11} md={12}>
                    <Form.Item>
                      <Input
                        value={filterParameter.client_name}
                        name="client_name"
                        onChange={handleInputsChange}
                        placeholder="Search by Client's name"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={11} md={12}>
                    <Form.Item>
                      <Input
                        value={filterParameter.client_email}
                        name="client_email"
                        onChange={handleInputsChange}
                        placeholder="Search by client's Email"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={11} md={12}>
                    <Form.Item>
                      <Select
                        value={filterParameter.price_ranges}
                        onChange={(value) =>
                          handleSelectorChange("price_ranges", value)
                        }
                        placeholder="Filter by price ranges"
                        mode="multiple"
                      >
                        {priceRange.map((item, index) => (
                          <Select.Option key={index} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={11} md={12}>
                    <Form.Item>
                      <Select
                        value={filterParameter.service_status}
                        allowClear
                        onChange={(value) =>
                          handleSelectorChange("service_status", value || null)
                        }
                        placeholder="Filter by payment status"
                      >
                        <Select.Option key="0" value="0">
                          Unpaid
                        </Select.Option>
                        <Select.Option key="1" value="1">
                          Has paid
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Button
                    block
                    className="button"
                    onClick={handleClearFilter}
                    type="primary"
                  >
                    Clear Filter
                  </Button>
                </Row>
              </Form>
            </Collapse.Panel>
          </Collapse> */}
          <br />
          <WrappedComponent setCurrentBooking={setCurrentBooking} />
        </ContentWrapper>

        {/* MODALS */}
        {/* <ViewModal
          open={Boolean(currentBooking)}
          onClose={handleClose}
          booking={currentBooking}
        />
        <QrCodeScanner
          searchBookingCode={handleScan}
          onClose={handleCloseScanner}
          booking={scanningCurrentBooking}
          open={Boolean(scanningCurrentBooking)}
        /> */}
      </>
    );
  };
  return View;
}

export default BookingFilterSearchHOC;
