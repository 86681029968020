import { Box, Text } from "grommet";
import CustomLoader from "../CustomLoader";

export function ScreenLoader() {
  return (
    <div className="loading">
      <Box align="center" gap="medium">
        <CustomLoader
          border={[
            {
              side: "all",
              color: "brand",
              size: "medium",
              style: "dotted",
            },
          ]}
        />
        <Text>Redirecting ...</Text>
      </Box>
    </div>
  );
}

export function ScreenLoader2() {
  return (
    <Box align="center" gap="medium" className="mt-6">
      <CustomLoader
        border={[
          {
            side: "all",
            color: "brand",
            size: "medium",
            style: "dotted",
          },
        ]}
      />
      <Text>Loading data...</Text>
    </Box>
  );
}
