import { createSlice } from "@reduxjs/toolkit";
import { HTTPStatusType } from "../../utils/http_status_type";
import getExtraReducers from "../extraReducersCreator";
import * as thunks from "../thunks/dashboard.thunk";

const initialState = {
  thunkRequestStatus: HTTPStatusType.IDLE,
  summary: {},
};

const extraReducers = getExtraReducers(thunks)

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    addSummaryData: (state, { payload }) => {
      state.summary = payload;
    },
  },
  extraReducers: extraReducers
});

export const { addSummaryData } = dashboardSlice.actions;

export default dashboardSlice.reducer;
