import React from "react";
import { Select } from "antd";
import countryPhoneCodes from "../../data/country_phone_codes.data.json";
const CountryCodeSelector = () => {
  return (
    <Select placeholder='Select country phone code'>
      <Select.Option disabled>Select country code</Select.Option>
      {countryPhoneCodes.map((item, index) => (
        <Select.Option key={index.toString()}>
          {item.country} {`(+${item.code})`}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CountryCodeSelector;
