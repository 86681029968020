/* eslint-disable no-undef */
import { notification } from "antd";
import axios from "axios";
import env from "../utils/configs/env.config.json";
import CustomLocalStorage, {
  getAccessToken,
  getSessionId,
} from "../utils/persistent_data/local_storage";

export class APIClient {
  axiosInstance = axios.create({
    baseURL: env[process.env.REACT_APP_ENV].apiBaseUrl,
  });
  errors = [];
  dispatcher = null;
  constructor(BASE_URL = null, errorHandlers = null) {
    if (BASE_URL !== null) {
      this.axiosInstance.defaults.baseURL = BASE_URL;
    }

    if (errorHandlers) {
      this.axiosInstance.interceptors.response.use(
        (response) => response,
        errorHandlers
      );
    } else {
      this.axiosInstance.interceptors.response.use(
        (response) => response,
        this.axiosInstanceErrorFn
      );
    }
  }

  addAccessToken = () => {
    const token = getAccessToken();
    const session = getSessionId();

    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Token ${token}`;
    this.axiosInstance.defaults.headers.common["session-key"] = session;
  };

  setBearerToken = (token) => {
    this.axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  };

  useMultipart = () => {
    this.axiosInstance.defaults.headers.common["Content-Type"] =
      "multipart/form-data";
  };
  removeAccessToken = () => {
    this.axiosInstance.defaults.headers.common["Authorization"] = "";
  };
  setDispatcher = (dispatch) => {
    this.dispatcher = dispatch;
  };
  get client() {
    return this.axiosInstance;
  }
  generateQueryParams = (object) => {
    let queryParams = "";
    const keys = Object.entries(object);
    for (let i = 0; i < keys.length; i++) {
      if (i === 0) {
        queryParams += `?${keys[i][0]}=${keys[i][1] ?? "all"}`;
      } else {
        queryParams += `&${keys[i][0]}=${keys[i][1] ?? "all"}`;
      }
    }
    return queryParams;
  };

  appendQueryToEndpoint = (params, endpoint = "") => {
    const _query = this.generateQueryParams(params);
    return _query === "" ? endpoint : endpoint.concat(`?${_query}`);
  };

  post = (url, payload) => this.axiosInstance.post(url, payload);
  get = (url) => this.axiosInstance.get(url);
  put = (url, payload) => this.axiosInstance.put(url, payload);
  patch = (url, payload) => this.axiosInstance.patch(url, payload);
  delete = (url) => this.axiosInstance.delete(url);

  //
  fulfilled = (data) => ({
    success: true,
    data: data,
  });
  rejected = (error) => ({
    success: false,
    error: error,
  });

  axiosInstanceErrorFn = (err) => {
    if (err.response) {
      const status = err.response?.status;
      const statusText = err.response?.statusText;
      const data = err.response?.data;
      if (status === 400) {
        notification.error({ message: data?.message });
        return Promise.reject({ error: err });
      }
      if (status === 401) {
        // this.dispatcher(setSessionExpired(true));
        if (
          statusText === "Unauthorized" &&
          data &&
          ["unable to login", "user logged out"].includes(
            data?.message?.toLowerCase().replace(".", " ").trim()
          )
        ) {
          CustomLocalStorage.setSessionHasExpired();
          notification.error({
            message: "Authenticate Session Expired",
            description: data?.message,
          });
        }
        return Promise.reject(data || status);
      } else if (status === 403) {
        notification.error({
          message: "Permission denied",
          description:
            "Sorry! You do not have permission to perform this action",
        });
        return Promise.reject({ status });
      } else if (status === 404) {
        notification.error({
          message: "Not found",
          description:
            "Sorry! The resource you are trying to access does not exist",
        });
        return Promise.reject({ status });
      } else if (status >= 500) {
        notification.error({
          message: "Internal error",
          description: "Sorry! An error occurred on our server",
        });
        return Promise.reject({ status });
      } else {
        if (err.response.data.message) {
          notification({
            message: err.response.data.message,
          });
        } else {
          notification({
            message: "Sorry!. An error occurred",
          });
        }
        return Promise.reject({ status });
      }
    } else if (err.request) {
      notification.error({
        message: "Network error",
        description: "Check internet connection and try again",
      });
      return Promise.reject({ status: null });
    } else {
      notification.error({
        message: "Unexpected error occurred",
      });
    }
    return Promise.reject({ status: null });
  };
}

export default APIClient;
