import { useCallback, useState } from 'react'
import { HTTPStatusType } from '../http_status_type'

function useLoading() {
  const [status, setStatus] = useState(HTTPStatusType.IDLE)
  const triggerStatusChange = useCallback( (_status) => {
    setStatus(_status)
  }, [])

  return {
    status:{
      loading: status === HTTPStatusType.PENDING,
      name: status
    },
    onIDLE: () => triggerStatusChange(HTTPStatusType.IDLE),
    onPENDING: () => triggerStatusChange(HTTPStatusType.PENDING),
    onFULFILLED: () => triggerStatusChange(HTTPStatusType.FULFILED),
    onREJECTED: () => triggerStatusChange(HTTPStatusType.REJECTED)
  }
}

export default useLoading
