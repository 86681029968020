import { createSlice } from "@reduxjs/toolkit";
import {HTTPStatusType} from '../../utils/http_status_type'
import * as thunks from '../thunks/dependant.thunk'
import getExtraReducers from '../extraReducersCreator'


const initialState = {
  thunkRequestStatus: HTTPStatusType.IDLE,
  list: []
}
const extraReducers = getExtraReducers(thunks)
const dependantSlice = createSlice({
  name: 'dependant',
  initialState,
  reducers: {
    addDependant: (state, {payload}) => {
      state.list = [...state.list, payload]
    },
    addDependants: (state, {payload}) => {
      state.list = payload
    }
  },
  extraReducers: extraReducers
})

export const {addDependant, addDependants} = dependantSlice.actions 

export default dependantSlice.reducer