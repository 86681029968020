const dashboard = "/dashboard";
const entityService = `${dashboard}/entity-services`;

const pageRoutes = {
  dashboard,

  // Error Pages
  page_404: "/not-found",

  // Payment
  payment_confirmation: (referenceId) => `/payment/confirmation/${referenceId}`,

  // Bookings
  bookings_history: `${dashboard}/bookings/history`,
  bookings_book_now: `${dashboard}/bookings/book`,
  booking_a_service: `${dashboard}/booking/service/:serviceId`,
  booked_services: dashboard.concat("/booked/services"),
  booked_services_attended: dashboard.concat("/booked/services/attended"),
  booked_services_unattended: dashboard.concat("/booked/services/unattended"),

  // Referrals
  referral: "/dashboard/refer",
  referral_booking: dashboard.concat("/referral/booking"),
  referral_history: dashboard.concat("/referral/history"),
  referral_bonus: dashboard.concat("/referral/bonus"),

  // Dependency
  dependency_create: dashboard.concat("/dependency/create"),
  dependency_list: dashboard.concat("/dependency/list"),

  // Service
  service_create: dashboard.concat("/service/create"),
  service_approved_list: dashboard.concat("/service/approved/list"),
  service_unapproved_list: dashboard.concat("/service/unapproved/list"),

  // Entity
  entity_services: entityService,
  entity_services_unapproved: entityService.concat("/unapproved"),
  entity_services_upload: entityService.concat("/upload"),

  // Schedule
  schedule_create: dashboard.concat("/schedule/create"),
  schedule_list: dashboard.concat("/schedule/list"),

  // External entities
  external_entity: dashboard.concat("/external-entity"),
  external_entity_list_services: dashboard.concat(
    "/external-entity/:entityId/services"
  ),
  external_entity_list_bookings: dashboard.concat(
    "/external-entity/:entityId/bookings"
  ),

  // Task
  task_services: dashboard.concat("/task/services"),

  // SETTINGS

  // Commission
  commission_rate: dashboard.concat("/settings/commission/rate"),
  commission_history: dashboard.concat("/settings/commission/history"),

  // Sub User
  sub_user: dashboard.concat("/settings/sub-user"),
  // lab notifications
  lab_notifications: dashboard.concat("/settings/lab-notifications"),
  payment: dashboard.concat("/payment/booking/:bookingCode"),
};

export default pageRoutes;
