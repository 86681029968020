import { Typography } from "antd";
import PropTypes from "prop-types";
import React from "react";
import CustomLoader from "../CustomLoader";

const ContentBase = ({ useContainer, children }) => {
  if (useContainer) return <div className="">{children}</div>;
  else return <>{children}</>;
};

const ContentWrapper = ({
  loading = false,
  hasContainer,
  title,
  className,
  children,
}) => {
  return (
    <CustomLoader loading={loading}>
      <ContentBase useContainer={hasContainer}>
        {title && (
          <Typography.Title level={3} className="heading capitalize">
            {title}
          </Typography.Title>
        )}
        <br />

        <section className={className || ""}>{children}</section>
      </ContentBase>
    </CustomLoader>
  );
};

ContentWrapper.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  hasContainer: PropTypes.bool,
};

ContentBase.propTypes = {
  children: PropTypes.node,
  useContainer: PropTypes.bool,
};

export default ContentWrapper;
