import InvoiceSummary from "@components/InvoiceSummary";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import BookingCertificate from "@components/certificates";
import { useParams } from "react-router-dom";
import { Button, Form, Modal, Input, Alert } from "antd";
import useAxiosFetch from "../../utils/custom_hooks/useAxiosFetch";
import PayStackAPI from "../../services/paystack";
import PublicImages from "../../utils/images";
import InvoiceService from "../../services/invoice";
import CustomListLoader from "@components/Loader/CustomListLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { HTTPStatusType } from "../../utils/http_status_type";
import PaymentService from "../../services/payment.service";
import { useHistory } from "react-router-dom";
import pageRoutes from "../../utils/page-routes";

const invoiceService = new InvoiceService();
const paymentService = new PaymentService();

const PaymentValidating = ({ invoice, onCancel }) => {
  const [status, setStatus] = useState(HTTPStatusType.PENDING);
  const { push } = useHistory();

  const { onFetch, loading } = useAxiosFetch({
    fetcher: (args) =>
      paymentService.verifyPayment(args?.referenceId, args?.invoiceCode),
    onSuccess: (res) => {
      onCancel && onCancel();
      setStatus(HTTPStatusType.FULFILED);
      push(
        pageRoutes.payment_confirmation.replace(":referenceId", invoice?.uuid)
      );
    },
  });

  const getStatusInfo = useMemo(() => {
    let result = {};
    if (status === HTTPStatusType.FULFILED)
      result = {
        message: "Invoice verification was successful",
        type: "success",
      };
    else if (status === HTTPStatusType.PENDING)
      result = {
        message: "Your invoice is been verify, kindly wait as we work on it",
        type: "info",
      };
    else
      result = {
        message:
          "Sorry, you currently don't have any active invoice to be verified",
        type: "error",
      };
    return result;
  }, [status]);

  useEffect(() => {
    const timer = setInterval(() => {
      onFetch({
        referenceId: invoice?.uuid,
        invoiceCode: invoice?.invoice_code,
      });
    }, 5000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice?.code, invoice?.uuid]);

  return (
    <div className="flex-1 flex justify-center items-center">
      <Alert
        type={getStatusInfo?.type || "info"}
        message={
          <div className="text-center font-bold space-x-4">
            <span>{getStatusInfo?.message || ""}</span>
            {loading && (
              <span>
                <FontAwesomeIcon icon={faSpinner} spin />
              </span>
            )}
          </div>
        }
      />
    </div>
  );
};

const BookingPayment = () => {
  const params = useParams();
  const [isVerifyPayment, setIsVerifyPayment] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const result = useSelector(
    (state) => state.bookNowService?.bookingResult || undefined
  );
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "certificate",
    pageStyle: () => `
    
    `,
  });

  const { onFetch, loading } = useAxiosFetch({
    fetcher: invoiceService.generateBookingInvoice,
    onSuccess: (res) => {
      console.log(res?.data?.data);
      setInvoiceData(res?.data?.data);
    },
  });

  const pay = useAxiosFetch({
    fetcher: PayStackAPI.initializeTransaction,
    onSuccess: (res) => {
      const _data = res?.data?.data;
      if (!_data) {
        onFetch(params?.bookingCode);
        setOpenModal(false);
        return;
      }
      setOpenModal(false);
      setIsVerifyPayment(true);
      window.open(_data?.authorization_url, "_blank");
    },
  });

  const paymentOnFinish = (values) => {
    const [currency, amount] = invoiceData?.payable_amount?.split(" ");
    setIsVerifyPayment(true);
    const parsedAmount = parseFloat(amount) * 100;
    console.log("AMOUNT ==> ", amount, parsedAmount);
    pay.onFetch({
      currency: currency,
      email: invoiceData?.generated_for?.email,
      reference: invoiceData.uuid,
      amount: parsedAmount,
      mobile_money: {
        phone: values?.phone,
        provider: "MTN",
      },
      callback_url: window.location.host.concat(
        `/payment/confirmation/${invoiceData?.uuid}`
      ),
    });
  };

  useEffect(() => {
    onFetch(params?.bookingCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.bookingCode]);

  return (
    <>
      <CustomListLoader
        text="Loading invoice"
        subText="Generating invoice for this booking"
        loading={loading}
      >
        {isVerifyPayment && (
          <PaymentValidating
            onCancel={() => {
              setIsVerifyPayment(false);
            }}
            invoice={invoiceData}
          />
        )}
        <section className="border rounded-3xl p-5 mt-4">
          <div className="grid grid-cols-1 md:grid-cols-2 items-start gap-4">
            <InvoiceSummary invoiceData={invoiceData} />
            {invoiceData?.booked_service && (
              <BookingCertificate
                result={invoiceData.booked_service}
                printRef={componentRef}
              />
            )}
          </div>
          {!invoiceData?.payed && (
            <div>
              <Button
                onClick={() => setOpenModal(true)}
                className="btn btn-primary"
              >
                Make Payment | {invoiceData?.payable_amount}
              </Button>
            </div>
          )}
        </section>
      </CustomListLoader>
      <Modal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
      >
        <div className="grid grid-cols-2 gap-4">
          <div>
            <img src={PublicImages.PaymentMtnBannerPerson} />
          </div>
          <div>
            <h3 className="font-bold text-lg">Pay with MTN MOMO</h3>
            <img width={80} src={PublicImages.PaymentMtnBanner} />
            <br />
            <h3 className="font-bold mb-2s">Payment Details</h3>
            <Form onFinish={paymentOnFinish} className="form">
              <Form.Item name={"name"}>
                <Input placeholder="Enter your name" />
              </Form.Item>
              <Form.Item name={"phone"}>
                <Input placeholder="Enter your phone number" />
              </Form.Item>
              <p className="text-sm text-gray-500">
                You will receive a prompt on your device to complete payment
              </p>
              <center className="mt-4">
                <Button
                  loading={pay.loading}
                  disabled={pay.loading}
                  htmlType="submit"
                  className="btn btn-primary mx-auto"
                >
                  Pay
                </Button>
              </center>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BookingPayment;
