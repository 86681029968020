import React from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";

const ListServicesLayout = ({ children }) => {
  return (
    <div style={{ minHeight: "100vh" }} className="flex  flex-col">
      <Header />
      {children}
      <Footer className="mt-auto pb-4" />
    </div>
  );
};

export default ListServicesLayout;
