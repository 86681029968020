export const priceRange = [
    {
      value: "0-100 ",
      label: "from 0 to 100",
    },
    {
      value: "101-500 ",
      label: "from 101 to 500",
    },
    {
      value: "501-10000 ",
      label: "from 501 to 10000",
    },
    {
      value: "1001-above",
      label: "At least 10001",
    },
  ];