import React from "react";
import errorImage from "@assets/images/server_error.png";
import { Button } from "antd";
import useLogout from "@utils/custom_hooks/useLogout.hook";
import envConfigs from "@utils/configs/env.config";
import { Link } from "react-router-dom";

const Error500 = () => {
  const logout = useLogout();
  return (
    <div className="flex flex-col items-center justify-center h-[100svh]">
      <div className="max-w-[70%] relative">
        <img width="70%" className="mx-auto" src={errorImage} alt="" />
      </div>
      <div className="text-center space-y-4">
        <h1 className="text-center font-bold text-3xl lg:text-[50px]">
          Server Error
        </h1>

        <p className="text-center">We are having problem with our servers.</p>
        <div className="flex justify-center">
          <Button onClick={() => logout()}> Logout </Button>
          <Link to="/">
            <Button type="link" className="ml-2 text-white">
              Go to home
            </Button>
          </Link>
        </div>
        <div className="d-flex w-100 justify-content-center mt-3">
          <a
            href={
              envConfigs[process.env.REACT_APP_ENV || "development"]
                .supportMailTo
            }
            className="text-center text-danger"
          >
            Get to support support center
          </a>
        </div>
      </div>
    </div>
  );
};

export default Error500;
