import envConfig from "../configs/env.config.json";

const baseUrl =
  envConfig[process.env.REACT_APP_ENV || "development"].apiBaseUrl;

// Authentication
export const authenticateUrl = `${baseUrl}/intel/auth/login/`;

// Dashboard Summary
export const dashboardSummaryUrl = baseUrl.concat(
  "/booking/manager/dashboard/summary/"
);

// Featured Services
export const featuredServicesUrl = `${baseUrl}/booking/client/list/entity/services`;
export const featuredServiceUrl = (type, countryCode) =>
  `${baseUrl}/booking/manager/list/featured/services/${type}/${countryCode}`;
export const getFeaturedServicesUrl = (type, countryCode) =>
  `${baseUrl}/booking/manager/list/featured/services/${type}/${countryCode}`;
export const bookingServiceUrl = (type) =>
  `${baseUrl}/booking/manager/list/bookable/services/${type}`;
export const entityUrl = `${baseUrl}/intel/user/entity/`;
export const timeSlotsUrl = (serviceId, date) =>
  `${baseUrl}/booking/client/possible/bookings/${serviceId}/${date}/`;

export const bookingHistoryUrl = `${baseUrl}/booking/client/list/services/booked/`;

// Entity Service
export const createEntityServiceUrl = `${baseUrl}/booking/manager/create/entity/service/`;
export const getEntityServicesUrl = "/booking/manager/entity/services/";
export const getUnApprovedEntityServicesUrl =
  "/booking/manager/unapproved/entity/services/";

// Schedule
export const scheduleWeekDayUrl = `${baseUrl}/booking/manager/week/days/`;
export const timeItervalUrl = (interval) =>
  `${baseUrl}/booking/manager/time/slots/interval/${interval}/`;
export const scheduleListUrl = `${baseUrl}/booking/manager/entity/booking/schedules/`;
export const createScheduleUrl = `${baseUrl}/booking/manager/booking/schedule/`;

// Dependants
export const getDependantsUrl = `${baseUrl}/booking/client/list/dependants/`;
export const createDependantUrl = `${baseUrl}/booking/client/add/dependant/`;

// create or get a user
export const getOrCreateUserUrl = `${baseUrl}/intel/create/user/`;

// Booking endpoints
export const getClientBookedServicesUrl =
  "/booking/manager/list/booked/services/";
export const getOrganizationsAttendedBookingsUrl =
  "/booking/manager/list/organization/attended/bookings/";
export const getOrganizationsUnAttendedBookingsUrl =
  "/booking/manager/list/organization/unattended/bookings/";
export const markBookingAsAttendedUrl =
  "/booking/manager/mark/booking/{ID}/attended/";

export const markBookingAsPaidUrl = "/booking/manager/mark/booking/paid/";

// Doctor
export const doctorsReferralHistoryUrl = `/booking/manager/referral/history`;

// Invoice
export const generateInvoiceUrl = (bookingCode) =>
  `/payment/generate/invoice/${bookingCode}`;
