const searchCurrencySliderLabel = (currency) => ({
  0: <p style={{ fontSize: "0.6rem" }}>{currency}0 </p>,
  2500: <p style={{ fontSize: "0.6rem" }}>{currency}2500 </p>,
  5000: <p style={{ fontSize: "0.6rem" }}>{currency}5000 </p>,
  7500: <p style={{ fontSize: "0.6rem" }}>{currency}7500 </p>,
  10000: {
    style: {
      color: `#f50`,
    },
    label: (
      <strong style={{ fontSize: "0.6rem" }}>{`${currency} 10000 `}</strong>
    ),
  },
});

export default searchCurrencySliderLabel;
