import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getUserProfile } from "../utils/persistent_data/local_storage";

const PublicRoutes = ({ component: Component }) => {
  return (
    <Route
      render={(props) => {
        if (getUserProfile()) {
          return <Redirect to="/dashboard" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default PublicRoutes;
