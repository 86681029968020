import React from "react";
import errorImage from "@assets/images/404_error.png";
import { Button } from "antd";
import { useHistory } from "react-router";
const Error404Page = () => {
  const history = useHistory();
  return (
    <div className="flex flex-col items-center justify-center h-[100svh] ">
      <div className="max-w-[70%] relative">
        <img width="70%" className="mx-auto" src={errorImage} alt="" />
      </div>
      <div className=" text-center space-y-4">
        <h1 className="text-center font-bold text-3xl lg:text-[50px] mt-2">
          Not found
        </h1>

        <p className="text-center">
          The page or resources you were looking for does not exist
        </p>
        <div className="">
          <Button href="/"> Go to home </Button>
          <Button
            type="link"
            className="ml-4 text-white"
            onClick={() => history.goBack()}
          >
            {" "}
            Go to previous page{" "}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Error404Page;
