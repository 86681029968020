import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { APIClient } from "../../services/api";

const useOrganizationProfile = () => {
  const [profile, setProfile] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { organizationId } = useParams();
  const fetchProfile = useCallback(async () => {
    setLoading(true);
    const api = new APIClient();
    try {
      const response = await api.get(
        `/intel/fetch/lab/by/registration/number/?reg_number=${organizationId}`
        
      );
      setLoading(false);
      setProfile(response.data.data);
      // console.log(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
      return { profile: [], loading };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);
  useEffect(() => {
    fetchProfile();
    // console.log(profile);
  }, [fetchProfile]);
  return { profile, loading };
};

export default useOrganizationProfile;
