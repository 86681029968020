import { Radio, Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentWrapper from "@components/ContentWrapper";
import { koldChainFetchOrganisationEntitiesThunk } from "@store/thunks/entity.thunk";
import useCustomSelector from "@utils/custom_hooks/useCustomSelector";
import { Link } from "react-router-dom";
import pageRoutes from "@utils/page-routes";
const ExternalEntities = () => {
  const dispatch = useDispatch();

  const entityState = useCustomSelector((state) => state.entity);

  useEffect(() => {
    dispatch(koldChainFetchOrganisationEntitiesThunk());
  }, [dispatch]);
  return (
    <ContentWrapper hasContainer title="List of External Entities">
      <br />
      <Table
        rowKey={(record) => record.entity_id}
        loading={entityState.loading}
        dataSource={entityState.state.organizations}
        columns={[
          {
            title: "Name",
            dataIndex: "service",
            key: "name",
            render: (value, record) => (
              <p>{record?.info.name || "Service name not provided"}</p>
            ),
          },
          {
            title: "Entity Name",
            dataIndex: "entity_type",
            key: "entity_type",
          },
          {
            title: "Country",
            dataIndex: "info_country",
            key: "info_country",
            render: (_, record) => <p>{record?.info?.country || ""}</p>,
          },
          {
            title: "City",
            dataIndex: "info_city",
            key: "info_city",
            render: (_, record) => <p>{record?.info?.city || ""}</p>,
          },
          {
            title: "Approved",
            dataIndex: "approved",
            key: "approved",
            render: (_, record) => (
              <Radio checked={record?.info?.isApproved || false} />
            ),
          },
          {
            title: "Actions",
            dataIndex: "action",
            key: "action",
            render: (_, record) => {
              return (
                <div className="space-x-8">
                  <Link
                    className="border border-yellow-500 text-yellow-500 hover:text-black hover:bg-yellow-300 border-1 p-2 rounded-full"
                    to={pageRoutes.external_entity_list_services.replace(
                      ":entityId",
                      record?.entity_reg
                    )}
                  >
                    View Services
                  </Link>
                  <Link
                    className="border border-yellow-500 text-yellow-500 hover:text-black hover:bg-yellow-300 border-1 p-2 rounded-full"
                    to={pageRoutes.external_entity_list_bookings.replace(
                      ":entityId",
                      record?.entity_reg
                    )}
                  >
                    View Bookings
                  </Link>
                </div>
              );
            },
          },
        ]}
      />
    </ContentWrapper>
  );
};

export default ExternalEntities;
