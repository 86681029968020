import { useState, useEffect, useCallback } from "react";
import CasClient, { constant } from "react-cas-client";
import { useHistory } from "react-router-dom";

import Header from "@components/headers/Header.js";
import { ScreenLoader } from "@components/Loader";
import { generateString } from "@utils/char-generator";
import envConfigs from "@utils/configs/env.config.json";
import { message } from "antd";
import {
  setAccessToken,
  setSessionId,
  setUsername,
  setUserProfile,
} from "@utils/persistent_data/local_storage";
import axios from "axios";
import { useSelector } from "react-redux";
import Container from "@components/container";

const Auth = (props) => {
  const history = useHistory();
  const appState = useSelector((state) => state.app);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    login();
  });

  const fetchUserProfile = useCallback(
    (sessionId, accessToken) => {
      const url = `${
        envConfigs[process.env.REACT_APP_ENV].apiBaseUrl
      }/intel/userprofile/`;
      const config = {
        headers: {
          "session-key": sessionId,
          Authorization: `Token ${accessToken}`,
        },
      };
      axios
        .get(url, config)
        .then((res) => {
          message.success("Login successfully");
          setUserProfile(res.data.data);
          history.push(appState?.cas_next_url || "/");
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            message.error("Authentication failed");
            history.push("/403_no_permission");
          } else if (error.request) {
            message.error("Network error");
          }
        });
    },
    [appState?.cas_next_url, history]
  );

  const logUserIn = useCallback(
    (sessionId, username) => {
      const data = {
        session_key: sessionId,
        username,
      };
      axios
        .post(
          `${
            envConfigs[process.env.REACT_APP_ENV].apiBaseUrl
          }/intel/auth/login/`,
          data
        )
        .then((res) => {
          const accessToken = res.data.data.token;
          setAccessToken(accessToken);
          fetchUserProfile(sessionId, accessToken);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 400) {
              message.error("An internal error occurred");
              history.push("/500-server-error");
            }
            message.error(err.response.data.message);
            history.push("/403_no_permission");
          } else {
            message.error(err.message);
            history.push("/403_no_permission");
          }
        });
    },
    [fetchUserProfile, history]
  );

  const login = useCallback(() => {
    const casClient = new CasClient(
      envConfigs[process.env.REACT_APP_ENV || "development"].casUrl,
      {
        version: constant.CAS_VERSION_2_0,
      }
    );

    casClient
      .auth()
      .then((res) => {
        const sessionId = generateString(15);
        setUsername(res.user);
        setSessionId(sessionId);
        logUserIn(sessionId, res.user);
      })
      .catch((err) => {
        setIsLoading(false);
        history.push("/500-server-error");
      });
  }, [logUserIn, history]);

  return (
    <>
      <Header />
      {/* Page content */}
      <Container>
        {isLoading && <ScreenLoader />}
        {/* If not loading then redirecting... */}
        {!isLoading && <ScreenLoader />}
      </Container>
    </>
  );
};

export default Auth;
