import React, { useCallback, useEffect } from "react";
import { Table } from "antd";

import { useDispatch } from "react-redux";
import { Archive } from "react-bootstrap-icons";
import { useState } from "react";
import ArchievePromptModal from "@components/modals/ArchievePromptModal";
import { fetchUnApprovedEntityServicesThunk } from "@store/thunks/service.thunk";
import ContentWrapper from "@components/ContentWrapper";
import useCustomSelector from "@utils/custom_hooks/useCustomSelector";

const ListUnapprovedServices = () => {
  const serviceState = useCustomSelector((state) => state.service);
  const dispatch = useDispatch();

  const getManagerUnapprovedServices = useCallback(() => {
    dispatch(fetchUnApprovedEntityServicesThunk());
  }, [dispatch]);

  useEffect(() => {
    getManagerUnapprovedServices();
  }, [getManagerUnapprovedServices]);

  const [currentItemToDelete, setCurrentItemToDelete] = useState(null);

  const columns = [
    {
      title: "Tests",
      dataIndex: "test",
      key: "id",
      render: (test) => <>{`${test?.name} - ${test?.type}`}</>,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "id",
    },
    {
      title: "Charge",
      dataIndex: "price",
      key: "id",
    },
    {
      title: "Actions",
      key: "actions",
      render: (value) => (
        <div>
          <Archive
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => setCurrentItemToDelete(value)}
          />
        </div>
      ),
    },
  ];
  return (
    <>
      <ArchievePromptModal
        onClose={() => setCurrentItemToDelete(null)}
        visible={Boolean(currentItemToDelete)}
        serviceId={currentItemToDelete?.service_id}
      />
      <ContentWrapper
        title="List of Unapproved Services"
        hasContainer
        loading={serviceState?.loading}
      >
        {serviceState && (
          <Table
            columns={columns}
            dataSource={serviceState.state.entity.unapproved}
            pagination={{ pageSize: 20 }}
          />
        )}
      </ContentWrapper>
    </>
  );
};

export default ListUnapprovedServices;
