import React from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route } from "react-router";
import { setRedirectUrl } from "../store/slices/app.slice";
import { getUserProfile } from "../utils/persistent_data/local_storage";

const IsAuthenticatedRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (getUserProfile()) {
          return <Component {...props} />;
        } else {
          dispatch(setRedirectUrl(props.history.location.pathname));

          return <Redirect to="/auth/login" />;
        }
      }}
    />
  );
};

export default IsAuthenticatedRoute;
