import { Button, Modal, Typography } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { markBookingAsAttendedThunk } from "../../store/thunks/booking.thunk";
const MarkBookingAsAttendedModal = ({ booking, onClose }) => {
  const dispatch = useDispatch();
  const onMarkComplete = () => {
    dispatch(markBookingAsAttendedThunk(booking));
    onClose();
  };

  return (
    <Modal open={Boolean(booking)} onCancel={onClose} footer={null}>
      <div
        style={{ rowGap: "1em" }}
        className="d-flex flex-column align-items-center justify-content-center "
      >
        <Typography.Title level={5}>
          Are you sure you want mark as attended?
        </Typography.Title>
        <div style={{ columnGap: "1em" }} className="flex">
          <Button onClick={onMarkComplete} type="primary">
            Yes
          </Button>
          <Button onClick={() => onClose()} type="default">
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MarkBookingAsAttendedModal;
