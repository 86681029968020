import { createSlice } from "@reduxjs/toolkit";
import { HTTPStatusType } from "../../utils/http_status_type";
import * as thunks from "../thunks/service.thunk";

import getExtraReducers from "../extraReducersCreator";

const initialState = {
  thunkRequestStatus: HTTPStatusType.IDLE,
  list: [],
  featuredServices: [],
  entity: {
    approved: [],
    unapproved: [],
  },
  koldchain: {
    approved: [],
    unapproved: [],
  },
  search: {
    result: [],
  },
};
const extraReducers = getExtraReducers(thunks);
const serviceSlice = createSlice({
  name: "serviceSlice",
  initialState,
  reducers: {
    clearSearchList: (state, action) => {
      state.search.result = [];
    },
    updateSearchResult: (state, { payload }) => {
      state.search.result = payload;
    },
    addToFeaturedServices: (state, { payload }) => {
      state.featuredServices = payload;
      state.search.result = payload;
    },
    addToUnApprovedServices: (state, { payload }) => {
      state.entity.unapproved.push(payload);
    },
    setEntityServices: (state, { payload }) => {
      const { data, type = "approved" } = payload;
      type === "approved" && (state.entity.approved = data);
      type === "unapproved" && (state.entity.unapproved = data);
    },
    archieveUnApprovedService: (state, { payload }) => {
      const result = state.entity.unapproved.filter(
        (service) => service.service_id !== payload
      );
      state.entity.unapproved = result;
    },
    // KoldChain actions
    setKoldChainServiceData: (state, { payload }) => {
      const { data, type = "approved" } = payload;
      type === "approved" && (state.koldchain.approved = data);
      type === "unapproved" && (state.koldchain.unapproved = data);
    },
    removeUnApprovedServiceById: (state, { payload }) => {
      state.koldchain.unapproved = [...state.koldchain.unapproved].filter(
        (service) => service.id !== payload
      );
    },
  },
  extraReducers: extraReducers,
});

export const {
  clearSearchList,
  addToFeaturedServices,
  archieveUnApprovedService,
  addToUnApprovedServices,
  setEntityServices,
  setKoldChainServiceData,
  removeUnApprovedServiceById,
  updateSearchResult,
} = serviceSlice.actions;

export default serviceSlice.reducer;
