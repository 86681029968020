import React, { useCallback, useEffect } from "react";
import ContentWrapper from "@components/ContentWrapper";
import { Progress, Table, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import useUserProfile from "@utils/custom_hooks/useUserProfile.hook";
import {
  fetchKoldChainTaskServicesThunk,
  fetchLabAdminTaskServicesThunk,
} from "@store/thunks/task.thunk";
import { UserRole } from "@utils/roles";
import { getTaskState } from "@store/slices/task.slice";
const columns = [
  {
    dataIndex: "num_of_services",
    key: "num_of_services",
    title: "Number of services",
  },
  {
    dataIndex: "num_of_services_upload",
    key: "num_of_services_upload",
    title: "Number of passed services",
    render: (value, _) => <>{value || 0}</>
  },
  {
    dataIndex: "num_of_services_failed",
    key: "num_of_services_failed",
    title: "Number of failed services",
  },
  {
    dataIndex: "percent_completion",
    key: "percent_completion",
    title: "Completion percentage",
    render: (value, _) =>{
      const _perc = value.includes("%") ? value.split("%")[0] : value;
      console.log("PERc", _perc)
      return  <Progress percent={_perc} />
    },
  },
  {
    dataIndex: "status",
    key: "status",
    title: "Status",
    render: (value, _) => (
      <Tag color={value?.toLowerCase() === "error" ? "red" : "green"}>
        {value}
      </Tag>
    ),
  },
];
const ServicesTask = () => {
  const taskState = useSelector(getTaskState);
  const group = useUserProfile()?.group?.toLowerCase();
  const dispatch = useDispatch();
  const getTaskServices = useCallback(() => {
    group === UserRole.KOLDCHAIN_ADMIN &&
      dispatch(fetchKoldChainTaskServicesThunk());
    group === UserRole.LAB_ADMIN && dispatch(fetchLabAdminTaskServicesThunk());
  }, [dispatch, group]);

  useEffect(() => {
    getTaskServices();
  }, [getTaskServices]);
  
  return (
    <ContentWrapper title={"Services Tasks"}>
      <Table
        dataSource={taskState?.data || []}
        columns={columns}
        loading={taskState.loading}
      />
    </ContentWrapper>
  );
};

export default ServicesTask;
