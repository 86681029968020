export const FETCH_BOOK_NOW_SERVICES_REQUEST =
  "FETCH_BOOK_NOW_SERVICES_REQUEST";
export const FETCH_BOOK_NOW_SERVICES_SUCCESS =
  "FETCH_BOOK_NOW_SERVICES_SUCCESS";
export const FETCH_BOOK_NOW_SERVICES_FAILED = "FETCH_BOOK_NOW_SERVICES_FAILED";

export const FETCH_TIME_SLOTS_REQUEST = "FETCH_TIME_SLOTS_REQUEST";
export const FETCH_TIME_SLOTS_SUCCESS = "FETCH_TIME_SLOTS_SUCCESS";
export const FETCH_TIME_SLOTS_FAILED = "FETCH_TIME_SLOTS_FAILED";

export const SET_TIMESLOT_CHOSEN = "SET_TIMESLOT_CHOSEN";
export const SET_BOOKINGS_TRAVEL_INFO = "SET_BOOKINGS_TRAVEL_INFO";
export const SET_BOOKING_DATE_SELECTED = "SET_BOOKING_DATE_SELECTED";
export const CLEAR_BOOKING_FIELDS = "CLEAR_BOOKING_FIELDS";
export const SET_BOOKING_RESULT = "SET_BOOKING_RESULT";
