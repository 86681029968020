import React from "react";
import errorImage from "@assets/images/timeout.png";
import useMediaquery from "@utils/custom_hooks/useMediaquery.hook";
import { Button } from "antd";
import envConfigs from "@utils/configs/env.config";
import useLogout from "@utils/custom_hooks/useLogout.hook";
import { useSelector } from "react-redux";
import { getAuthState } from "@store/slices/auth.slice";
const SessionTimeOut = () => {
  const authState = useSelector(getAuthState);
  const width = useMediaquery();
  const logout = useLogout();
  return (
    <div
      style={{ height: "100vh", width: "100vw" }}
      className="d-flex flex-column align-items-center justify-content-center "
    >
      <div
        style={{
          width:
            width < 500
              ? "100%"
              : (width > 500 && width) < 1000
              ? "50%"
              : "40%",
        }}
        className=""
      >
        <img width="100%" src={errorImage} alt="" />
      </div>
      <div style={{ width: width < 500 ? "90%" : "65%" }} className="">
        <div>
          <h1
            className="text-center"
            style={{ fontSize: width < 500 ? "1rem" : "2rem" }}
          >
            <span className="font-weight-lighter">Session</span> Timed Out
          </h1>
        </div>
        <p className="text-center">
          Your session has timed out due to inactivity. You will be
          automatically logged in again or Please click on the log in again to
          continue accessing our services. We apologize for any inconvenience
          caused.
        </p>
        <div className="d-flex w-100 justify-content-center">
          <Button
            loading={authState.loading}
            type="primary"
            className="button"
            onClick={() => logout()}
          >
            {" "}
            Login{" "}
          </Button>
        </div>
        <div className="d-flex w-100 justify-content-center mt-3">
          <a
            href={
              envConfigs[process.env.REACT_APP_ENV || "development"]
                .supportMailTo
            }
            className="text-center text-danger"
          >
            Get to support support center
          </a>
        </div>
      </div>
    </div>
  );
};

export default SessionTimeOut;
