import React, { useEffect } from "react";
import { Card, Col, Row, Empty, Typography } from "antd";
import { weekByNumber } from "@utils/day-of-week";
import { useDispatch } from "react-redux";
import { getTime } from "@utils/date_time_format";
import useCustomSelector from "@utils/custom_hooks/useCustomSelector";
import ContentWrapper from "@components/ContentWrapper";
import { fetchSchedulesThunk } from "@store/thunks/schedule.thunk";

const ListSchedules = () => {
  const dispatch = useDispatch();
  const scheduleState = useCustomSelector((state) => state.schedule);

  useEffect(() => {
    dispatch(fetchSchedulesThunk());
  }, [dispatch]);

  return (
    <>
      <ContentWrapper
        loading={scheduleState.loading}
        hasContainer
        title="List of Schedules "
      >
        <Row gutter={16}>
          {scheduleState.state.list.length > 0 ? (
            scheduleState.state.list.map((data) => (
              <Col sm={{ span: 24 }} span={24}>
                <Card bordered={false}>
                  <Typography.Title level={4}>
                    {data?.day_of_the_week}
                  </Typography.Title>
                  <div className="d-flex w-full">
                    <div
                      style={{
                        backgroundColor:
                          data?.day_of_the_week === weekByNumber(1)
                            ? "#f6e3fe"
                            : data?.day_of_the_week === weekByNumber(2)
                            ? "#E7F2FE"
                            : data?.day_of_the_week === weekByNumber(3)
                            ? "#FEE9E1"
                            : data?.day_of_the_week === weekByNumber(4)
                            ? "#FBD8E2"
                            : data?.day_of_the_week === weekByNumber(5)
                            ? "#DFF9E4"
                            : data?.day_of_the_week === weekByNumber(6)
                            ? "#FFF5D1"
                            : "#FFFFFF",
                      }}
                      className="border-top border-bottom border-left"
                    >
                      <Typography className="p-1">Time Slot</Typography>
                      <Typography
                        style={{ borderColor: "#cccccc" }}
                        className="border-top p-1 pr-5"
                      >
                        Available Slots
                      </Typography>
                    </div>
                    <div
                      style={{ flex: "auto" }}
                      className="d-flex flex-2 justify-between"
                    >
                      {data.time_slots?.map((timeSlot) => (
                        <div
                          className="border text-center"
                          style={{ flex: "1" }}
                        >
                          <Typography className="p-1">
                            {getTime(timeSlot.slot.start_time)} -
                            {getTime(timeSlot.slot.end_time)}
                          </Typography>
                          <Typography className="border-top p-1">
                            {timeSlot.max_bookee}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                </Card>
              </Col>
            ))
          ) : (
            <div className="d-flex w-100 justify-content-center">
              <Empty />
            </div>
          )}
        </Row>
      </ContentWrapper>
    </>
  );
};

export default ListSchedules;
