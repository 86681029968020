import { useSelector } from "react-redux";
import { HTTPStatusType } from "../http_status_type";

function useCustomSelector(selector) {
  const state = useSelector(selector);
  return (
    state !== undefined && {
      state,
      loading: state?.thunkRequestStatus === HTTPStatusType.PENDING,
    }
  );
}

export default useCustomSelector;
