import { createSlice } from "@reduxjs/toolkit";
import { HTTPStatusType } from "../../utils/http_status_type";
import getExtraReducers from "../extraReducersCreator";
import * as thunks from "../thunks/task.thunk";

const initialState = {
  data: [],
  loading: false,
  thunkRequestStatus: HTTPStatusType.IDLE,
};

const taskSlice = createSlice({
  name: "taskSlice",
  initialState,
  reducers: {
    addTasks: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: getExtraReducers(thunks),
});
// selectors
export const getTaskState = (state) => state.task;
export const { addTasks } = taskSlice.actions;
export default taskSlice.reducer;
