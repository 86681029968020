import { Button, Modal, Typography } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { archieveAServiceThunk } from "@store/thunks/service.thunk";

const ArchievePromptModal = ({ onClose, visible, serviceId }) => {
  const dispatch = useDispatch();
  const onArchive = () => {
    dispatch(archieveAServiceThunk(serviceId));
    onClose();
  };
  return (
    <Modal closable={false} open={visible} onCancel={onClose} footer={null}>
      <Typography>
        Archiving a service will remove the service from the list of services
        that can be booked by clients
      </Typography>
      <Typography style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
        Are you sure you want to archive this service?
      </Typography>
      <div
        style={{ columnGap: "1em" }}
        className="w-full d-flex  justify-content-center mt-3"
      >
        <Button onClick={onArchive} type="primary">
          Archive
        </Button>
        <Button onClick={onClose} type="default">
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ArchievePromptModal;
