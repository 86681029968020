import { notification } from "antd";
import { APIClient } from "./api";

export default class PayStackAPI {
  static instance = () => {
    const api = new APIClient(
      "https://api.paystack.co/transaction",
      PayStackAPI.ErrorHandlers
    );

    api.setBearerToken(process.env.REACT_APP_PAYSTACK_PUBLIC_KEY);
    return api;
  };

  static ErrorHandlers = (error) => {
    if (!error.response) {
      return error;
    }
    const status = error?.response?.status;

    switch (status) {
      case 400:
        notification.error({ description: error.response.data.message });
        return;
      case 401:
        notification.error({ description: "Payment process canceled unexpectedly, refresh page and try again" });
        return;
      case 403:
        notification.error({ description: error.response.data.message });
        return;
      default:
        return error;
    }
  };

  static initializeTransaction = async (data) => {
    const api = PayStackAPI.instance();
    return await api.post("initialize", data);
  };
  static verifyTransaction = async (referenceId) => {
    const api = PayStackAPI.instance();
    const url = `verify/${referenceId}`;
    return await api.get(url);
  };
}
