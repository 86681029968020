import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../services/api";
import { generateString } from "../../utils/char-generator";
import { authenticateUrl } from "../../utils/network/endpoints";
import CustomLocalStorage, {
  getUsername,
  setAccessToken,
  setSessionId,
} from "../../utils/persistent_data/local_storage";

class AuthService {
  constructor() {
    this.api = new APIClient();
  }

  loginUser = createAsyncThunk(
    "auth/login-user",
    async (username, { getState }) => {
      const sessionKey = generateString(15);
      try {
        const { data } = await this.api.post(authenticateUrl, {
          session_key: sessionKey,
          username,
        });
        const accessToken = data.data.token;
        console.log("USERNAME", username);
        console.log("NEW AACCES", accessToken);
        console.log("SESSION_KEY", sessionKey);
        setAccessToken(accessToken);
        setSessionId(sessionKey);
        CustomLocalStorage.setSessionHasExpired(false);
        // setAccessToken(accessToken);
        return accessToken;
      } catch (error) {
        return null;
      }
    }
  );

  loginAfterSessionExpired = createAsyncThunk(
    "auth/login-after-session-expired",
    async (_, { dispatch }) => {
      const username = getUsername();
      return await dispatch(this.loginUser(username));
    }
  );
}

// Exports
const auth = new AuthService();

const authThunks = {
  loginAfterSessionExpired: auth.loginAfterSessionExpired,
};

export default authThunks;
