import React from "react";
import { Button } from "antd";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLogout from "@utils/custom_hooks/useLogout.hook";

const ProfileDropDown = () => {
  const logout = useLogout();
  return (
    <div>
      <Button
        icon={<FontAwesomeIcon icon={faSignOutAlt} />}
        type="primary"
        onClick={() => logout()}
        className="w-100 px-5"
      >
        {" "}
        Logout{" "}
      </Button>
    </div>
  );
};

export default ProfileDropDown;
