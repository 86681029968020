import {
  Button,
  Col,
  Form,
  Divider,
  Row,
  Checkbox,
  message,
  Typography,
  Tag,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import CustomLoader from "@components/CustomLoader";
import {
  clearBookingFields,
  setBookingResult,
} from "@store/actions/bookNowServicesActions";
import {
  getCalDate,
  getRawDayMonthYear,
  getTime,
} from "@utils/date_time_format";
import { APIClient } from "@services/api";
import BookingService from "../../../../services/booking.service";
import pageRoutes from "../../../../utils/page-routes";

const bookingInstance = new BookingService();

const BookingPreview = ({ onNext, onBack, formData }) => {
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const bookNow = async () => {
    let purpose_info = {
      travel_date: getRawDayMonthYear(formData?.travel_date),
    };

    if (formData.purpose === "Travel") {
      purpose_info["travel"] = {
        transport_operator: formData?.transport_operator,
        passport_number: formData?.passport_number,
        national_id_number: formData?.national_id_number,
        covid_code: formData?.covid_code,
        departure_country: formData?.departure_country,
        arrival_country: formData?.arrival_country,
      };
    }
    if (formData.purpose === "Education") {
      purpose_info["education"] = {
        school_name: formData?.school_name,
        school_level: formData?.school_level,
        student_id: formData?.student_id,
        email: formData?.student_email,
        telephone: formData?.telephone,
        address: formData?.school_address,
        country: formData?.country,
      };
    }
    if (formData.purpose === "Employment") {
      purpose_info["employment"] = {
        employer_name: formData?.employer_name,
        employment_status: formData?.employment_status,
        employer_email: formData?.employer_email,
        employer_location: formData?.employer_location,
        employer_telephone: formData?.employer_telephone,
      };
    }
    if (formData.purpose === "Personal") {
      purpose_info["personal"] = {
        name: formData?.name,
        date_of_birth: formData?.date_of_birth,
        gender: formData?.gender,
        email: formData?.email,
        telephone: formData?.telephone,
      };
    }

    delete purpose_info.timeslot;
    delete purpose_info.schedule_date;

    const payload = {
      booked_entity_service: parseInt(params?.serviceId),
      schedule_date: formData?.schedule_date,
      entity_booking_schedule: formData.timeslot?.entity_schedule_id,
      travel_info: {
        purpose: formData?.purpose,
        purpose_info: purpose_info,
      },
    };

    setLoading(true);
    try {
      const { data } = await bookingInstance.clientCreateBookingService(
        payload
      );
      dispatch(setBookingResult(data?.data));
      message.success("You have successfully booked a service");
      push(pageRoutes.payment.replace(":bookingCode", data?.data?.code));
      // message.success("")
    } catch (err) {
      if (err.response) {
        if (Array.isArray(err.response.data.message)) {
          for (const message of err.response.data.message) {
            message(message);
          }
        } else {
          message.error(err.response.data.message);
        }
      } else if (err.request) {
        message.error(err.message);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <CustomLoader loading={loading}>
        <Typography.Title
          level={3}
          style={{ color: "#000000" }}
          className="text-center"
        >
          Booking preview
        </Typography.Title>
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {formData?.purpose && (
            <li className="bg-gray-100 p-4 rounded-2xl">
              <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                Purpose
              </Tag>
              <p className="font-bold py-1">{formData?.purpose}</p>
            </li>
          )}
          {formData?.purpose === "Travel" && (
            <>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Transport Operator
                </Tag>
                <p className="font-bold py-1">
                  {formData?.transport_operator || "---"}
                </p>
              </li>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Passport Number
                </Tag>
                <p className="font-bold py-1">
                  {formData?.passport_number || "---"}
                </p>
              </li>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  National Id
                </Tag>
                <p className="font-bold py-1">
                  {formData?.national_id_number || "---"}
                </p>
              </li>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Covid Code
                </Tag>
                <p className="font-bold py-1">
                  {formData?.covid_code || "---"}
                </p>
              </li>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Departure Country
                </Tag>
                <p className="font-bold py-1">
                  {formData?.departure_country || "---"}
                </p>
              </li>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Destination Country
                </Tag>
                <p className="font-bold py-1">
                  {formData?.arrival_country || "---"}
                </p>
              </li>
            </>
          )}
          {formData?.purpose === "Education" && (
            <>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  School Name
                </Tag>
                <p className="font-bold py-1">
                  {formData?.school_name || "---"}
                </p>
              </li>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  School Level
                </Tag>
                <p className="font-bold py-1">
                  {formData?.school_level || "---"}
                </p>
              </li>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Student Id
                </Tag>
                <p className="font-bold py-1">
                  {formData?.student_id || "---"}
                </p>
              </li>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Address
                </Tag>
                <p className="font-bold py-1">{formData?.address || "---"}</p>
              </li>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Country
                </Tag>
                <p className="font-bold py-1">{formData?.country || "---"}</p>
              </li>
            </>
          )}
          {/* EMPLOYMENT */}
          {formData?.purpose === "Employment" && (
            <>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Employer Name
                </Tag>
                <p className="font-bold py-1">
                  {formData?.employer_name || "---"}
                </p>
              </li>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Employment Status
                </Tag>
                <p className="font-bold py-1">
                  {formData?.employment_status || "---"}
                </p>
              </li>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Employer Email
                </Tag>
                <p className="font-bold py-1">
                  {formData?.employer_email || "---"}
                </p>
              </li>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Employer Location
                </Tag>
                <p className="font-bold py-1">
                  {formData?.employer_location || "---"}
                </p>
              </li>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Telephone
                </Tag>
                <p className="font-bold py-1">
                  {formData?.employer_telephone || "---"}
                </p>
              </li>
            </>
          )}
          {/* PERSONAL */}
          {formData?.purpose === "Personal" && (
            <>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Fullname
                </Tag>
                <p className="font-bold py-1">{formData?.name || "---"}</p>
              </li>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Date of Birth
                </Tag>
                <p className="font-bold py-1">
                  {getRawDayMonthYear(formData?.date_of_birth) || "---"}
                </p>
              </li>
              <li className="bg-gray-100 p-4 rounded-2xl">
                <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                  Gender
                </Tag>
                <p className="font-bold py-1">{formData?.gender || "---"}</p>
              </li>
            </>
          )}

          {formData?.travel_date && (
            <li className="bg-gray-100 p-4 rounded-2xl">
              <Tag color="blue" className="uppercase p-1 px-4 rounded-full">
                Depature Date
              </Tag>
              <p className="font-bold py-1">
                {getRawDayMonthYear(formData?.travel_date)}
              </p>
            </li>
          )}
        </ul>
        <br />

        <Form onFinish={bookNow}>
          <Form.Item
            name="shared"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? // eslint-disable-next-line no-undef
                      Promise.resolve()
                    : // eslint-disable-next-line no-undef
                      Promise.reject(
                        "You must consent to the collection of my scheduling, for healthcare and data collection purposes"
                      ),
              },
            ]}
          >
            <Checkbox>
              I confirm that all information provided is complete and accurate.
              I consent to the collection of my scheduling, for healthcare and
              data collection purposes.
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="covid"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? // eslint-disable-next-line no-undef
                      Promise.resolve()
                    : // eslint-disable-next-line no-undef
                      Promise.reject(
                        "You must agree that your information will be shared with state agencies mandated to manage COVID-19 testing data"
                      ),
              },
            ]}
          >
            <Checkbox>
              I understand my information will be shared with state agencies
              mandated to manage COVID-19 testing data.
            </Checkbox>
          </Form.Item>
          <div className="flex">
            <div className="flex gap-2 ml-auto">
              <Button shape="round" className="btn" onClick={onBack}>
                BACK
              </Button>
              <Button
                shape="round"
                className="btn btn-primary"
                htmlType="submit"
                type="primary"
              >
                SUBMIT
              </Button>
            </div>
          </div>
        </Form>
      </CustomLoader>
    </div>
  );
};

export default BookingPreview;
