export class OrganizationPermissions {
  static CREATE_ENTITY = 1;
  static CREATE_SCHEDULE = 2;
}

export class Dashboards {
  static PATIENT = 1;
  static LAB_ADMIN = 2;
  static KOLDCHAIN_ADMIN = 3;
  static DOCTOR = 4;
}

export class UserRole {
  static PATIENT = "patient";
  static LAB_ADMIN = "lab admin";
  static KOLDCHAIN_ADMIN = "koldchain admin";
  static DOCTOR = "doctor";
}

export const APP_ROLES = {
  Patient: {
    dashboard: Dashboards.PATIENT,
    all: true,
    permissions: [],
    banned: [],
  },
  "Lab Admin": {
    dashboard: Dashboards.LAB_ADMIN,
    all: true,
    permissions: [],
    banned: [],
  },
  "Lab Technician": {
    dashboard: Dashboards.LAB_ADMIN,
    all: false,
    permissions: [],
    banned: [
      OrganizationPermissions.CREATE_ENTITY,
      OrganizationPermissions.CREATE_SCHEDULE,
    ],
  },
  "KoldChain Admin": {
    dashboard: Dashboards.KOLDCHAIN_ADMIN,
    all: true,
    permissions: [],
    banned: [],
  },
  Doctor: {
    dashboard: Dashboards.DOCTOR,
    all: true,
    permissions: [],
    banned: [],
  },
};
