import React from "react";
import ContentWrapper from "@components/ContentWrapper";
import { Card } from "antd";
const LabNotifications = () => {
  return (
    <ContentWrapper title="Lab notifications">
      <Card>
        <p className="text-lg font-black">
          Select the notification channels and types you wish to receive for
          your {"lab's"} notifications
        </p>
        <div className="py-8 grid grid-cols-2">
          <ul className="grid grid-cols-1 gap-2">
            <li className="text-lg font-bold mb-4">Notifications Channels</li>
            <li className="flex items-center gap-4">
              <input
                className="w-5 h-5 accent-yellow-500 rounded-2xl"
                type="checkbox"
              />{" "}
              <label className="">Email</label>
            </li>
            <li className="flex items-center gap-4">
              <input
                className="w-5 h-5 accent-yellow-500 rounded-2xl"
                type="checkbox"
              />{" "}
              <label className="">SMS</label>
            </li>
            <li className="flex items-center gap-4">
              <input
                className="w-5 h-5 accent-yellow-500 rounded-2xl"
                type="checkbox"
              />{" "}
              <label className="">Push Notifications</label>
            </li>
          </ul>
          <ul className="grid grid-cols-1 gap-2">
            <li className="text-lg font-bold mb-4">Notifications Types</li>
            <li className="flex items-center gap-4">
              <input
                className="w-5 h-5 accent-yellow-500 rounded-2xl"
                type="checkbox"
              />{" "}
              <label className="">Booking Notifications</label>
            </li>
            <li className="flex items-center gap-4">
              <input
                className="w-5 h-5 accent-yellow-500 rounded-2xl"
                type="checkbox"
              />{" "}
              <label className="">Invoice Notifications</label>
            </li>
            <li className="flex items-center gap-4">
              <input
                className="w-5 h-5 accent-yellow-500 rounded-2xl"
                type="checkbox"
              />{" "}
              <label className="">Payment Notifications</label>
            </li>
          </ul>
        </div>
      </Card>
    </ContentWrapper>
  );
};

export default LabNotifications;
