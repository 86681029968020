import React, { useEffect, useMemo, useState } from "react";
import ContentWrapper from "@components/ContentWrapper";
import { Button, Col, Form, Row, Select } from "antd";
import FileUploader from "@components/form/FileUploader";
import { useDispatch, useSelector } from "react-redux";
import { koldChainFetchOrganisationEntitiesThunk } from "@store/thunks/entity.thunk";
import { getOrganizations } from "@store/slices/entity.slice";
import { koldChainUploadBulkyServices } from "@store/thunks/service.thunk";

const UploadServices = () => {
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const organizations = useSelector(getOrganizations);
  const organizationsOptions = useMemo(
    () =>
      organizations
        .map((data, index) => ({
          id: index,
          label: data?.info?.name,
          value: data?.entity_reg,
        }))
        .filter((data) => data?.label || data?.value),
    [organizations]
  );

  const onSuccess = () => {
    setFile(null);
  };

  const onFinishHandler = (values) => {
    const formData = new FormData();
    formData.append("file", file[0]);
    formData.append("lab_reg_number", values.lab);

    file &&
      file.length > 0 &&
      dispatch(
        koldChainUploadBulkyServices({ data: formData, onSuccess: onSuccess })
      );
  };

  useEffect(() => {
    dispatch(koldChainFetchOrganisationEntitiesThunk());
  }, [dispatch]);
  return (
    <ContentWrapper title={"Upload Services"}>
      <Button type="primary" className="rounded-3xl font-bold px-5 py-2 h-auto">
        Download Format
      </Button>
      <br />
      <p className="text-muted py-2">
        Services upload should be in this format
      </p>
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div>
          <Form onFinish={onFinishHandler} layout="vertical">
            <Form.Item required label="Lab" name={"lab"}>
              <Select
                placeholder="Select Organization"
                options={organizationsOptions}
              />
            </Form.Item>
            <FileUploader
              required
              filePickHandler={(_files) => setFile(_files)}
            />
            <center>
              <Button
                htmlType="submit"
                shape="round"
                className="btn-sm btn-primary"
              >
                Upload
              </Button>
            </center>
          </Form>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default UploadServices;
