import { Button } from "antd";

const CustomButton = ({
  title,
  variant = "primary",
  type = "button",
  onClick = () => {},
}) => {
  const buttonVariant = variant === "primary" ? "btn-primary" : "";
  return (
    <Button
      htmlType={type}
      onClick={onClick}
      className={`btn ${buttonVariant}`}
    >
      {title}
    </Button>
  );
};

export default CustomButton;
