import React from "react";
import errorImage from "@assets/images/403_error.png";
import { Button } from "antd";
import envConfigs from "@utils/configs/env.config";
import useLogout from "@utils/custom_hooks/useLogout.hook";
const Error403 = () => {
  const logout = useLogout();
  return (
    <div className="w-full h-[100svh] flex flex-col items-center justify-center ">
      <div className="max-w-[70%] relative">
        <img width="70%" className="mx-auto" src={errorImage} alt="" />
      </div>
      <div className="">
        <h1 className="text-center font-bold text-3xl lg:text-[50px] pb-4">
          Access Denied/Forbidden
        </h1>

        <p className="text-center">
          The page or resources you are trying to access is absolutely forbidden
          for some reasons
        </p>
        <br />
        <div className="flex justify-center">
          <Button onClick={() => logout()}> Logout </Button>
          <Button
            type="link"
            className="ml-2"
            href={
              envConfigs[process.env.REACT_APP_ENV || "development"].casHomeUrl
            }
          >
            Go to home
          </Button>
        </div>
        <div className="flex justify-center mt-3">
          <a
            href={
              envConfigs[process.env.REACT_APP_ENV || "development"]
                .supportMailTo
            }
            className="text-center text-danger"
          >
            Get to support support center
          </a>
        </div>
      </div>
    </div>
  );
};

export default Error403;
