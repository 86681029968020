import { Col, Modal, Row, Tag, Typography } from "antd";
import moment from "moment";
import React from "react";
// import BookingCertificate from "../../../../components/BookingCertificate";
import { getBookingEntityTimeSlot } from "../../utils/date_time_format/index";
const ViewModal = ({ open, onClose, booking }) => {
  return (
    <Modal visible={open} onCancel={onClose} footer={null}>
      <Row style={{ rowGap: ".4em" }}>
        <Col xs={24}>
          <Typography.Title level={4}>Schedule Information</Typography.Title>
        </Col>
        <Col xs={12}>
          <Typography.Title level={5}>Booking Code: </Typography.Title>
        </Col>
        <Col xs={12}>
          <Typography level={5}>{booking?.code || "Not provided"}</Typography>
        </Col>
        <Col xs={12}>
          <Typography.Title level={5}>Payment Status: </Typography.Title>
        </Col>
        <Col xs={12}>
          {booking?.payed ? (
            <Tag color="green">Has Paid</Tag>
          ) : (
            <Tag color="red">Not paid</Tag>
          )}
        </Col>
        <Col xs={12}>
          <Typography.Title level={5}>Scheduled Date: </Typography.Title>
        </Col>
        <Col xs={12}>
          <Typography level={5}>
            {moment(booking?.schedule_date).format("dddd DD MMMM, YYYY")}
          </Typography>
        </Col>
        <Col xs={12}>
          <Typography.Title level={5}>Scheduled time: </Typography.Title>
        </Col>
        <Col xs={12}>
          <Typography level={5}>
            {getBookingEntityTimeSlot(
              booking?.entity_booking_schedule?.time_slot
            )}
          </Typography>
        </Col>
        <Col xs={24}>
          <Typography.Title level={4}>
            Entity Service Information
          </Typography.Title>
        </Col>
        <Col xs={12}>
          <Typography.Title level={5}>Service Name: </Typography.Title>
        </Col>
        <Col xs={12}>
          <Typography level={5}>
            {booking?.booked_entity_service?.name || "Not provided"}
          </Typography>
        </Col>
        <Col xs={12}>
          <Typography.Title level={5}>Service Type: </Typography.Title>
        </Col>
        <Col xs={12}>
          <Typography level={5}>
            {booking?.booked_entity_service?.service || "Not provided"}
          </Typography>
        </Col>
        <Col xs={12}>
          <Typography.Title level={5}>Service Price: </Typography.Title>
        </Col>
        <Col xs={12}>
          <Typography level={5}>
            {`${booking?.booked_entity_service?.price.currency} ${booking?.booked_entity_service?.price.amount}`}
          </Typography>
        </Col>
        <Col xs={24}>
          <Typography.Title level={4}>Client's Information</Typography.Title>
        </Col>
        <Col xs={12}>
          <Typography.Title level={5}>Name: </Typography.Title>
        </Col>
        <Col xs={12}>
          <Typography level={5}>
            {`${booking?.booked_by?.firstName} ${booking?.booked_by?.lastName}`}
          </Typography>
        </Col>
        <Col xs={12}>
          <Typography.Title level={5}>Email: </Typography.Title>
        </Col>
        <Col xs={12}>
          <Typography level={5}>{booking?.booked_by?.email}</Typography>
        </Col>
        <Col xs={12}>
          <Typography.Title level={5}>Phone Number: </Typography.Title>
        </Col>
        <Col xs={12}>
          <Typography level={5}>{booking?.booked_by?.phoneNumber}</Typography>
        </Col>
        <Col xs={12}>
          <Typography.Title level={5}>Date of Birth: </Typography.Title>
        </Col>
        <Col xs={12}>
          <Typography level={5}>
            {moment(booking?.booked_by?.dateOfBirth).format("dddd DD MM,YYYY")}
          </Typography>
        </Col>
      </Row>
    </Modal>
  );
};

export default ViewModal;
