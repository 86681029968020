import APIClient from "./api";

export default class ServiceClass extends APIClient {
  constructor() {
    super();
    this.addAccessToken();
  }

  getApprovedServices = () => {
    return this.get("/booking/manager/koldchain/services/list?s=active");
  };
  getUnApprovedServices = () => {
    return this.get("/booking/manager/koldchain/services/list?s=unapproved");
  };
  approveService = (serviceID) =>
    this.patch("/booking/manager/kc/approve/service/", {
      service_id: serviceID,
    });

  achieveService = (serviceID) =>
    this.put("/booking/manager/archive/service/", {
      service_id: serviceID,
    });

  uploadBulkyServices = (data) => {
    this.useMultipart();
    return this.post("/booking/manager/bulk/service/upload/", data);
  };
}
