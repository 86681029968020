import { Button, DatePicker, Form, Input, Select } from "antd";
import { getCountryOptions } from "../../../../utils/helpers";
const EntityServiceForm = ({ onNext, onBack, formData, setFormData }) => {
  const [form] = Form.useForm();

  const onSubmit = (values) => {
    setFormData(values || {});
    onNext && onNext();
  };
  const initValues = {
    purpose: formData?.purpose ?? "Travel",
    transport_operator: formData?.transport_operator ?? "",
    passport_number: formData?.passport_number ?? "",
    national_id_number: formData?.national_id_number ?? "",
    covid_code: formData?.covid_code ?? "",
    departure_country: formData?.departure_country ?? "",
    arrival_country: formData?.arrival_country ?? "",

    //EDUCATION
    school_name: formData?.school_name ?? "",
    school_level: formData?.school_level ?? "",
    student_id: formData?.student_id ?? "",
    address: formData?.school_address ?? "",
    country: formData?.country ?? "",

    //EMPLOYMENT
    employer_name: formData?.employer_name ?? "",
    employment_status: formData?.employment_status ?? "",
    employer_email: formData?.employer_email ?? "",
    employer_location: formData?.employer_location ?? "",
    employer_telephone: formData?.employer_telephone ?? "",

    //PERSONAL
    name: formData?.name,
    date_of_birth: formData?.date_of_birth,
    gender: formData?.gender,

    // GENERIC
    travel_date: formData?.travel_date ?? "",
    email: formData?.student_email ?? "",
    telephone: formData?.telephone ?? "",
  };

  return (
    <div className="w-100">
      <Form
        initialValues={initValues}
        onFinish={onSubmit}
        labelCol={{ span: "8" }}
        size="large"
        form={form}
        layout="vertical"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="col-span-3 grid grid-cols-3">
            <Form.Item label="Purpose" name={"purpose"}>
              <Select
                onChange={(value) => {
                  setFormData({ purpose: value || "Travel" });
                }}
                options={[
                  { label: "Travel", value: "Travel" },
                  { label: "Education", value: "Education" },
                  { label: "Employment", value: "Employment" },
                  {
                    label: "Personal Wellness/Care",
                    value: "Personal",
                  },
                ]}
              />
            </Form.Item>
          </div>
          {/* Travel Information */}
          {formData?.purpose === "Travel" && (
            <>
              <Form.Item
                name="transport_operator"
                className=""
                label="Transport Operator"
              >
                <Input placeholder="Enter name of airline, bus, ship" />
              </Form.Item>
              <Form.Item name="passport_number" label="Passport Number">
                <Input placeholder="Enter passport number" />
              </Form.Item>
              <Form.Item name="national_id_number" label="National ID">
                <Input placeholder="Enter National ID" />
              </Form.Item>
              <Form.Item name="covid_code" label="Covid Code">
                <Input placeholder="Enter Covid ID" />
              </Form.Item>
              <Form.Item name="departure_country" label="Depature Country">
                <Select
                  placeholder={"Select Departure Country"}
                  options={getCountryOptions}
                />
              </Form.Item>
              <Form.Item name="arrival_country" label="Destination Country">
                <Select
                  placeholder={"Select Destination Country"}
                  options={getCountryOptions}
                />
              </Form.Item>
              <Form.Item name="travel_date" label="Depature Date">
                <DatePicker showTime className="form-control" />
              </Form.Item>
            </>
          )}
          {/* Education Information */}
          {formData?.purpose === "Education" && (
            <>
              <Form.Item name="school_name" className="" label="School name">
                <Input placeholder="Enter name of school" />
              </Form.Item>
              <Form.Item name="school_level" label="School Level">
                <Input placeholder="Enter school level" />
              </Form.Item>
              <Form.Item name="student_id" label="Student ID">
                <Input placeholder="Enter Student Identification" />
              </Form.Item>
              <Form.Item name="email" label="Student's Email">
                <Input placeholder="Enter school's email" />
              </Form.Item>
              <Form.Item name="telephone" label="Student's Telephone">
                <Input placeholder="Enter school's telephone" />
              </Form.Item>
              <Form.Item name="address" label="School's Address">
                <Input placeholder="Enter school's address" />
              </Form.Item>
              <Form.Item name="country" label="Country">
                <Select
                  placeholder="Select country in which school resides"
                  options={getCountryOptions}
                />
              </Form.Item>
              <Form.Item name="travel_date" label="Depature Date">
                <DatePicker showTime className="form-control" />
              </Form.Item>
            </>
          )}
          {/*  EMPLOYMENT  */}
          {formData?.purpose === "Employment" && (
            <>
              <Form.Item name="employer_name" className="" label="Full name">
                <Input placeholder="Enter Employer/Organisation name" />
              </Form.Item>
              <Form.Item label="Employment Status" name={"employment_status"}>
                <Select
                  options={[
                    { label: "Contract", value: "contract" },
                    { label: "Full time", value: "full-time" },
                  ]}
                />
              </Form.Item>
              <Form.Item name="employer_email" label="Employer Email">
                <Input placeholder="Enter employee's email" />
              </Form.Item>
              <Form.Item name="employer_location" label="Employer Location">
                <Input placeholder="Enter of location" />
              </Form.Item>
              <Form.Item name="employer_telephone" label="Employer Telephone">
                <Input placeholder="Enter employer's telephone" />
              </Form.Item>
              <Form.Item name="travel_date" label="Depature Date">
                <DatePicker showTime className="form-control" />
              </Form.Item>
            </>
          )}
          {/* PERSONAL WELLNESS  */}
          {formData?.purpose === "Personal" && (
            <>
              <Form.Item name="name" className="" label="Full name">
                <Input placeholder="Enter of full name" />
              </Form.Item>
              <Form.Item name="date_of_birth" label="Date of Birth">
                <DatePicker showTime={false} className="form-control" />
              </Form.Item>
              <Form.Item initialValue={"male"} label="Gender" name={"gender"}>
                <Select
                  options={[
                    { label: "Male", value: "male" },
                    { label: "Female", value: "female" },
                  ]}
                />
              </Form.Item>
              <Form.Item name="email" label="Email">
                <Input placeholder="Enter email" />
              </Form.Item>
              <Form.Item name="telephone" label="Phone number">
                <Input placeholder="Enter phone number" />
              </Form.Item>
              <Form.Item name="travel_date" label="Depature Date">
                <DatePicker showTime={false} className="form-control" />
              </Form.Item>
            </>
          )}
        </div>
        <div className="flex mt-5">
          <div className="ml-auto space-x-4">
            <Button shape="round" className="btn" onClick={onBack}>
              BACK
            </Button>
            <Button
              // hidden={canSkip}
              shape="round"
              className="btn btn-primary"
              htmlType="submit"
              type="primary"
            >
              NEXT
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EntityServiceForm;
