import { message, notification } from "antd";
import { APIClient } from "../../services/api";
import generateQueryParams from "../../utils/generateQueryParams";
import {
  featuredServiceUrl,
  featuredServicesUrl,
  timeSlotsUrl,
} from "../../utils/network/endpoints";
import { getUserIpLocation } from "../../utils/persistent_data/local_storage";
import {
  CLEAR_BOOKING_FIELDS,
  FETCH_BOOK_NOW_SERVICES_FAILED,
  FETCH_BOOK_NOW_SERVICES_REQUEST,
  FETCH_BOOK_NOW_SERVICES_SUCCESS,
  FETCH_TIME_SLOTS_FAILED,
  FETCH_TIME_SLOTS_REQUEST,
  FETCH_TIME_SLOTS_SUCCESS,
  SET_BOOKINGS_TRAVEL_INFO,
  SET_BOOKING_DATE_SELECTED,
  SET_BOOKING_RESULT,
  SET_TIMESLOT_CHOSEN,
} from "../actionCreators/bookNowActionCreator";

const fetchBookNowServiceRequest = () => {
  return {
    type: FETCH_BOOK_NOW_SERVICES_REQUEST,
  };
};

const fetchBookNowServiceSuccess = (payload) => {
  return {
    type: FETCH_BOOK_NOW_SERVICES_SUCCESS,
    payload,
  };
};

const fetchBookNowServiceFailed = () => {
  return {
    type: FETCH_BOOK_NOW_SERVICES_FAILED,
  };
};

const fetchTimeSlotsRequest = () => {
  return {
    type: FETCH_TIME_SLOTS_REQUEST,
  };
};

const fetchTimeSlotsSuccess = (payload) => {
  return {
    type: FETCH_TIME_SLOTS_SUCCESS,
    payload,
  };
};

const fetchTimeSlotsFailed = () => {
  return {
    type: FETCH_TIME_SLOTS_FAILED,
  };
};

export const setTimslotChosen = (timeslot) => {
  return {
    type: SET_TIMESLOT_CHOSEN,
    payload: timeslot,
  };
};

export const setBookingsTravelInfo = (info) => {
  return {
    type: SET_BOOKINGS_TRAVEL_INFO,
    payload: info,
  };
};
export const clearBookingFields = () => ({ type: CLEAR_BOOKING_FIELDS });
export const setBookingsDateSelectedInfo = (info) => ({
  type: SET_BOOKING_DATE_SELECTED,
  payload: info,
});

export const setBookingResult = (payload) => ({
  type: SET_BOOKING_RESULT,
  payload,
});

export const getAvailableBookings = (serviceId, date) => (dispatch) => {
  dispatch(fetchTimeSlotsRequest());
  const API = new APIClient();
  API.addAccessToken();
  API.get(timeSlotsUrl(serviceId, date))
    .then((res) => {
      dispatch(fetchTimeSlotsSuccess(res.data.data));
    })
    .catch((err) => {
      dispatch(fetchTimeSlotsFailed());
      if (err.response) {
        message.error(err.response.data.message);
      } else if (err.request) {
        notification.error({
          message: "Fetching services failed",
          description: err.message,
        });
      }
    });
};

export const getFeaturedServices = () => async (dispatch) => {
  const API = new APIClient();
  API.addAccessToken();
  dispatch(fetchBookNowServiceRequest());
  try {
    const location = getUserIpLocation();
    const response = await API.get(
      featuredServiceUrl("Test", location?.country_code)
    );
    dispatch(fetchBookNowServiceSuccess(response.data.data));
  } catch (error) {
    dispatch(fetchBookNowServiceFailed());
    console.log("Error Fetching featured services", error);
  }
};

export const fetchBookNowServices = (object) => (dispatch) => {
  dispatch(fetchBookNowServiceRequest());
  const API = new APIClient();
  const queryParams = generateQueryParams(object);

  API.get(`${featuredServicesUrl}/?${queryParams}`)
    .then((res) => {
      dispatch(fetchBookNowServiceSuccess(res.data.data));
    })
    .catch((err) => {
      dispatch(fetchBookNowServiceFailed());
      message.error(err.message);
    });
};
