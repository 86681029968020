import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Row, Statistic } from "antd";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentWrapper from "@components/ContentWrapper";
import { fetchDashboardSummaryDataThunk } from "@store/thunks/dashboard.thunk";
import useCustomSelector from "@utils/custom_hooks/useCustomSelector";
import {
  getUsername,
  getUserProfile,
} from "@utils/persistent_data/local_storage";
import { UserRole } from "@utils/roles";

const Dashboard = () => {
  const dispatch = useDispatch();
  const user = getUsername().split("@")[0];
  const group = getUserProfile()?.group;
  const { loading, state } = useCustomSelector((state) => state.dashboard);

  const loadDashboardSummaryData = useCallback(() => {
    group.toLowerCase() !== UserRole.DOCTOR &&
      dispatch(fetchDashboardSummaryDataThunk());
  }, [dispatch, group]);

  useEffect(() => {
    loadDashboardSummaryData();
  }, [loadDashboardSummaryData]);

  return (
    <ContentWrapper loading={loading}>
      <h1 className="mt-5 text-3xl font-thin">
        Welcome <span className="text-capitalize">{user}</span> 👋
      </h1>
      <h4 className="heading"> Dashboard</h4>
      <Row gutter={[18]} className="mt-5">
        {Object.keys(state.summary).map((key) => {
          const _data = state.summary;
          return (
            <Col sm={12} md={6} key={key}>
              <Card
                className="card-1 border-0 shadow-lg"
                style={{ backgroundColor: "#f5f5f5", borderRadius: "10px" }}
              >
                <FontAwesomeIcon
                  style={{ fontSize: "2rem" }}
                  icon={faListAlt}
                />
                <Statistic
                  className="text-center"
                  title={
                    <h3 className="uppercase">{key.replaceAll("_", " ")}</h3>
                  }
                  value={_data[key]}
                  // loading={summary.loading}
                />
              </Card>
            </Col>
          );
        })}
      </Row>
    </ContentWrapper>
  );
};

export default Dashboard;
