/* eslint-disable no-undef */
/* eslint-disable no-empty */
import React, { useState, useEffect } from "react";
import { Button, Form, Input, Checkbox, Select } from "antd";
import { message } from "antd";
import { getUsername } from "@utils/persistent_data/local_storage";
import { APIClient } from "@services/api";
import { useHistory } from "react-router";
import { bookingServiceUrl, entityUrl } from "@utils/network/endpoints";
import ContentWrapper from "@components/ContentWrapper";
import pageRoutes from "@utils/page-routes";
import { useDispatch } from "react-redux";
import { createEntityServicesThunk } from "@store/thunks/service.thunk";
import useCustomSelector from "@utils/custom_hooks/useCustomSelector";
let currencies = require("@utils/currencies.json");

const CreateService = () => {
  const INITIAL_FORM_VALUES = {
    payment_required: false,
    sharing_consent_required: false,
    service: -1,
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const serviceState = useCustomSelector((state) => state.service);
  const [entity, setEntity] = useState("");
  const [service, setServices] = useState([]);

  const user = getUsername();

  const getBookableServices = async () => {
    const type = "Test";
    const API = new APIClient();
    API.addAccessToken();
    try {
      const response = await API.get(bookingServiceUrl(type));
      setServices(response.data.data);
    } catch (error) {
      if (error.response) {
        message.error(error.response.data.message);
      } else if (error.request) {
        message.error(error.message);
      }
    }
  };

  const getEntity = async () => {
    const API = new APIClient();
    API.addAccessToken();
    try {
      const response = await API.get(entityUrl);
      setEntity(response?.data?.data?.registrationNumber);
    } catch (error) {}
  };

  useEffect(() => {
    getBookableServices();
    getEntity();
  }, []);

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      sharing_consent_required: data.sharing_consent_required
        ? data.sharing_consent_required
        : false,
      payment_required: data.payment_required ? data.payment_required : false,
      bookable_entity: entity,
      username: user,
    };

    dispatch(createEntityServicesThunk(payload))
      .unwrap()
      .then((res) => {
        history.push(pageRoutes.service_unapproved_list);
      });
  };

  return (
    <ContentWrapper title="Create Service" hasContainer>
      <div className="w-full d-flex  align-items-center">
        <Form
          initialValues={INITIAL_FORM_VALUES}
          onFinish={onSubmit}
          size="large"
          labelCol={{ span: 24 }}
          className=""
        >
          <Form.Item
            rules={[{ required: true, message: "Service name is required" }]}
            style={{ margin: "0" }}
            label="Service name"
            name="service_name"
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "Service identifier is required" },
            ]}
            style={{ margin: "0" }}
            label="Service identifier"
            name="service_identifier"
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ margin: "0" }}
            label="Service"
            name="service"
            rules={[
              {
                required: true,
                message: "Service is required",
              },
              {
                validator: (_, value) => {
                  return parseInt(value) !== -1
                    ? Promise.resolve()
                    : Promise.reject("Select a service type");
                },
              },
            ]}
          >
            <Select placeholder="Select a service">
              <Select.Option value={-1}>Select a service</Select.Option>

              {service?.map((item, index) => (
                <Select.Option key={index.toString()} value={item.service_id}>
                  {item?.details?.name} - {item?.details?.target} -
                  {item?.details?.method}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="currency"
            rules={[
              { required: true, message: "Currency is required" },
              {
                validator: (_, value) => {
                  return parseInt(value) !== -1
                    ? Promise.resolve()
                    : Promise.reject("Select a currency");
                },
              },
            ]}
            style={{ margin: "0" }}
            label="Currency"
          >
            <Select placeholder="Select Currency">
              <Select.Option value={-1}>Select a Currency</Select.Option>

              {currencies?.map((item, index) => (
                <>
                  <Select.Option value={item.code} key={index.toString()}>
                    {item.code} - {item.currency}
                  </Select.Option>
                </>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="price"
            rules={[{ required: true, message: "Price is required" }]}
            label="Price(Service charge)"
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            className="no-border"
            valuePropName="checked"
            name="payment_required"
          >
            <Checkbox>Payment required?</Checkbox>
          </Form.Item>
          <Form.Item
            className="no-border"
            name="sharing_consent_required"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("You must agree to sharable consent")
                      ),
              },
            ]}
          >
            <Checkbox>
              I understand my information will be shared with clients on the
              platform.
            </Checkbox>
          </Form.Item>

          <Button
            shape="round"
            loading={serviceState.loading}
            htmlType="submit"
            block
            type="primary"
            className="button"
          >
            Create Service
          </Button>
        </Form>
      </div>
    </ContentWrapper>
  );
};

export default CreateService;
