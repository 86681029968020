import { message } from "antd";
import PaymentService from "../../services/payment.service";
import { addCommissionData, setCommissions } from "../slices/payment.slice";
const paymentService = new PaymentService();
// get all commission rates

const { createAsyncThunk } = require("@reduxjs/toolkit");

export const createCommissionThunk = createAsyncThunk(
  "create/commission",
  async (payload, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const { data } = await paymentService.createPaymentCommission(
        payload?.commission,
        payload?.service_type,
        payload?.country
      );
      dispatch(addCommissionData(data.data));
      message.success("Commission added successfully");
      return fulfillWithValue(paymentService.fulfilled(data.data));
    } catch (error) {
      return rejectWithValue(paymentService.rejected(error));
    }
  }
);

export const listCommissionsThunk = createAsyncThunk(
  "list/commission",
  async (data, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const res = await paymentService.getPaymentCommissions();

      dispatch(setCommissions(res.data?.data));
      return fulfillWithValue({ success: true });
    } catch (error) {
      return rejectWithValue({ success: false });
    }
  }
);
