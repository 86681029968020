import React from "react";
import { getUserProfile } from "../utils/persistent_data/local_storage";
import { APP_ROLES, Dashboards } from "../utils/roles";
import Error403 from "../pages/Error/Error403";
import MainDashboard from "../pages/MainDashboard";

const RedirectUser = () => {
  const group = getUserProfile()?.group;
  return APP_ROLES[group]?.dashboard === Dashboards.PATIENT ? (
    <MainDashboard />
  ) : APP_ROLES[group]?.dashboard === Dashboards.LAB_ADMIN ? (
    <MainDashboard /> 
  ) : APP_ROLES[group]?.dashboard === Dashboards.KOLDCHAIN_ADMIN ? (
    <MainDashboard />
  ) : APP_ROLES[group]?.dashboard === Dashboards.DOCTOR ? (
    <MainDashboard />
  ) : (
    <Error403 />
  );
};

export default RedirectUser;
