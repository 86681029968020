import { APIClient } from "./api";

export default class PaymentService extends APIClient {
  constructor() {
    super();
    this.addAccessToken();
  }

  createPaymentCommission = (commission, service_type, country) => {
    return this.post("/payment/commission/", {
      commission_percentage: commission,
      service_type,
      country,
    });
  };
  getPaymentCommissions = () => {
    return this.get("/payment/commission/list");
  };

  verifyPayment = (referenceId, invoiceCode) => {
    return this.get(`/payment/verify/${referenceId}/${invoiceCode}/`);
  };
}
