import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Select,
  Button,
  DatePicker,
  message,
} from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { UserRole } from "@utils/roles";
import UserAccessibilityView from "@components/decorators/UserAccessibilityView";
import { APIClient } from "@services/api";
import { getOrCreateUserUrl } from "@utils/network/endpoints";
import ContentWrapper from "@components/ContentWrapper";
import useLoading from "@utils/custom_hooks/useLoading";
import CountryCodeSelector from "@components/CountryCodeSelector";
import { useHistory } from "react-router-dom";
import pageRoutes from "@utils/page-routes";

const ReferralReview = ({ referralUser }) => {
  const getPhoneNumber = useMemo(() => {
    const regex = /^(\([+][0-9]{3})\)/;
    if (regex.test(referralUser?.phone_number)) {
      const findIndexOfLastClosingBracket =
        referralUser?.phone_number.split("").findIndex((ch) => ch === ")") + 1;
      return {
        country_code: referralUser.phone_number.slice(
          0,
          findIndexOfLastClosingBracket
        ),
        phone_number: referralUser.phone_number.replace(regex, "").trim(),
      };
    }
    return {
      country_code: "Unspecified",
      phone_number: "Unspecified",
    };
  }, [referralUser]);
  return (
    <Row gutter={[0, 20]}>
      <Col lg={10}>
        <h4>Referral's Name</h4>
        <p>
          {referralUser?.first_name} {referralUser?.last_name}
        </p>
      </Col>
      <Col lg={14}>
        <h4>Email Address</h4>
        <p>{referralUser?.email}</p>
      </Col>

      <Col lg={10}>
        <h4>Country Code</h4>
        <p>{getPhoneNumber.country_code}</p>
      </Col>
      <Col lg={14}>
        <h4>Phone Number</h4>
        <p>{getPhoneNumber.phone_number}</p>
      </Col>

      <Col lg={10}>
        <h4>Date of Birth</h4>
        <p>{referralUser?.dob || "Unspecified"}</p>
      </Col>
      <Col lg={14}>
        <h4>Gender</h4>
        <p>{referralUser?.gender || "Unspecified"}</p>
      </Col>
    </Row>
  );
};

class ReferralSearchType {
  static INITIAL = "initial";
  static PANABIOS = "panabios";
  static GENERAL = "general";
}

function Referral() {
  const { status, onFULFILLED, onPENDING, onREJECTED } = useLoading();
  const history = useHistory();
  const [searchType, setSearchType] = useState(ReferralSearchType.INITIAL);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [referralUser, setReferralUser] = useState(null);
  const [form] = Form.useForm();

  const userCodeInputOnChangeHandler = (e) => {
    setSearchType(
      e.target.value !== ""
        ? ReferralSearchType.PANABIOS
        : ReferralSearchType.INITIAL
    );
  };

  const onSubmiting = (values) => {
    if (referralUser) {
      history.push(pageRoutes.referral_booking);
      return;
    }
    const data = Object.keys(values)
      .map((key) => values[key] !== undefined && { [key]: values[key] })
      .filter((data) => data !== false)
      .reduce((prev, next) => ({ ...prev, ...next }), {});
    searchForReferral(data);
  };

  const searchForReferral = useCallback(
    (data) => {
      console.log(data);
      const API = new APIClient();
      onPENDING();
      API.addAccessToken();
      API.post(getOrCreateUserUrl, data)
        .then(({ data }) => {
          if (data.data?.error) {
            message.error("No referral found, please try again");
          } else {
            message.success("Referral Search Completed Successfully");
            setReferralUser(data?.data);
            setIsPreviewMode(true);
          }
          onFULFILLED();
        })
        .catch((error) => {
          onREJECTED();
        });
    },
    [onFULFILLED, onPENDING, onREJECTED]
  );

  return (
    <ContentWrapper title="Referral" loading={status.loading}>
      <Card title={!isPreviewMode ? "Referral Information" : "Preview"}>
        {!isPreviewMode ? (
          <Form form={form} layout="vertical" onFinish={onSubmiting}>
            {(searchType === ReferralSearchType.PANABIOS ||
              searchType === ReferralSearchType.INITIAL) && (
              <Row>
                <Col md={12}>
                  <Form.Item label="PANABIOS ID" name="user_code">
                    <Input
                      onBlur={userCodeInputOnChangeHandler}
                      placeholder='ID Begins with "PA"'
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {searchType === ReferralSearchType.INITIAL && (
              <center>
                <h2>OR</h2>
              </center>
            )}
            {(searchType === ReferralSearchType.GENERAL ||
              searchType === ReferralSearchType.INITIAL) && (
              <>
                <div className="d-flex justify-content-between align-items-center py-1">
                  <h4>
                    <strong>Referral's Name</strong>
                  </h4>
                  <Button shape="round">Reset</Button>
                </div>
                <Row gutter={[12]}>
                  <Col sm={24} lg={12}>
                    <Form.Item label="" name="first_name">
                      <Input placeholder="First Name" />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item name="last_name">
                      <Input placeholder="Last Name" />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item label="Email Address" name="email">
                      <Input placeholder="Enter email address" />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item label="Country Code" name="country_code">
                      <CountryCodeSelector />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item label="Phone Number" name="phone_number">
                      <Input placeholder="Enter phone number" />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item label="Date of Birth" name="dob">
                      <DatePicker style={{ width: "100%", border: "unset" }} />
                    </Form.Item>
                  </Col>
                  <Col sm={24} lg={12}>
                    <Form.Item label="Gender" name="gender">
                      <Select
                        placeholder="Select gender"
                        options={[
                          { value: "male", label: "Male" },
                          { value: "female", label: "Female" },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        ) : (
          <ReferralReview referralUser={referralUser} />
        )}
        <div className="d-flex justify-content-end">
          {isPreviewMode && (
            <Button
              className="button mr-4"
              type="ghost"
              onClick={() => setIsPreviewMode(false)}
            >
              Update
            </Button>
          )}
          <Button
            className="button"
            type="primary"
            onClick={() => form.submit()}
          >
            Continue
          </Button>
        </div>
      </Card>
    </ContentWrapper>
  );
}

export default UserAccessibilityView({
  component: Referral,
  allowedUsers: [UserRole.DOCTOR],
});
