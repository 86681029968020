import { Button, Col, Empty, Pagination, Row, Typography } from "antd";
import React, { useMemo, useState } from "react";
import ServiceComponent from "@components/ServiceComponent";
import { LeftOutlined } from "@ant-design/icons";
import CustomLoader from "@components/CustomLoader";
import { Link } from "react-router-dom";
import ListServicesLayout from "@components/layouts/ListServicesLayout";
import useCustomSelector from "@utils/custom_hooks/useCustomSelector";

const PAGINATE_BY = 5;

const SearchPage = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const { loading, state } = useCustomSelector((state) => state.service);
  const page = useMemo(() => {
    const start = pageIndex <= 1 ? pageIndex - 1 : pageIndex - 1 + PAGINATE_BY;
    const end = pageIndex <= 1 ? PAGINATE_BY - 1 : start + PAGINATE_BY;
    return [start, end];
  }, [pageIndex]);

  /**
   * It takes in a pageIndex and dispatches a paginateEntityService action
   * @param {string | number} pageIndex
   */
  const onChange = (_pageIndex) => {
    setPageIndex(_pageIndex);
  };
  return (
    <ListServicesLayout>
      <div className="container max-w-screen-2xl px-4 mx-auto">
        <div className="flex justify-between">
          <Typography.Title level={2}>Available Services</Typography.Title>
          <Link to="/">
            <Button
              className="flex items-center text-danger"
              type="link"
              icon={<LeftOutlined />}
            >
              Search again
            </Button>
          </Link>
        </div>
        <div className="m-2">
          <Col className="mb-4" xs={24}>
            <Typography level={4}>
              {state.search.result.length} found services
            </Typography>
          </Col>
          <Row>
            <Col xs={24}>
              <CustomLoader loading={loading}>
                <Row style={{ columnGap: "1em", rowGap: "1em" }}>
                  {state.search.result.length === 0 ? (
                    <Col
                      xs={24}
                      className="d-flex w-full justify-content-center"
                    >
                      <Empty />
                    </Col>
                  ) : (
                    state.search.result.slice(page[0], page[1])?.map((item) => (
                      <Col xs={24} sm={12} md={7}>
                        <ServiceComponent service={item} />
                      </Col>
                    ))
                  )}
                  <Col className="d-flex justify-content-center mt-5" xs={24}>
                    <Pagination
                      onChange={onChange}
                      total={state.search.result.length}
                      // size="small"

                      pageSize={5}
                    />
                  </Col>
                </Row>
              </CustomLoader>
            </Col>
          </Row>
        </div>
      </div>
    </ListServicesLayout>
  );
};

export default SearchPage;
