import { EyeOutlined } from "@ant-design/icons";
import { Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentWrapper from "@components/ContentWrapper";
import { fetchDependantsThunk } from "@store/thunks/dependant.thunk";
import useCustomSelector from "@utils/custom_hooks/useCustomSelector";

const ListDependency = () => {
  const dependantState = useCustomSelector((state) => state.dependant);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDependantsThunk());
  }, [dispatch]);
  return (
    <ContentWrapper title="list of dependants" hasContainer>
      <Table
        rowKey={(record) => record?.user_entity_id}
        loading={dependantState.loading}
        dataSource={dependantState.state.list}
        columns={[
          { title: "First Name", key: "first_name", dataIndex: "first_name" },
          { title: "Last Name", key: "last_name", dataIndex: "last_name" },
          {
            title: "Relationship",
            key: "relationship",
            dataIndex: "relationship",
          },
          {
            title: "Email",
            key: "email",
            dataIndex: "email",
          },
          {
            title: "Gender",
            key: "gender",
            dataIndex: "gender",
            render: (value) => (
              <p> {value?.toLowerCase() === "m" ? "Male" : "Female"}</p>
            ),
          },
          {
            title: "Action",
            key: "action",
            render: (value) => (
              <div>
                <EyeOutlined onClick={() => {}} />
              </div>
            ),
          },
        ]}
      />
    </ContentWrapper>
  );
};

export default ListDependency;
