import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setFetchedOrganisationByCountry,
  setOrganisationEntities,
} from "../slices/entity.slice";
import BookingService from "../../services/booking.service";
import IntelService from "../../services/intel.service";

const intelService = new IntelService();
const bookingService = new BookingService();

export const fetchEntityByCountryThunk = createAsyncThunk(
  "entity/fetchOrganisationByCountry",
  async (country, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = intelService.getEntitiesByCountry(country);
      dispatch(setFetchedOrganisationByCountry(data.data));
      return fulfillWithValue(intelService.fulfilled(data?.data));
    } catch (error) {
      return rejectWithValue(intelService.rejected(error));
    }
  }
);

// KoldChain
export const koldChainFetchOrganisationEntitiesThunk = createAsyncThunk(
  "entity/fetchKoldChainOrganisation",
  async (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = bookingService.getOrganizations();
      dispatch(setOrganisationEntities(data.data));
      return fulfillWithValue(bookingService.fulfilled(data?.data));
    } catch (error) {
      return rejectWithValue(bookingService.rejected(error));
    }
  }
);
