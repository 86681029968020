import {
  FETCH_SCHEDULE_TIME_SLOTS_FAILUIRE,
  FETCH_SCHEDULE_TIME_SLOTS_LOADING,
  FETCH_SCHEDULE_TIME_SLOTS_SUCCESS,
  FETCH_SCHEDULE_WEEK_DAYS_FAILUIRE,
  FETCH_SCHEDULE_WEEK_DAYS_LOADING,
  FETCH_SCHEDULE_WEEK_DAYS_SUCCESS,
} from "../actionCreators/scheduleWeekdaysActionCreator";

const initialState = {
  weekdaysLoading: false,
  timeslotsLoading: false,
  weekDays: [],
  timeslots: [],
};

const serviceWeekdaysReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SCHEDULE_WEEK_DAYS_LOADING:
      return { ...state, weekdaysLoading: true };
    case FETCH_SCHEDULE_WEEK_DAYS_SUCCESS:
      return {
        ...state,
        weekdaysLoading: false,
        weekDays: payload,
      };
    case FETCH_SCHEDULE_WEEK_DAYS_FAILUIRE:
      return {
        ...state,
        weekdaysLoading: false,
      };
    case FETCH_SCHEDULE_TIME_SLOTS_LOADING:
      return { ...state, timeslotsLoading: true };
    case FETCH_SCHEDULE_TIME_SLOTS_SUCCESS:
      return {
        ...state,
        timeslotsLoading: false,
        timeslots: payload,
      };
    case FETCH_SCHEDULE_TIME_SLOTS_FAILUIRE:
      return {
        ...state,
        timeslotsLoading: false,
      };

    default:
      return state;
  }
};
export default serviceWeekdaysReducer;
