import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCheck,
  faListUl,
  faPlus,
  faCubes,
} from "@fortawesome/free-solid-svg-icons";
import pageRoutes from "@utils/page-routes";

const style = { fontSize: "1rem", backgroundColor: "transparent" };

function ClientSidebarMenu() {
  return (
    <Menu className="custom-menu-list">
      <Menu.Item key={"0"}>
        <div className="custom-menu-item">
          <span className="custom-menu-icon">
            <FontAwesomeIcon icon={faCubes} />
          </span>
          <Link style={{ fontSize: 18 }} to={pageRoutes.dashboard}>
            Dashboard
          </Link>
        </div>
      </Menu.Item>
      <Menu.SubMenu
        style={style}
        icon={<FontAwesomeIcon icon={faUserCheck} />}
        title="Bookings"
        key="1"
      >
        <Menu.Item
          style={{ backgroundColor: "transparent" }}
          key="1.2"
          icon={
            <span className="custom-menu-icon">
              <FontAwesomeIcon icon={faPlus} />
            </span>
          }
        >
          <Link to={pageRoutes.bookings_book_now}> Book now</Link>
        </Menu.Item>
        <Menu.Item
          style={{ backgroundColor: "transparent" }}
          key="1.3"
          icon={<FontAwesomeIcon icon={faListUl} />}
        >
          <Link to={pageRoutes.bookings_history}> History</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        style={style}
        icon={<FontAwesomeIcon icon={faUserCheck} />}
        title="Dependency"
        key="3"
      >
        <Menu.Item
          style={{ backgroundColor: "transparent" }}
          key="3.2"
          icon={<FontAwesomeIcon icon={faPlus} />}
        >
          <Link to={pageRoutes.dependency_create}>Add dependency</Link>
        </Menu.Item>
        <Menu.Item
          style={{ backgroundColor: "transparent" }}
          key="3.1"
          icon={<FontAwesomeIcon icon={faListUl} />}
        >
          <Link to={pageRoutes.dependency_list}> Dependencies</Link>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
}

export default ClientSidebarMenu;
