import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { APIClient } from "../../services/api";
import generateQueryParams from "../../utils/generateQueryParams";
import {
  archiveServiceUrl,
  featuredServicesUrl,
  getKoldChainUnApprovedServicesListUrl,
  getFeaturedServicesUrl,
  getEntityServicesUrl,
  serviceApprovalUrl,
  createEntityServiceUrl,
  getUnApprovedEntityServicesUrl,
  uploadBulkyServicesUrl,
} from "../../utils/network/endpoints";
import { getUserIpLocation } from "../../utils/persistent_data/local_storage";
import {
  addToFeaturedServices,
  archieveUnApprovedService,
  removeUnApprovedServiceById,
  setEntityServices,
  setKoldChainServiceData,
  updateSearchResult,
} from "../slices/service.slice";
import { setSessionExpire } from "../slices/app.slice";
import BookingService from "../../services/booking.service";
import ServiceClass from "../../services/services";
const api = new APIClient();

const bookingService = new BookingService();
const service = new ServiceClass();

export const fetchFeaturedServicesThunk = createAsyncThunk(
  "fetch_manager_featured_services",
  async (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    api.addAccessToken();
    const location = getUserIpLocation();
    return api
      .get(getFeaturedServicesUrl("Test", location?.country_code))
      .then(({ data }) => {
        dispatch(addToFeaturedServices(data.data));
        return fulfillWithValue(data);
      })
      .catch((error) => {
        console.warn(error);
        return rejectWithValue(error);
      });
  }
);

export const fetchEntityServicesThunk = createAsyncThunk(
  "fetch_entity_services_thunk",
  async (params, { dispatch, fulfillWithValue, rejectWithValue }) => {
    // const queryParams = generateQueryParams(params);
    // console.log("PARAMS", queryParams)
    try {
      const { data } = await bookingService.getClientBookingEntityServicesList(
        params
      );
      dispatch(updateSearchResult(data?.data));
    } catch (err) {
      return rejectWithValue({ success: false, error: err });
    }
    // return api
    //   .get(featuredServicesUrl.concat(`?${queryParams}`))
    //   .then(({ data }) => {
    //     dispatch(updateSearchResult(data.data));
    //     return fulfillWithValue(data);
    //   })
    //   .catch((error) => rejectWithValue(error));
  }
);

export const createEntityServicesThunk = createAsyncThunk(
  "create_entity_services",
  async (payload, { fulfillWithValue, rejectWithValue, dispatch }) => {
    api.addAccessToken();
    return api
      .post(createEntityServiceUrl, payload)
      .then(({ data }) => {
        message.success(
          "Lab Service successfully created! All services are subject to review and approval by Alkemy before public display"
        );
        return fulfillWithValue(data.data);
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);

export const fetchUnApprovedEntityServicesThunk = createAsyncThunk(
  "fetch_unapproved_entity_services",
  async (_, { fulfillWithValue, rejectWithValue, dispatch }) => {
    api.addAccessToken();
    return api
      .get(getUnApprovedEntityServicesUrl)
      .then(({ data }) => {
        dispatch(setEntityServices({ data: data?.data, type: "unapproved" }));
        return fulfillWithValue(data?.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else if (err.request) {
          message.error(err.message);
        }
        return rejectWithValue(err);
      });
  }
);

export const fetchApprovedEntityServicesThunk = createAsyncThunk(
  "fetch_approved_entity_services",
  async (_, { fulfillWithValue, rejectWithValue, dispatch }) => {
    api.addAccessToken();
    return api
      .get(getEntityServicesUrl)
      .then(({ data }) => {
        dispatch(setEntityServices({ data: data?.data }));
        return fulfillWithValue(data?.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else if (err.request) {
          message.error(err.message);
        }
        return rejectWithValue(err);
      });
  }
);

// This function should archieve a service whose id is passed
export const archieveAServiceThunk = createAsyncThunk(
  "achieve_single_service",
  async (serviceID, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const { data } = await service.achieveService();
      dispatch(archieveUnApprovedService(serviceID));
      message.success("Service successfully archieved");
      return fulfillWithValue(service.fulfilled(data));
    } catch (error) {
      return rejectWithValue(service.rejected(error));
    }
  }
);

export const fetchKoldChainApprovedServicesThunk = createAsyncThunk(
  "fetch_koldchain_approved_services",
  async (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await service.getApprovedServices();
      dispatch(setKoldChainServiceData({ data: data.data }));
      return fulfillWithValue(service.fulfilled(data.data));
    } catch (error) {
      return rejectWithValue(service.rejected(error));
    }
  }
);

export const fetchKoldChainUnApprovedServicesThunk = createAsyncThunk(
  "fetch_koldchain_approved_services",
  async (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await service.getUnApprovedServices();
      dispatch(
        setKoldChainServiceData({ data: data.data, type: "unapproved" })
      );
      return fulfillWithValue(service.fulfilled(data.data));
    } catch (error) {
      return rejectWithValue(service.rejected(error));
    }
  }
);

export const koldChainUploadBulkyServices = createAsyncThunk(
  "service/koldchain_upload_services",
  async ({ services, onSuccess }, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await service.uploadBulkyServices(services);
      message.success(data?.message || "Services Upload Successful");
      onSuccess();
      return fulfillWithValue(service.fulfilled({ data }));
    } catch (error) {
      return rejectWithValue(service.rejected(error));
    }
  }
);

export const approveServiceThunk = createAsyncThunk(
  "approve_service",
  async (serviceID, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await service.approveService(serviceID);
      dispatch(removeUnApprovedServiceById(serviceID));
      return fulfillWithValue(service.fulfilled(data));
    } catch (error) {
      return rejectWithValue(service.rejected(error));
    }
  }
);
