import getExtraReducers from "../extraReducersCreator";
import { HTTPStatusType } from "@utils/http_status_type";
import { createSlice } from "@reduxjs/toolkit";
import * as thunks from "../thunks/payment.thunk";

const initialState = {
  thunkRequestStatus: HTTPStatusType.IDLE,
  loading: false,
  commission: [],
};
const extraReducers = getExtraReducers(thunks);
const paymentSlice = createSlice({
  name: "paymentSlice",
  initialState,
  reducers: {
    addCommissionData: (state, { payload }) => {
      state.commission.push(payload);
    },
    setCommissions: (state, { payload }) => {
      state.commission = payload;
    },
  },
  extraReducers: extraReducers,
});

export const getPaymentState = (state) => state.payment;

export const { addCommissionData, setCommissions } = paymentSlice.actions;

export default paymentSlice.reducer;
