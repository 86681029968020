import { Skeleton } from "antd";
import React from "react";

const CustomListLoader = ({ loading = false, text, subText, children }) => {
  return loading ? (
    <div className="relative">
      <ul>
        {Array(8)
          .fill("")
          .map((_, index) => (
            <li key={index}>
              <Skeleton />
            </li>
          ))}
      </ul>
      <h3 className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-3xl font-bold text-center">
        <span className="text-primary">{text}</span>
        <span className="animate-ping">....</span>
        <br />
        {subText && <span className="font-normal text-lg">{subText}</span>}
      </h3>
    </div>
  ) : (
    <>{children}</>
  );
};

export default CustomListLoader;
