import { Col, Form, Input, Row, Button, DatePicker, Select } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import ContentWrapper from "@components/ContentWrapper";
import CountryCodeSelector from "@components/CountryCodeSelector";
import { createDependantThunk } from "@store/thunks/dependant.thunk";
import useCustomSelector from "@utils/custom_hooks/useCustomSelector";
import { UserRole } from "@utils/roles";
import UserAccessibilityView from "@components/decorators/UserAccessibilityView";

const RELATIONSHIP = [
  { value: "", label: "Select relationship", disabled: true },
  {
    value: "Family",
    label: "Family",
  },
  {
    value: "Friend",
    label: "Friend",
  },
  {
    value: "Work colleague",
    label: "Work colleague",
  },
  {
    value: "Others",
    label: "Others",
  },
];

const DependencyAdd = () => {
  const initialValues = {
    relationship: "",
    gender: "Male",
  };
  const defaultValues = {
    email: "",
    phone_number: "",
    country_code: "",
  };
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const onFinish = (values) => {
    const payload = Object.keys(values)
      .filter((key) => values[key] !== undefined)
      .reduce((prev, current) => ({ ...prev, [current]: values[current] }), {});
    dispatch(
      createDependantThunk({
        ...payload,
        date_of_birth: moment(values.date_of_birth).format("DD/MM/YYYY"),
      })
    );
  };

  const dependantState = useCustomSelector((state) => state.dependant);
  return (
    <ContentWrapper hasContainer title="Add a dependent">
      <div className="my-4">
        <Form
          onFinish={onFinish}
          form={form}
          initialValues={initialValues}
          defaultValue={defaultValues}
          size="large"
          layout="vertical"
        >
          <Row gutter={16}>
            <Col xs={24} md={12}>
              <Form.Item
                label="First Name."
                name="first_name"
                rules={[{ required: true, message: "First name is required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Last Name."
                rules={[{ required: true, message: "Last name is required" }]}
                name="last_name"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ type: "email", message: "Enter a valid email" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label="Phone Number" name="phone_number">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item label="Country Code" name="country_code">
                <CountryCodeSelector />
              </Form.Item>
            </Col>

            <Col xs={24} md={8}>
              <Form.Item
                label="Relationship"
                rules={[
                  { required: true, message: "Relationship is required" },
                ]}
                name="relationship"
              >
                <Select
                  placeholder="Select relationship"
                  options={RELATIONSHIP}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Date Of Birth"
                rules={[
                  { required: true, message: "Date of birth is required" },
                ]}
                name="date_of_birth"
              >
                <DatePicker className="w-100" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label="Gender"
                rules={[{ required: true, message: "Gender is required" }]}
                name="gender"
              >
                <Select
                  options={[
                    { label: "Select Gender", value: "" },
                    { label: "Male", value: "Male" },
                    { label: "Female", value: "Female" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <Button
              loading={dependantState.loading}
              htmlType="submit"
              className="btn btn-primary"
            >
              Create Dependant
            </Button>
          </div>
        </Form>
      </div>
    </ContentWrapper>
  );
};

export default UserAccessibilityView({
  component: DependencyAdd,
  allowedUsers: [UserRole.PATIENT],
});
