import { Button, Typography } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import "./index.scss";

const ServiceComponent = ({ service }) => {
  const history = useHistory();
  return (
    <div
      style={{
        border: "1px solid black",
        borderRadius: "10px",
      }}
    >
      <div className="flex flex-col">
        <div className="p-3">
          <Typography.Title style={{ color: "#ccccccc" }} level={5}>
            {service.service_name ?? service?.service?.details?.name}
          </Typography.Title>
          <Link
            id="link"
            to={`/services/organization/${service.bookable_entity}`}
          >
            {service?.bookable_entity_name}
          </Link>

          <Typography.Title level={4}>
            {service?.price_currency} {service?.price}
          </Typography.Title>
        </div>
        <Button
          onClick={() =>
            history.push(`/dashboard/booking/service/${service?.id}`)
          }
          type="primary"
          style={{
            minHeight: 50,
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            width: "100%",
          }}
          className="mt-auto bg-yellow-500 text-white"
        >
          Book a test
        </Button>
      </div>
    </div>
  );
};

export default ServiceComponent;
