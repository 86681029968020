import { APIClient } from "./api";

export default class IntelService extends APIClient {
  constructor() {
    super();
    this.addAccessToken();
  }
  getExternalEntityServices = async (entityId) => {
    return await this.get(
      `/booking/manager/koldchain/organization/services/list?reg_number=${entityId}`
    );
  };

  getExternalEntityDetailInfo = async (entityId) => {
    return await this.get(
      `/intel/fetch/lab/by/registration/number/?reg_number=${entityId}`
    );
  };

  getExternalEntitiesByCountry = (countryName) =>
    this.get(`/intel/fetch/entities?country=${countryName || ""}`);
}
