import React, { useMemo } from 'react'
import { Redirect } from 'react-router-dom';
import { getUserProfile } from "../../utils/persistent_data/local_storage";
function UserAccessibilityView({allowedUsers=[], component:Component}) {
  const View = () => {
    const group = getUserProfile()?.group;
  
    const exists = useMemo(() => {
      return  allowedUsers.length > 0 ?  group !== null ? allowedUsers.includes(group.toLowerCase()) : false : true
    }, [group])
  
  
    return exists ? <Component /> :<Redirect to={'/403_no_permission'} />
  }
  return View;
}

export default UserAccessibilityView
