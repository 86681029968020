import React, { useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DashboardLayout from "@components/layouts/DashboardLayout";
import ClientSidebarMenu from "@components/layouts/components/sidebars/Client.sidebar";
import DoctorSidebarMenu from "@components/layouts/components/sidebars/Doctor.sidebar";
import KoldChainAdminSidebarMenu from "@components/layouts/components/sidebars/KoldChainAdmin.sidebar.";
import LabAdminSidebarMenu from "@components/layouts/components/sidebars/LabAdmin.sidebar";
import pageRoutes from "@utils/page-routes";
import { getUserProfile } from "@utils/persistent_data/local_storage";
import { UserRole } from "@utils/roles";
import BookingHistory from "../Bookings/BookingHistory";
import BookNowForm from "../Bookings/BookNowForm";
import BookService from "../Bookings/BookService";
import ListOfBookings from "../Bookings/ListOfBookings";
import AttendedBookings from "../Bookings/ListOfBookings/AttendedBookings";
import UnAttendedBookings from "../Bookings/ListOfBookings/UnAttendedBookings";
import Dashboard from "../Dashboard";
import DependencyAdd2 from "../Dependency";
import ListDependency from "../Dependency/ListDependencies";
import ExternalEntities from "../externalEntities";
import Referral from "../Referral";
import ReferralBooking from "../Referral/ReferralBooking";
import CreateSchedule from "../Schedule";
import ListSchedules from "../Schedule/ListSchedules";
import CreateService from "../Services/CreateService";
import KoldChainListApprovedServices from "../Services/koldchain/services/KoldChainListApprovedServices";
import KoldChainListUnapprovedServices from "../Services/koldchain/services/KoldChainListUnapprovedServices";
import ListApprovedServices from "../Services/ListApprovedServices";
import ListUnapprovedServices from "../Services/ListUnApprovedServices";
import UploadServices from "../Services/UploadServices";
import ServicesTask from "../Task/ServicesTask";
import ListExternalEntityServices from "../externalEntities/ListExternalEntityServices";
import ListExternalEntityBookings from "../externalEntities/ListExternalEntityBookings";
import CommissionRate from "../commission/CommissionRate";
import LabNotifications from "@pages/settings/LabNotifications";
import BookingPayment from "@pages/payment/BookingPayment";
// import ReferralBonuses from "../Referral/Bonuses";
function MainDashboard() {
  const group = getUserProfile()?.group.toLowerCase();
  const getSidebarComponent = useMemo(() => {
    let sideMenu = <></>;
    if (group === UserRole.DOCTOR) {
      sideMenu = <DoctorSidebarMenu />;
    } else if (group === UserRole.PATIENT) {
      sideMenu = <ClientSidebarMenu />;
    } else if (group === UserRole.LAB_ADMIN) {
      sideMenu = <LabAdminSidebarMenu />;
    } else if (group === UserRole.KOLDCHAIN_ADMIN) {
      sideMenu = <KoldChainAdminSidebarMenu />;
    }
    return sideMenu;
  }, [group]);

  return (
    <DashboardLayout sideMenu={getSidebarComponent}>
      <Switch>
        {/* Payment */}
        <Route path={pageRoutes.payment} component={BookingPayment} />
        {/* Settings */}
        <Route
          path={pageRoutes.lab_notifications}
          component={LabNotifications}
        />
        <Route
          path={pageRoutes.lab_notifications}
          component={LabNotifications}
        />
        {/* Commission */}
        <Route path={pageRoutes.commission_rate} component={CommissionRate} />

        {/* Tasks */}
        <Route path={pageRoutes.task_services} component={ServicesTask} />
        {/* Entity Services */}
        <Route
          path={pageRoutes.entity_services_upload}
          component={UploadServices}
        />
        <Route
          path={pageRoutes.entity_services_unapproved}
          component={KoldChainListUnapprovedServices}
        />
        <Route
          path={pageRoutes.entity_services}
          component={KoldChainListApprovedServices}
        />
        {/* External Entities */}
        <Route
          path={pageRoutes.external_entity_list_bookings}
          component={ListExternalEntityBookings}
        />
        <Route
          path={pageRoutes.external_entity_list_services}
          component={ListExternalEntityServices}
        />
        <Route path={pageRoutes.external_entity} component={ExternalEntities} />

        {/* SCHEDULE */}
        <Route path={pageRoutes.schedule_create} component={CreateSchedule} />
        <Route path={pageRoutes.schedule_list} component={ListSchedules} />

        {/* DEPENDENCY */}
        <Route path={pageRoutes.dependency_create} component={DependencyAdd2} />
        <Route path={pageRoutes.dependency_list} component={ListDependency} />

        {/* Referral */}
        <Route path={pageRoutes.referral} component={Referral} />
        <Route path={pageRoutes.referral_booking} component={ReferralBooking} />
        {/* <Route path={pageRoutes.referral_bonus} component={ReferralBonuses} /> */}
        <Route path={pageRoutes.referral_history} component={BookingHistory} />

        {/* Service */}
        <Route path={pageRoutes.service_create} component={CreateService} />
        <Route
          path={pageRoutes.service_unapproved_list}
          component={ListUnapprovedServices}
        />
        <Route
          path={pageRoutes.service_approved_list}
          component={ListApprovedServices}
        />

        {/* Booked Services */}
        <Route
          path={pageRoutes.booked_services_unattended}
          component={UnAttendedBookings}
        />
        <Route
          path={pageRoutes.booked_services_attended}
          component={AttendedBookings}
        />
        <Route path={pageRoutes.booked_services} component={ListOfBookings} />

        {/* Booking */}
        <Route path={pageRoutes.bookings_history} component={BookingHistory} />
        <Route path={pageRoutes.booking_a_service} component={BookNowForm} />
        <Route path={pageRoutes.bookings_book_now} component={BookService} />
        <Route exact path={pageRoutes.dashboard} component={Dashboard} />
        <Route path={"*"} component={PageNotFoundRedirect} />
      </Switch>
    </DashboardLayout>
  );
}

const PageNotFoundRedirect = () => {
  return <Redirect to={{ pathname: "/404_page_not_found" }} />;
};

export default MainDashboard;
