export const weekByName = (day) => {
    if (day.toLowerCase() === "monday") {
      return 1;
    } else if (day.toLowerCase() === "tuesday") {
      return 2;
    } else if (day.toLowerCase() === "wednesday") {
      return 3;
    } else if (day.toLowerCase() === "thursday") {
      return 4;
    } else if (day.toLowerCase() === "friday") {
      return 5;
    } else if (day.toLowerCase() === "saturday") {
      return 6;
    } else if (day.toLowerCase() === "sunday") {
      return 7;
    } else {
      return null;
    }
  };


  export const weekByNumber = (day) => {
    if (day === 1) {
      return "Monday";
    } else if (day === 2) {
      return "Tuesday";
    } else if (day === 3) {
      return "Wednesday";
    } else if (day === 4) {
      return "Thursday";
    } else if (day === 5) {
      return "Friday";
    } else if (day === 6) {
      return "Saturday";
    } else if (day === 7) {
      return "Sunday";
    } else {
      return null;
    }
  };