import React, { useCallback, useEffect, useState } from "react";
import ContentWrapper from "@components/ContentWrapper";
import { useParams } from "react-router-dom";
import { Popover, Table, Tag, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import BookingService from "@services/booking.service";

const book = new BookingService();

const ListExternalEntityBookings = () => {
  const params = useParams();
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchEntityData = useCallback(async () => {
    if (!params?.entityId) return;
    setLoading(true);
    try {
      // service.onPENDING();
      const servicesResponse = await book.getExternalEntityBookings(
        params?.entityId
      );

      setBookings(servicesResponse.data?.data);
      // setEntityInfo(entityInfoResponse.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [params?.entityId]);

  // UseEffects

  useEffect(() => {
    fetchEntityData();
  }, [fetchEntityData]);

  // Columns
  const columns = [
    {
      id: "code",
      dataIndex: "code",
      title: "Code",
    },
    {
      id: "travel_info",
      dataIndex: "travel_info",
      title: "Traveling Details",
      render: (value, record) => {
        const travelInfo = record?.travel_info;
        return !travelInfo ? (
          <Tooltip title="No field data">
            <div className="text-slate-200 hover:text-slate-500">
              <FontAwesomeIcon size="lg" icon={faQuestionCircle} />
            </div>
          </Tooltip>
        ) : (
          <Popover
            title="Travel Information"
            content={
              <ul className="grid grid-cols-1 gap-2">
                {Object.keys(travelInfo).map((key, index) => (
                  <li
                    key={index}
                    className="grid grid-cols-2 items-center gap-4"
                  >
                    <h3 className="font-bold uppercase">
                      {key?.replaceAll("_", " ")}
                    </h3>{" "}
                    <p>{travelInfo[key] || "Unspecified"}</p>
                  </li>
                ))}
              </ul>
            }
            className="text-blue-400 font-bold hover:cursor-pointer"
          >
            <FontAwesomeIcon icon={faEye} /> traveling info
          </Popover>
        );
      },
    },
    {
      id: "booked_by",
      dataIndex: "booked_by",
      title: "Traveler Details",
      render: (value, record) => {
        const traveller = record?.booked_by;
        return !traveller ? (
          <Tooltip title="No field data">
            <div className="text-slate-200 hover:text-slate-500">
              <FontAwesomeIcon size="lg" icon={faQuestionCircle} />
            </div>
          </Tooltip>
        ) : (
          <Popover
            title="Travel Information"
            content={
              <ul className="grid grid-cols-1 gap-2">
                {Object.keys(traveller).map((key, index) => (
                  <li
                    key={index}
                    className="grid grid-cols-2 items-center gap-4"
                  >
                    <h3 className="font-bold uppercase">
                      {key?.replaceAll("_", " ")}
                    </h3>{" "}
                    <p>{traveller[key] || "Unspecified"}</p>
                  </li>
                ))}
              </ul>
            }
            className="text-blue-400 font-bold hover:cursor-pointer"
          >
            <FontAwesomeIcon icon={faEye} /> Traveler
          </Popover>
        );
      },
    },

    {
      id: "payment_required",
      dataIndex: "payment_required",
      title: "Payment Required",
      render: (value, _) => (!value ? "No" : "Yes"),
    },
    {
      id: "schedule_date",
      dataIndex: "schedule_date",
      title: "Schedule",
    },
    {
      id: "payed",
      dataIndex: "payed",
      title: "Payed",
      render: (value, _) => (
        <Tag color={value ? "success" : "red"}>
          {value ? "Paid" : "Not paid"}
        </Tag>
      ),
    },
  ];

  return (
    <ContentWrapper loading={loading} title={"List External Entity Bookings"}>
      {/* {entityInfo && (
        <Card className="max-w-[800px] shadow-lg bg-yellow-100 rounded-2xl">
          <ul className="grid grid-cols-2 gap-4">
            <li className="flex items-center">
              <span className="px-4 py-2 rounded-lg bg-yellow-300 mr-4">
                Name:
              </span>
              <span className="font-bold flex-1">{entityInfo?.name}</span>
            </li>
            <li className="flex items-center">
              <span className="px-4 py-2 rounded-lg bg-yellow-300 mr-4">
                Phone Number:
              </span>
              <span className="font-bold flex-1">
                {entityInfo?.phoneNumber}
              </span>
            </li>
            <li className="flex items-center">
              <span className="px-4 py-2 rounded-lg bg-yellow-300 mr-4">
                Contact Email:
              </span>
              <span className="font-bold flex-1">{entityInfo?.email}</span>
            </li>
            <li className="flex items-center">
              <span className="px-4 py-2 rounded-lg bg-yellow-300 mr-4">
                Country:
              </span>
              <span className="font-bold flex-1">{entityInfo?.country}</span>
            </li>
            <li className="flex items-center">
              <span className="px-4 py-2 rounded-lg bg-yellow-300 mr-4">
                Street Address:
              </span>
              <span className="font-bold flex-1">
                {entityInfo?.streetAddress1}
              </span>
            </li>
            <li className="flex items-center">
              <span className="px-4 py-2 rounded-lg bg-yellow-300 mr-4">
                Street Address 2:
              </span>
              <span className="font-bold flex-1">
                {entityInfo?.streetAddress2}
              </span>
            </li>
          </ul>
        </Card>
      )}
      <br /> */}
      <div>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={bookings}
        />
      </div>
    </ContentWrapper>
  );
};

export default ListExternalEntityBookings;
