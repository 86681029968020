import { faMoneyBill, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Input, Modal } from "antd";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import BookingCertificate from "@components/certificates";
import PublicImages from "../../../../utils/images";
import InvoiceSummary from "@components/InvoiceSummary";
import useAxiosFetch from "../../../../utils/custom_hooks/useAxiosFetch";
import PayStackAPI from "../../../../services/paystack";

const ActionOptions = {
  DUPLICATE: 'duplicate'
}

const BookingSuccessfullyPage = () => {
  const [action, setAction] = useState()
  const [openModal, setOpenModal] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const result = useSelector(
    (state) => state.bookNowService?.bookingResult || undefined
  );
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "certificate",
    pageStyle: () => `
    
    `,
  });

  const pay = useAxiosFetch({
    fetcher: PayStackAPI.initializeTransaction,
    onSuccess: (res) => {
      const _data = res?.data?.data;
      window.open(_data?.authorization_url, "_blank");
    },
  });
  const paymentOnFinish = (values) => {
    const [currency, amount] = invoice?.payable_amount?.split(" ");
    pay.onFetch({
      currency: currency,
      email: invoice?.generated_for?.email,
      reference: invoice.uuid,
      amount: parseFloat(amount) * 100,
      mobile_money: {
        phone: values?.phone,
        provider: "MTN",
      },
      callback_url: window.location.host.concat(
        `/payment/confirmation/${invoice?.uuid}`
      ),
    });
  };

  return Object.keys(result)?.length > 0 ? (
    <div className="grid grid-cols-1 lg:grid-cols-2 items-start gap-4">
      {/* {result?.code && (
        <InvoiceSummary
          bookingCode={result?.code || ""}
          setInvoice={(values) => setInvoice(values)}
        />
      )} */}
      <BookingCertificate result={result} printRef={componentRef} />
      <div className="flex gap-2 ml-auto mt-4">
        {invoice && (
          <Button
            icon={<FontAwesomeIcon className="px-1" icon={faMoneyBill} />}
            className="btn btn-primary"
            shape="round"
            type="primary"
            onClick={() => {
              if (!invoice) return;
              setOpenModal(true);
            }}
          >
            Make Payment
          </Button>
        )}
        <Button
          icon={<FontAwesomeIcon className="px-1" icon={faPrint} />}
          className="btn btn-secondary"
          shape="round"
          type="primary"
          onClick={handlePrint}
        >
          Print Certificate
        </Button>
      </div>
      <Modal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={null}
      >
        <div className="grid grid-cols-2 gap-4">
          <div>
            <img src={PublicImages.PaymentMtnBannerPerson} />
          </div>
          <div>
            <h3 className="font-bold text-lg">Pay with MTN MOMO</h3>
            <img width={80} src={PublicImages.PaymentMtnBanner} />
            <br />
            <h3 className="font-bold mb-2s">Payment Details</h3>
            <Form onFinish={paymentOnFinish} className="form">
              <Form.Item name={"name"}>
                <Input placeholder="Enter your name" />
              </Form.Item>
              <Form.Item name={"phone"}>
                <Input placeholder="Enter your phone number" />
              </Form.Item>
              <p className="text-sm text-gray-500">
                You will receive a prompt on your device to complete payment
              </p>
              <center className="mt-4">
                <Button
                  loading={pay.loading}
                  disabled={pay.loading}
                  htmlType="submit"
                  className="btn btn-primary mx-auto"
                >
                  Pay
                </Button>
              </center>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  ) : (
    <></>
  );
};

export default BookingSuccessfullyPage;
