import labImage from "@assets/images/lab.jpg";
import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const LandingPageLayout = ({ children }) => {
  return (
    <div style={{ minHeight: "100vh" }} className="flex flex-col">
      <section className="">
        <Header />
        <div className="mt-4 px-5 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>{children}</div>
          <div className="d-none d-md-flex justify-content-end">
            <div className="h-full  relative">
              <img
                className="absolute h-full"
                style={{ borderRadius: 20, objectFit: "cover" }}
                // width="100%"
                src={labImage}
                alt="lab_image"
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default LandingPageLayout;
