import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../../assets/img/brand/alkemy.png";
import useMediaquery from "../../../../utils/custom_hooks/useMediaquery.hook";

const Header = ({ ...props }) => {
  const width = useMediaquery();

  return (
    <div
      className="py-4 w-full flex justify-between items-center px-6"
      {...props}
    >
      <img
        className=""
        width={width > 600 ? 150 : 100}
        src={logo}
        alt="Logo"
      />
      <Link
        
        to="/dashboard"
      >
        <Button size={width < 600 && "small"} type="primary" shape="round">
          Dashboard
        </Button>
      </Link>
    </div>
  );
};

export default Header;
