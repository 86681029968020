import { Card, Empty } from "antd";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import CustomLoader from "@components/CustomLoader";
import ServiceComponent from "@components/ServiceComponent";

import ServiceSearchEngine from "@components/ServiceSearchEngine";
import { fetchFeaturedServicesThunk } from "@store/thunks/service.thunk";
import { getUserProfile } from "@utils/persistent_data/local_storage";
import { UserRole } from "@utils/roles";
import { clearSearchList } from "@store/slices/service.slice";
import useCustomSelector from "@utils/custom_hooks/useCustomSelector";
import ContentWrapper from "@components/ContentWrapper";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

const BookNow = ({ title }) => {
  const dispatch = useDispatch();
  const serviceState = useCustomSelector((state) => state.service);

  const initialFetchFeaturedService = useCallback(() => {
    if (
      getUserProfile()?.group.toLowerCase() !== UserRole.PATIENT.toLowerCase()
    ) {
      dispatch(fetchFeaturedServicesThunk());
    }
  }, [dispatch]);

  const isEmpty =
    !serviceState.state.search.result?.length ||
    serviceState.state.search.result?.length <= 0;

  useEffect(() => {
    initialFetchFeaturedService();
    return () => {
      dispatch(clearSearchList());
    };
  }, [dispatch, initialFetchFeaturedService]);

  return (
    <ContentWrapper title={title} hasContainer>
      <section
        className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4 ${
          !isEmpty ? "items-start" : ""
        }`}
      >
        <Card
          className=" col-span-full lg:col-span-2 rounded-2xl relative lg:sticky top-0"
          // title="Filter bar "
        >
          <h2 className="text-lg flex items-center space-x-4">
            <FontAwesomeIcon
              className="bg-yellow-400 p-2 rounded-lg"
              icon={faFilter}
            />{" "}
            <span>Filter</span>
          </h2>
          <ServiceSearchEngine />
        </Card>
        <Card className="col-span-full lg:col-span-4 flex-1">
          <h2 className="text-2xl font-bold">Featured Services</h2>
          <CustomLoader loading={serviceState.loading}>
            {isEmpty ? (
              <div className="h-full flex justify-center items-center">
                <CustomLoader loading={serviceState.loading}>
                  <Empty />
                </CustomLoader>
              </div>
            ) : (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 items-stretch">
                {serviceState.state.search.result?.map((item, index) => (
                  <div key={index.toString()}>
                    <ServiceComponent service={item} />
                  </div>
                ))}
              </div>
            )}
          </CustomLoader>
        </Card>
      </section>
    </ContentWrapper>
  );
};

BookNow.propTypes = {
  title: PropTypes.string,
};

export default BookNow;
