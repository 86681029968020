import { Button, Modal, Typography } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { approveServiceThunk } from "@store/thunks/service.thunk";

const KoldchainApproveModal = ({ service, open, onClose }) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const onApprove = async () => {
    setLoading(true);
    dispatch(approveServiceThunk(service?.id))
      .unwrap()
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <Typography.Title className="text-center" level={5}>
        {service?.service?.details?.name}
      </Typography.Title>
      <div className="flex flex-col gap-3">
        <ul className="grid grid-cols-3 gap-4 mb-4">
          <li>
            <Typography>
              <span className="font-bold">Entity Name</span>:
            </Typography>

            {service?.bookable_entity_name}
          </li>
          <li>
            <Typography>
              <span className="font-bold">Price</span>:
            </Typography>
            {service?.price_currency} {service?.price}
          </li>

          <li>
            <Typography>
              <span className="font-bold">Country</span>:
            </Typography>

            {service?.bookable_entity_country}
          </li>
          <li>
            <Typography>
              <span className="font-bold">Payment Required</span>:
            </Typography>

            {service?.payment_required ? "Yes" : "No"}
          </li>
          <li>
            <Typography>
              <span className="font-bold">Lonic code</span>:
            </Typography>

            {service?.service?.details?.lonic_code}
          </li>
        </ul>

        <div className="flex justify-center">
          <Button
            loading={loading}
            onClick={onApprove}
            shape="round"
            type="primary"
            className="btn-primary"
          >
            Approve Service
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default KoldchainApproveModal;
