import { useEffect, useState } from "react";
import { getUserProfile } from "../persistent_data/local_storage";

const useUserProfile = () => {
  const [profile, setProfile] = useState();
  useEffect(() => {
    setProfile(getUserProfile());
  }, []);
  return profile;
};

export default useUserProfile;
