import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faListUl,
  faPlus,
  faStopwatch,
  faAddressBook,
  faCubes,
} from "@fortawesome/free-solid-svg-icons";
import pageRoutes from "@utils/page-routes";
const style = { fontSize: "1rem", backgroundColor: "transparent" };
function LabAdminSidebarMenu() {
  return (
    <Menu className="custom-menu-list" style={{ backgroundColor: "#f4f4f4" }}>
      <Menu.Item style={style} key="0">
        <div className="custom-menu-item">
          <span className="custom-menu-icon">
            <FontAwesomeIcon icon={faCubes} />
          </span>
          <Link style={{ fontSize: 18 }} to={pageRoutes.dashboard}>
            Dashboard
          </Link>
        </div>
      </Menu.Item>

      <Menu.SubMenu
        style={{ backgroundColor: "transparent" }}
        key="1.0"
        title="Bookings"
        icon={<FontAwesomeIcon icon={faAddressBook} />}
      >
        <Menu.Item key="1.1" icon={<FontAwesomeIcon icon={faListUl} />}>
          <Link to={pageRoutes.booked_services}> All</Link>
        </Menu.Item>
        <Menu.Item key="1.2" icon={<FontAwesomeIcon icon={faListUl} />}>
          <Link to={pageRoutes.booked_services_attended}> Attended</Link>
        </Menu.Item>
        <Menu.Item key="1.3" icon={<FontAwesomeIcon icon={faListUl} />}>
          <Link to={pageRoutes.booked_services_unattended}> UnAttended</Link>
        </Menu.Item>
      </Menu.SubMenu>

      <Menu.SubMenu
        style={style}
        icon={<FontAwesomeIcon icon={faBriefcase} />}
        title="Services"
        key="2"
      >
        <Menu.Item
          // hidden={APP_ROLES[group].banned?.includes(
          //   OrganizationPermissions.CREATE_ENTITY
          // )}
          style={{ backgroundColor: "transparent" }}
          key="2.0"
          icon={<FontAwesomeIcon icon={faPlus} />}
        >
          <Link to={pageRoutes.service_create}> Add service</Link>
        </Menu.Item>
        <Menu.Item
          style={{ backgroundColor: "transparent" }}
          key="2.1"
          icon={<FontAwesomeIcon icon={faListUl} />}
        >
          <Link to={pageRoutes.service_approved_list}>Approved </Link>
        </Menu.Item>
        <Menu.Item
          style={{ backgroundColor: "transparent" }}
          key="2.2"
          icon={<FontAwesomeIcon icon={faListUl} />}
        >
          <Link to={pageRoutes.service_unapproved_list}> Unapproved </Link>
        </Menu.Item>
      </Menu.SubMenu>

      <Menu.SubMenu
        style={style}
        icon={<FontAwesomeIcon icon={faStopwatch} />}
        title="Schedules"
        key="3"
      >
        <Menu.Item
          // hidden={APP_ROLES[group].banned?.includes(
          //   OrganizationPermissions.CREATE_SCHEDULE
          // )}
          style={{ backgroundColor: "transparent" }}
          key="3.0"
          icon={<FontAwesomeIcon icon={faPlus} />}
        >
          <Link to={pageRoutes.schedule_create}> Add schedule</Link>
        </Menu.Item>
        <Menu.Item
          style={{ backgroundColor: "transparent" }}
          key="3.1"
          icon={<FontAwesomeIcon icon={faListUl} />}
        >
          <Link to={pageRoutes.schedule_list}> Schedules</Link>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
}

export default LabAdminSidebarMenu;
