import React from "react";

const Container = ({ children, extraClassName = "" }) => {
  return (
    <main className={`container px-4 mx-auto ${extraClassName}`}>
      {children}
    </main>
  );
};

export default Container;
