import { createSlice } from "@reduxjs/toolkit";
import { HTTPStatusType } from "../../utils/http_status_type";
import getExtraReducers from "../extraReducersCreator";
import * as thunks from "../thunks/schedule.thunk";

const initialState = {
  thunkRequestStatus: HTTPStatusType.IDLE,
  loading: false,
  list: [],
  entities: {
    weekDays: [],
    timeSlots: [],
  },
};

const extraReducers = getExtraReducers(thunks);

const scheduleSlice = createSlice({
  name: "scheduleSlice",
  initialState,
  reducers: {
    addAllSchedules: (state, { payload }) => {
      state.list = payload;
    },
    addEntitiesData: (state, { payload }) => {
      const { data, type = types.WEEK_DAYS } = payload;
      if (type === types.WEEK_DAYS) state.entities.weekDays = data;
      if (type === types.TIME_SLOTS) state.entities.timeSlots = data;
    },
  },
  extraReducers: extraReducers,
});

export const types = {
  WEEK_DAYS: "weekdays",
  TIME_SLOTS: "timeslots",
};

export const { addAllSchedules, addEntitiesData } = scheduleSlice.actions;
export default scheduleSlice.reducer;
