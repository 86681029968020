import { Col, Row, Typography } from "antd";
import React from "react";
import panabios from "../../../../assets/images/panabios.svg";
import koldchain from "../../../../assets/images/koldchain.svg";
import useMediaquery from "../../../../utils/custom_hooks/useMediaquery.hook";

const Footer = ({ ...props }) => {
  const width = useMediaquery();
  return (
    <Row hidden={width < 600} className="my-5 mt-auto" {...props}>
      <Col
        style={{ columnGap: "1em" }}
        className="flex justify-center px-3 mt-3 "
        xs={24}
        lg={12}
      >
        <Typography
          style={{ fontSize: width < 600 && ".8rem" }}
          className="font-weight-bold "
        >
          A service of
        </Typography>
        <img width={width < 600 && "40%"} src={koldchain} alt="koldchain" />
        <Typography
          style={{ fontSize: width < 600 && ".8rem" }}
          className="font-weight-bold"
        >
          A member of the
        </Typography>
        <img width={width < 600 && "70px"} src={panabios} alt="koldchain" />
        <Typography
          style={{ fontSize: width < 600 && ".8rem" }}
          className="font-weight-bold"
        >
          Trusted Apps Marketplace
        </Typography>
      </Col>
    </Row>
  );
};

export default Footer;
