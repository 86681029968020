import { PrinterOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";
import React, { useRef } from "react";
import BookingCertificate from "../certificates";
import { useReactToPrint } from "react-to-print";

const BookingCertificateModal = ({ open, onClose, booking }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "certificate",
    pageStyle: () => `
    
    `,
  });
  return (
    <Modal
      onCancel={onClose}
      open={open}
      footer={[
        <Button
          onClick={handlePrint}
          icon={<PrinterOutlined style={{ fontSize: "1.1rem" }} />}
          type="primary"
        >
          Print Certificate
        </Button>,
      ]}
    >
      <Typography.Title level={4}>Booking Certificate</Typography.Title>
      <BookingCertificate printRef={componentRef} result={booking} />
    </Modal>
  );
};

export default BookingCertificateModal;
