import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTime } from "@utils/date_time_format";
import {
  Typography,
  Button,
  message,
  Switch,
  InputNumber,
  Collapse,
} from "antd";
import { useHistory } from "react-router";
import ContentWrapper from "@components/ContentWrapper";
import useCustomSelector from "@utils/custom_hooks/useCustomSelector";
import {
  createScheduleThunk,
  fetchScheduleTimeSlotsThunk,
  fetchScheduleWeekdaysThunk,
} from "@store/thunks/schedule.thunk";
const MAX_BOOKEE = 200;
const CreateSchedule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [schedule, setSchedule] = useState({});
  const [days, setDays] = useState([]);
  const [slots, setSlots] = useState([]);
  const { loading, state } = useCustomSelector((state) => state.schedule);

  const handleOnChange = (keys) => {
    setDays(keys);
  };

  const handleSubmit = () => {
    if (Object.keys(schedule).length === 0) {
      message.error("You must select at least one day");
      return;
    }
    const data = {
      schedule: Object.values(schedule),
    };
    dispatch(createScheduleThunk({ payload: data, history }));
  };

  useEffect(() => {
    dispatch(fetchScheduleWeekdaysThunk());
    dispatch(fetchScheduleTimeSlotsThunk());
  }, [dispatch]);

  return (
    <ContentWrapper title="Create schedule" hasContainer loading={loading}>
      <div className="border p-8 rounded-xl grid grid-cols-1 lg:grid-cols-2">
        <div>
          <Collapse className="border-0 bg-none" onChange={handleOnChange}>
            {state.entities.weekDays.map((day, index) => {
              const bgColor = [
                "#f6e3fe",
                "#E7F2FE",
                "#FEE9E1",
                "#FBD8E2",
                "#DFF9E4",
                "#FFF5D1",
                "#FFFFFF",
              ];
              return (
                <Collapse.Panel
                  className="border-none bg-none"
                  collapsible="header"
                  style={{
                    border: 0,
                    padding: 0,
                    margin: 0,
                    height: "auto",
                    background: "none",
                  }}
                  showArrow={false}
                  header={
                    <div
                      className="h-full p-4 rounded-t-xl flex flex-row space-x-4"
                      style={{ backgroundColor: bgColor[index] }}
                    >
                      <h3 className="capitalize font-bold text-gray-600">
                        {day?.day}
                      </h3>
                      <Switch
                        checked={days.includes(day?.id?.toString())}
                        onChange={(value) => {
                          const _schedule = { ...schedule };
                          if (!value) {
                            delete _schedule[day.day];
                          } else {
                            _schedule[day.day] = {
                              day_of_the_week: day.id,
                              time_slots: [],
                            };
                          }
                          setSchedule({ ..._schedule });
                        }}
                      />
                      <h3 className="font-bold text-center">
                        Choose a time slot
                      </h3>
                    </div>
                  }
                  key={day.id}
                >
                  {state.entities.timeSlots?.map((timeslot) => {
                    const _slotIdentity = `${day.day}__${timeslot.id}`;
                    return (
                      <div className="my-2">
                        <div className="flex justify-between items-center p-1">
                          <Switch
                            size="small"
                            className="switch"
                            onChange={(value) => {
                              const _newSchedule = { ...schedule };
                              const _dayKey = day?.day;
                              if (!value) {
                                setSlots([
                                  ...slots.filter(
                                    (item) => item !== _slotIdentity
                                  ),
                                ]);

                                // remove scheduleItem
                                const timeSlots = _newSchedule[
                                  _dayKey
                                ]?.time_slots?.filter(
                                  (scheduleItem) =>
                                    scheduleItem.slot_id !== timeslot.id
                                );
                                setSchedule({
                                  ..._newSchedule,
                                  [_dayKey]: {
                                    ..._newSchedule[_dayKey],
                                    time_slots: timeSlots,
                                  },
                                });
                              } else {
                                // add to slot
                                setSlots([...slots, _slotIdentity]);
                                // updated schedule
                                setSchedule({
                                  ..._newSchedule,
                                  [_dayKey]: {
                                    ..._newSchedule[_dayKey],
                                    time_slots: [
                                      ..._newSchedule[_dayKey].time_slots,
                                      {
                                        slot_id: timeslot.id,
                                        max_bookee: MAX_BOOKEE,
                                      },
                                    ],
                                  },
                                });
                              }

                              console.log(value, day.day, timeslot.id);
                            }}
                          />
                          <Typography>
                            {getTime(timeslot.start)} - {getTime(timeslot.end)}
                          </Typography>
                          <InputNumber
                            className="pr-2"
                            style={{ borderRadius: "20px" }}
                            defaultValue={200}
                            disabled={!slots.includes(_slotIdentity)}
                            onChange={(value) => {
                              const key = day?.day;

                              if (!key) return;

                              const _schedules = { ...schedule };
                              const timeSlots = _schedules[
                                key
                              ]?.time_slots?.map((item) => {
                                if (item.slot_id === timeslot?.id) {
                                  return {
                                    ...item,
                                    max_bookee: value,
                                  };
                                }
                                return item;
                              });
                              setSchedule({
                                ..._schedules,
                                [key]: {
                                  ..._schedules[key],
                                  time_slots: timeSlots,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </Collapse.Panel>
              );
            })}
          </Collapse>
          <br />
          <Button
            onClick={handleSubmit}
            type="primary"
            shape="round"
            className="button"
          >
            Create schedule
          </Button>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default CreateSchedule;
