import APIClient from "./api";

export default class InvoiceService extends APIClient {
  constructor() {
    super();
    this.addAccessToken();
  }

  generateBookingInvoice = (bookingCode) => {
    return this.get(`/payment/generate/invoice/${bookingCode}`);
  };
}
