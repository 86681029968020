import { createAsyncThunk } from "@reduxjs/toolkit";
import TaskServices from "../../services/task.service";
import { addTasks } from "../slices/task.slice";

export const fetchKoldChainTaskServicesThunk = createAsyncThunk(
  "tasks/getServices/Koldchain",
  async (_, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const { data } = await TaskServices.getKoldChainServicesTask();
      dispatch(addTasks(data?.data));
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchLabAdminTaskServicesThunk = createAsyncThunk(
  "tasks/getServices/labAdmin",
  async (_, { fulfillWithValue, rejectWithValue, dispatch }) => {
    try {
      const { data } = await TaskServices.getLabAdminServicesTask();
      dispatch(addTasks(data?.data));
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
