import { APIClient } from "./api";

// Endpoints Task
export const getLabServicesTaskUrl =
  "/booking/manager/bulk/service/upload/status/";
export const getKoldChainAdminServicesTaskUrl =
  "/booking/manager/services/upload/status/";

const api = new APIClient();
export default class TaskServices {
  static getKoldChainServicesTask = async () => {
    api.addAccessToken();
    return api.get(getKoldChainAdminServicesTaskUrl);
  };
  static getLabAdminServicesTask = async () => {
    api.addAccessToken();
    return api.get(getLabServicesTaskUrl);
  };
}
