import React, { useEffect, useState } from "react";
import useAxiosFetch from "../utils/custom_hooks/useAxiosFetch";
import InvoiceService from "../services/invoice";
import { ScreenLoader } from "../components/Loader";

// const invoiceData = {
//   uuid: "e6138eba-ba9b-42f7-9ce9-1dd40b2b5a7c",
//   invoice_code: "INC17122023000000001",
//   booked_service: {
//     id: 1,
//     created_at: "2023-12-06T17:34:09.449748Z",
//     booked_by: {
//       id: 43441,
//       user: 43470,
//       code: "PA1350974268",
//       gender: "F",
//       dateOfBirth: "1984-06-07",
//       group: "Patient",
//       firstName: "Diana",
//       lastName: "Akua M.",
//       email: "Akua2@diana.com",
//       phoneNumber: "(+93)13213131313",
//     },
//     booked_entity_service: {
//       external_entity: "Original Medical Lab",
//       service_name: "Services Test 1",
//       service_identifier: "service_test_1",
//       service: "Corona Virus Disease- 2019 (COVID-19)",
//       price: {
//         amount: "200.00",
//         currency: "GHS",
//       },
//       payment_required: true,
//     },
//     schedule_date: "2023-12-12",
//     entity_booking_schedule: {
//       "working day": "Tuesday",
//       time_slot: "06:00:00 - 07:00:00",
//       max_bookee: 200,
//     },
//     code: "BO89203567",
//     payed: false,
//     travel_info: null,
//     bookee: "akua2@diana.com",
//   },
//   payed: false,
//   payable_amount: "GHS 219.80",
//   outstanding: null,
//   transaction_cost: [
//     {
//       rate: "5.00%",
//       type: "Commission",
//       amount: "10.00",
//       currency: "GHS",
//     },
//     {
//       rate: "1.50%",
//       type: "GETFund Levy",
//       amount: "3.00",
//       currency: "GHS",
//     },
//     {
//       rate: "0.70%",
//       type: "NHIL",
//       amount: "1.40",
//       currency: "GHS",
//     },
//     {
//       rate: "2.70%",
//       type: "VAT",
//       amount: "5.40",
//       currency: "GHS",
//     },
//   ],
//   generated_for: {
//     id: 43441,
//     code: "PA1350974268",
//     gender: "F",
//     date_of_birth: "1984-06-07",
//     group: "Patient",
//     email: "Akua2@diana.com",
//     nationality: "",
//   },
// };
const getInvoiceHandler = (invoiceData) => {
  return invoiceData?.transaction_cost?.map((invoice, index) => (
    <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-between mb-4 bg-slate-100 px-4 py-3 rounded-2xl">
      <li className="">
        <span className="inline-block mb-2 bg-blue-700 text-white font-bold rounded-full px-4 py-1">
          Type
        </span>
        <br />
        {invoice?.type}
      </li>
      <li>
        {" "}
        <span className="inline-block mb-2 bg-blue-700 text-white font-bold rounded-full px-4 py-1">
          Rate
        </span>
        <br />
        {invoice?.rate}
      </li>
      <li>
        <span className="inline-block mb-2 bg-blue-700 text-white font-bold rounded-full px-4 py-1">
          Amount
        </span>
        <br />
        {invoice?.currency}
        {invoice?.amount}
      </li>
    </ul>
  ));
};
const InvoiceSummary = ({ invoiceData }) => {
  // useEffect(() => {
  //   onFetch(bookingCode);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [bookingCode]);
  // if (loading) return <ScreenLoader />;
  return (
    invoiceData && (
      <div>
        <h3 className="text-yellow-500 font-bold text-lg">Payment Summary</h3>
        <p>
          <span className="font-bold">Total Amount to be paid: </span>
          {invoiceData?.payable_amount}
        </p>
        <h3 className="text-yellow-500 font-bold text-lg">Payment Breakdown</h3>
        <div className="block bg-gray-100 p-8 rounded-xl">
          <div className="grid grid-cols-2 gap-4">
            <span className="font-bold">Service</span>
            <span className="font-bold">Cost/Price</span>
            <span>
              {invoiceData?.booked_service?.booked_entity_service.service}
            </span>
            <span>
              {
                invoiceData?.booked_service?.booked_entity_service?.price
                  .currency
              }
              {invoiceData?.booked_service?.booked_entity_service?.price.amount}
            </span>
          </div>
          <hr />
          <ul>
            <li></li>
          </ul>
        </div>

        {invoiceData && (
          <section className="mt-4">{getInvoiceHandler(invoiceData)}</section>
        )}
      </div>
    )
  );
};

export default InvoiceSummary;
