import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { APIClient } from "../../services/api";
import { createDependantUrl, getDependantsUrl } from "../../utils/network/endpoints";
import { addDependants } from "../slices/dependant.slice";

const api = new APIClient();


export const fetchDependantsThunk = createAsyncThunk(
  'fetch_dependants', async (_, {dispatch, fulfillWithValue, rejectWithValue}) => {

  api.addAccessToken();
  return  api.get(getDependantsUrl).then(({data}) => {
    dispatch(addDependants(data.data));
    return fulfillWithValue(data)
  }).catch((error) => {
    return rejectWithValue(error)
  })
})

export const createDependantThunk = createAsyncThunk(
  "create_dependant",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    api.addAccessToken();
    return api
      .post(createDependantUrl, payload)
      .then(({ data }) => {
        message.success("You have successfully added a dependant");
        return fulfillWithValue(data);
      })
      .catch((error) => {
        if (error.message) {
          message.error("An error occurred whiles adding a dependant");
        }
        return rejectWithValue(error)
      });
  }
);
