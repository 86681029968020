import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { APIClient } from "../../services/api";
import { dashboardSummaryUrl } from "../../utils/network/endpoints";
import { addSummaryData } from "../slices/dashboard.slice";

const api = new APIClient();

export const fetchDashboardSummaryDataThunk = createAsyncThunk(
  "fetch_dashboard_summary_data",
  (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    api.addAccessToken();
    return api
      .get(dashboardSummaryUrl)
      .then(({ data }) => {
        dispatch(addSummaryData(data.data));
        return fulfillWithValue(data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else if (err.request) {
          message.error(err.message);
        }
        return rejectWithValue(err);
      });
  }
);
