import { createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { APIClient } from "../../services/api";
import {
  createScheduleUrl,
  scheduleListUrl,
  scheduleWeekDayUrl,
  timeItervalUrl,
} from "../../utils/network/endpoints";
import pageRoutes from "../../utils/page-routes";
import {
  addAllSchedules,
  addEntitiesData,
  types,
} from "../slices/schedule.slice";

const api = new APIClient();

export const fetchSchedulesThunk = createAsyncThunk(
  "fetch_schedules",
  async (_, { fulfillWithValue, rejectWithValue, dispatch }) => {
    api.addAccessToken();
    return api
      .get(scheduleListUrl)
      .then(({ data }) => {
        dispatch(addAllSchedules(data.data?.schedule));
        return fulfillWithValue(data.data?.schedule);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else if (err.request) {
          message.error(err.message);
        }
        return rejectWithValue(err);
      });
  }
);

export const fetchScheduleWeekdaysThunk = createAsyncThunk(
  "fetch_schedule_weekdays",
  (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    api.addAccessToken();
    api
      .get(scheduleWeekDayUrl)
      .then(({ data }) => {
        dispatch(addEntitiesData({ data: data.data, type: types.WEEK_DAYS }));
        return fulfillWithValue(data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else if (err.request) {
          message.error(err.message);
        }
        return rejectWithValue(err);
      });
  }
);

export const fetchScheduleTimeSlotsThunk = createAsyncThunk(
  "fetch_time_slots",
  async (_, { fulfillWithValue, rejectWithValue, dispatch }) => {
    api.addAccessToken();
    return api
      .get(timeItervalUrl(60))
      .then(({ data }) => {
        dispatch(addEntitiesData({ data: data.data, type: types.TIME_SLOTS }));
        return fulfillWithValue(data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else if (err.request) {
          message.error(err.message);
        }
        return rejectWithValue(err);
      });
  }
);

export const createScheduleThunk = createAsyncThunk(
  "create_schedule",
  async (
    { payload, history },
    { dispatch, fulfillWithValue, rejectWithValue }
  ) => {
    api.addAccessToken();
    return api
      .post(createScheduleUrl, payload)
      .then(({ data }) => {
        message.success("Schedule created successfully!");
        history.push(pageRoutes.schedule_list);
        return fulfillWithValue({ data });
      })
      .catch((err) => {
        if (err.status === 400) {
          message.error("An error occurred");
        }
        return rejectWithValue(err);
      });
  }
);
