import React, { useCallback, useEffect, useState } from "react";
import ContentWrapper from "@components/ContentWrapper";
import { useParams } from "react-router-dom";
import IntelService from "@services/intel.service";
import { Card, Popover, Table, Tag, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const intel = new IntelService();

const ListExternalEntityServices = () => {
  const params = useParams();
  const [entityInfo, setEntityInfo] = useState(null);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchEntityData = useCallback(async () => {
    if (!params?.entityId) return;
    setLoading(true);
    try {
      // service.onPENDING();
      const servicesResponse = await intel.getExternalEntityServices(
        params?.entityId
      );

      const entityInfoResponse = await intel.getExternalEntityDetailInfo(
        params?.entityId
      );
      setServices(servicesResponse.data?.data);
      setEntityInfo(entityInfoResponse.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [params?.entityId]);

  // UseEffects

  useEffect(() => {
    fetchEntityData();
  }, [fetchEntityData]);

  // Columns
  const columns = [
    {
      id: "service_name",
      dataIndex: "service_name",
      title: "Name",
    },
    {
      id: "bookable_entity_country",
      dataIndex: "bookable_entity_country",
      title: "Bookable Country",
    },
    {
      id: "service",
      dataIndex: "service",
      title: "Service Details",
      render: (value) => {
        return !value?.details ? (
          <Tooltip title="No field data">
            <div className="text-slate-200 hover:text-slate-500">
              <FontAwesomeIcon size="lg" icon={faQuestionCircle} />
            </div>
          </Tooltip>
        ) : (
          <Popover
            title="Service Details"
            content={
              <ul className="grid grid-cols-1 gap-2">
                {Object.keys(value?.details).map((key, index) => (
                  <li
                    key={index}
                    className="grid grid-cols-2 items-center gap-4"
                  >
                    <h3 className="font-bold">{key}</h3>{" "}
                    <p>{value?.details[key] || "Unspecified"}</p>
                  </li>
                ))}
              </ul>
            }
            className="text-blue-400 font-bold hover:cursor-pointer"
          >
            <FontAwesomeIcon icon={faEye} /> services
          </Popover>
        );
      },
    },
    {
      id: "price",
      dataIndex: "price",
      title: "Price",
      render: (_, record) =>
        record?.price && record?.price_currency ? (
          <>
            {record.price_currency}
            {record.price}
          </>
        ) : (
          <Tooltip title="No price at the moment">
            <div className="text-slate-200 hover:text-slate-500">
              <FontAwesomeIcon size="lg" icon={faQuestionCircle} />
            </div>
          </Tooltip>
        ),
    },
    {
      id: "payment_required",
      dataIndex: "payment_required",
      title: "Payment Required",
      render: (value, _) => (!value ? "No" : "Yes"),
    },
    {
      id: "status",
      dataIndex: "status",
      title: "Status",
      render: (value, _) =>
        !value ? (
          <></>
        ) : (
          <Tag color={value === "ACTIVE" ? "success" : "danger"}>{value}</Tag>
        ),
    },
  ];

  return (
    <ContentWrapper loading={loading} title={"List External Entity Services"}>
      {entityInfo && (
        <Card className="max-w-[800px] shadow-lg bg-yellow-100 rounded-2xl">
          <ul className="grid grid-cols-2 gap-4">
            <li className="flex items-center">
              <span className="px-4 py-2 rounded-lg bg-yellow-300 mr-4">
                Name:
              </span>
              <span className="font-bold flex-1">{entityInfo?.name}</span>
            </li>
            <li className="flex items-center">
              <span className="px-4 py-2 rounded-lg bg-yellow-300 mr-4">
                Phone Number:
              </span>
              <span className="font-bold flex-1">
                {entityInfo?.phoneNumber}
              </span>
            </li>
            <li className="flex items-center">
              <span className="px-4 py-2 rounded-lg bg-yellow-300 mr-4">
                Contact Email:
              </span>
              <span className="font-bold flex-1">{entityInfo?.email}</span>
            </li>
            <li className="flex items-center">
              <span className="px-4 py-2 rounded-lg bg-yellow-300 mr-4">
                Country:
              </span>
              <span className="font-bold flex-1">{entityInfo?.country}</span>
            </li>
            <li className="flex items-center">
              <span className="px-4 py-2 rounded-lg bg-yellow-300 mr-4">
                Street Address:
              </span>
              <span className="font-bold flex-1">
                {entityInfo?.streetAddress1}
              </span>
            </li>
            <li className="flex items-center">
              <span className="px-4 py-2 rounded-lg bg-yellow-300 mr-4">
                Street Address 2:
              </span>
              <span className="font-bold flex-1">
                {entityInfo?.streetAddress2}
              </span>
            </li>
          </ul>
        </Card>
      )}
      <br />
      <div>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={services}
        />
      </div>
    </ContentWrapper>
  );
};

export default ListExternalEntityServices;
