import React, { useCallback, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import AuthLayout from "./pages/Auth";
import IsAuthenticatedRoute from "./routes/IsAuthenticatedRoute";
import RedirectUser from "./routes/RedirectUser";
import Error403 from "./pages/Error/Error403";
import Error500 from "./pages/Error/Error500";
import PaymentConfirmation from "./pages/Error/PaymentConfirmation";
import LandingPage from "./pages/LandingPage";
import SearchPage from "./pages/LandingPage/SearchPage";
import PublicRoutes from "./routes/PublicRoutes";
import OrganizationServicesPage from "./pages/LandingPage/OrganizationServicesPage";
import axios from "axios";
import { setUserIpLocation } from "./utils/persistent_data/local_storage";
import Error404Page from "./pages/Error/Error404"


export default function App() {
  const getIp = useCallback(async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_GEOLOCATION_DB_URL
      );
      setUserIpLocation(response.data);
    } catch (error) {
      setUserIpLocation(null);
      console.log("Error getting client ip");
    }
  }, []);

  useEffect(() => {
    getIp();
  }, [getIp]);

  return (
    <Switch>
      {/* <Route path="/test" component={ClientsDashboard} /> */}
      <Route path="/auth" component={AuthLayout} />
      <Route path="/403_no_permission" component={Error403} />
      <Route path={"/404_page_not_found"} component={Error404Page} />
      <Route exact path="/500-server-error" component={Error500} />
      <Route
        path="/payment/confirmation/:referenceId"
        component={PaymentConfirmation}
      />
      <Route path="/dashboard">
        <IsAuthenticatedRoute component={RedirectUser} />
      </Route>
      <Route path="/search">
        <PublicRoutes component={SearchPage} />
      </Route>
      <Route
        path="/services/organization/:organizationId"
        component={OrganizationServicesPage}
      />

      <Route path="/">
        <PublicRoutes component={LandingPage} />
      </Route>
    </Switch>
  );
}
