// import "./styles/dashboard.style.css";
import React, { useCallback, useEffect, useMemo } from "react";
import { Layout, Popover, Avatar } from "antd";
import { BellOutlined, MenuOutlined } from "@ant-design/icons";
import { faBell, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import panabios from "@assets/img/brand/alkemy.png";
import ProfileDropDown from "@components/layouts/components/ProfileDropDown";
import useMediaquery from "@utils/custom_hooks/useMediaquery.hook";
import useUserEmail from "@utils/custom_hooks/useUserEmail.hook";

import CustomLoader from "@components/CustomLoader";
import { useDispatch } from "react-redux";
import CustomLocalStorage from "@utils/persistent_data/local_storage";
import authThunks from "@store/services/auth.service";
import SessionTimeOut from "@pages/Error/SessionTimeout";
import Container from "@components/container";

const { Sider, Header, Content } = Layout;

function DashboardLayout({
  isLoading = false,
  loadingText = "Loading",
  sideMenu,
  children,
}) {
  const sessionHasExpired = CustomLocalStorage.getSessionHasExpired();
  const dispatch = useDispatch();
  // const width = useMediaquery();
  const email = useUserEmail();
  // const hidden_lg = useMemo(() => width < 700, [width]);
  const expired = useMemo(
    () => sessionHasExpired !== null,
    [sessionHasExpired]
  );

  const SideBar = () => <></>;

  const sessionReAuthenticateUser = useCallback(() => {
    if (expired) {
      dispatch(authThunks.loginAfterSessionExpired());
    }
  }, [dispatch, expired]);

  useEffect(() => {
    sessionReAuthenticateUser();
  }, [sessionReAuthenticateUser]);

  if (expired) {
    return (
      <Layout>
        <SessionTimeOut />
      </Layout>
    );
  }

  return (
    <section className="dashboard-layout">
      <aside
        style={{ position: "sticky", top: "0" }}
        // hidden={hidden_lg}
        className="dashboard-layout__sidebar"
        theme="light"
      >
        <div className="sidebar-logo-wrapper center">
          <div className="image-container">
            <img src={panabios} alt="alkemy-logo" />
          </div>
        </div>
        <hr className="m-0" />
        <section className="dashboard-sidebar__menu-wrapper">
          {sideMenu}
        </section>
      </aside>
      <main className="flex-1">
        <header className="sticky py-4 top-0 z-10 bg-white">
          <Container extraClassName="flex md:justify-between">
            <ul className="list-unstyled flex md:hidden items-center">
              <li>
                <MenuOutlined style={{ fontSize: "1.2rem" }} />
              </li>
              <li className="ml-4">
                <div className="image-container">
                  <img src={panabios} alt="alkemy-logo" />
                </div>
              </li>
            </ul>
            <div
              className="flex items-center gap-2 ml-auto"
              style={{ height: "100%" }}
            >
              <BellOutlined style={{ fontSize: "1.5rem" }} icon={faBell} />
              <Popover
                className="mx-2"
                placement="bottomLeft"
                content={<ProfileDropDown />}
                trigger="click"
              >
                <Avatar
                  icon={<FontAwesomeIcon icon={faUser} />}
                  className="mt-2"
                  background="#ccc"
                />
              </Popover>

              <p className="m-0">{email}</p>
            </div>
          </Container>
        </header>
        <Content className="dashboard-layout__content">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center h-100 w-100 flex-column">
              <CustomLoader />
              <br />
              <h3>{loadingText}...</h3>
            </div>
          ) : (
            <div className="dashboard_layout-main container max-w-screen-2xl px-4 mx-auto">
              {children}
            </div>
          )}
        </Content>
      </main>
    </section>
  );
}

export default DashboardLayout;
