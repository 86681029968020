import React from "react";
import { Col, Typography } from "antd";
import LandingPageLayout from "@components/layouts/landing";

import ServiceSearchEngine from "@components/ServiceSearchEngine";
import { useHistory } from "react-router-dom";

const LandingPage = () => {
  const history = useHistory();
  return (
    <LandingPageLayout>
      <Col>
        <Typography.Title className="" level={2}>
          Book a Test
        </Typography.Title>
      </Col>
      <Col
        style={{ border: "2px solid rgba(214, 213, 212,.3)" }}
        className=""
        xs={24}
        md={24}
      >
        <div
          style={{ borderBottom: ".05px solid rgba(214, 213, 212,.3)" }}
          className="p-2"
        >
          <Typography.Title className="p-4 m-0" level={3}>
            Discover Services
          </Typography.Title>
        </div>
        <ServiceSearchEngine onComplete={() => history.push("/search")} />
      </Col>
    </LandingPageLayout>
  );
};

export default LandingPage;
