import React, { useCallback, useEffect, useState } from "react";
import { Card, Skeleton, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faQuestion } from "@fortawesome/free-solid-svg-icons";
import { useParams, Link } from "react-router-dom";
import PayStackAPI from "@services/paystack";
import useLoading from "@utils/custom_hooks/useLoading";
import { HTTPStatusType } from "@utils/http_status_type";
import { getFullDateWithTime } from "@utils/date_time_format";
import pageRoutes from "@utils/page-routes";

const PaymentConfirmation = () => {
  const { referenceId } = useParams();
  const [payment, setPayment] = useState(null);
  const { status, onFULFILLED, onPENDING, onREJECTED } = useLoading();

  const verifyPayment = useCallback(async () => {
    onPENDING();
    try {
      const response = await PayStackAPI.verifyTransaction(referenceId);
      const data = response.data?.data;
      setPayment({
        reference: data?.reference,
        status: data?.status,
        date: data?.transaction_date,
        currency: data?.currency,
        amount: data?.amount,
      });
      message.success(response.data.message);
      onFULFILLED();
    } catch (error) {
      onREJECTED();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceId]);

  useEffect(() => {
    verifyPayment();
  }, [verifyPayment]);

  return (
    <section className="bg-gray-200 h-[100svh] w-full center">
      <div className="max-w-[450px] w-full mx-auto space-y-4">
        {status.loading ? (
          <>
            <Skeleton active />
            <Skeleton acative />
          </>
        ) : status.name === HTTPStatusType.FULFILED ? (
          <>
            <Card className="rounded-3xl shadow flex  flex-col  items-center justify-center">
              <section className="flex flex-col items-center gap-4">
                <div className="text-center relative square-60 circle bg-green-100 text-white center after:content-[''] after:absolute after:w-1/2 after:h-1/2 after:rounded-full after:p-2 after:bg-green-700">
                  <FontAwesomeIcon icon={faCheck} className="z-10" />
                </div>

                <p className="text-base">Payment Success!!</p>
                <h3 className="text-3xl font-bold text-green-800">
                  {payment.currency}
                  {payment.amount}
                  <sub>.00</sub>
                </h3>
              </section>
            </Card>
            <Card className="rounded-3xl shadow ">
              <section className="space-y-2">
                <h4 className="font-semibold py-2">Payment Details</h4>
                <div className="flex justify-between">
                  <p>Ref Number</p>
                  <p className="font-semibold text-gray-500 line-clamp-1 truncate max-w-md">
                    {payment.reference}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p>Payment Status</p>
                  <p className="font-bold text-gray-500 flex text-start">
                    Success{" "}
                    <span className="inline-block w-5 h-5 bg-green-600 center text-white circle ml-4">
                      <FontAwesomeIcon size="sm" icon={faCheck} />
                    </span>
                  </p>
                </div>
                <div className="flex justify-between">
                  <p>Payment Time</p>
                  <p className="font-bold text-gray-500">
                    {getFullDateWithTime(payment.date)}
                  </p>
                </div>
                <br />
                <div className="pt-4 border-t-2 border-dashed">
                  <div className="flex justify-between">
                    <p>Total Payment</p>
                    <p className="font-bold text-gray-500">
                      {payment.currency}
                      {payment.amount}
                    </p>
                  </div>
                </div>
              </section>
            </Card>
          </>
        ) : (
          <Card className="rounded-3xl shadow flex  flex-col  items-center justify-center">
            <section className="flex flex-col items-center gap-4">
              <div className="text-center relative square-60 circle bg-red-100 text-white center after:content-[''] after:absolute after:w-1/2 after:h-1/2 after:rounded-full after:p-2 after:bg-red-700">
                <FontAwesomeIcon icon={faQuestion} className="z-10" />
              </div>

              <p className="text-base">Payment Unsuccessful!!</p>
              <p className="text-center font-bold text-slate-800">
                We apologize for the incovenience, but we encountered an issue
                during the payment process, which resulted in the transactaion
                not being successful.
              </p>
            </section>
          </Card>
        )}
        {!status.loading && (
          <div className=" mt-4">
            <section className="space-y-2">
              <Link
                to={pageRoutes.dashboard}
                className="inline-block bg-yellow-400 px-8 py-4 rounded-full text-yellow-700 font-semibold hover:scale-90 transition-transform"
              >
                Go Home
              </Link>
            </section>
          </div>
        )}
      </div>
    </section>
  );
};

export default PaymentConfirmation;
