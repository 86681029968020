const currencies = [
  {
    abbreviation: "AD",
    country: "Andorra",
    currencyCode: "EUR",
    population: "84000",
    capital: "Andorra la Vella",
    continentName: "Europe",
  },
  {
    abbreviation: "AE",
    country: "United Arab Emirates",
    currencyCode: "AED",
    population: "4975593",
    capital: "Abu Dhabi",
    continentName: "Asia",
  },
  {
    abbreviation: "AF",
    country: "Afghanistan",
    currencyCode: "AFN",
    population: "29121286",
    capital: "Kabul",
    continentName: "Asia",
  },
  {
    abbreviation: "AG",
    country: "Antigua and Barbuda",
    currencyCode: "XCD",
    population: "86754",
    capital: "St. John's",
    continentName: "North America",
  },
  {
    abbreviation: "AI",
    country: "Anguilla",
    currencyCode: "XCD",
    population: "13254",
    capital: "The Valley",
    continentName: "North America",
  },
  {
    abbreviation: "AL",
    country: "Albania",
    currencyCode: "ALL",
    population: "2986952",
    capital: "Tirana",
    continentName: "Europe",
  },
  {
    abbreviation: "AM",
    country: "Armenia",
    currencyCode: "AMD",
    population: "2968000",
    capital: "Yerevan",
    continentName: "Asia",
  },
  {
    abbreviation: "AO",
    country: "Angola",
    currencyCode: "AOA",
    population: "13068161",
    capital: "Luanda",
    continentName: "Africa",
  },
  {
    abbreviation: "AQ",
    country: "Antarctica",
    currencyCode: "",
    population: "0",
    capital: "",
    continentName: "Antarctica",
  },
  {
    abbreviation: "AR",
    country: "Argentina",
    currencyCode: "ARS",
    population: "41343201",
    capital: "Buenos Aires",
    continentName: "South America",
  },
  {
    abbreviation: "AS",
    country: "American Samoa",
    currencyCode: "USD",
    population: "57881",
    capital: "Pago Pago",
    continentName: "Oceania",
  },
  {
    abbreviation: "AT",
    country: "Austria",
    currencyCode: "EUR",
    population: "8205000",
    capital: "Vienna",
    continentName: "Europe",
  },
  {
    abbreviation: "AU",
    country: "Australia",
    currencyCode: "AUD",
    population: "21515754",
    capital: "Canberra",
    continentName: "Oceania",
  },
  {
    abbreviation: "AW",
    country: "Aruba",
    currencyCode: "AWG",
    population: "71566",
    capital: "Oranjestad",
    continentName: "North America",
  },
  {
    abbreviation: "AX",
    country: "Åland",
    currencyCode: "EUR",
    population: "26711",
    capital: "Mariehamn",
    continentName: "Europe",
  },
  {
    abbreviation: "AZ",
    country: "Azerbaijan",
    currencyCode: "AZN",
    population: "8303512",
    capital: "Baku",
    continentName: "Asia",
  },
  {
    abbreviation: "BA",
    country: "Bosnia and Herzegovina",
    currencyCode: "BAM",
    population: "4590000",
    capital: "Sarajevo",
    continentName: "Europe",
  },
  {
    abbreviation: "BB",
    country: "Barbados",
    currencyCode: "BBD",
    population: "285653",
    capital: "Bridgetown",
    continentName: "North America",
  },
  {
    abbreviation: "BD",
    country: "Bangladesh",
    currencyCode: "BDT",
    population: "156118464",
    capital: "Dhaka",
    continentName: "Asia",
  },
  {
    abbreviation: "BE",
    country: "Belgium",
    currencyCode: "EUR",
    population: "10403000",
    capital: "Brussels",
    continentName: "Europe",
  },
  {
    abbreviation: "BF",
    country: "Burkina Faso",
    currencyCode: "XOF",
    population: "16241811",
    capital: "Ouagadougou",
    continentName: "Africa",
  },
  {
    abbreviation: "BG",
    country: "Bulgaria",
    currencyCode: "BGN",
    population: "7148785",
    capital: "Sofia",
    continentName: "Europe",
  },
  {
    abbreviation: "BH",
    country: "Bahrain",
    currencyCode: "BHD",
    population: "738004",
    capital: "Manama",
    continentName: "Asia",
  },
  {
    abbreviation: "BI",
    country: "Burundi",
    currencyCode: "BIF",
    population: "9863117",
    capital: "Bujumbura",
    continentName: "Africa",
  },
  {
    abbreviation: "BJ",
    country: "Benin",
    currencyCode: "XOF",
    population: "9056010",
    capital: "Porto-Novo",
    continentName: "Africa",
  },
  {
    abbreviation: "BL",
    country: "Saint Barthélemy",
    currencyCode: "EUR",
    population: "8450",
    capital: "Gustavia",
    continentName: "North America",
  },
  {
    abbreviation: "BM",
    country: "Bermuda",
    currencyCode: "BMD",
    population: "65365",
    capital: "Hamilton",
    continentName: "North America",
  },
  {
    abbreviation: "BN",
    country: "Brunei",
    currencyCode: "BND",
    population: "395027",
    capital: "Bandar Seri Begawan",
    continentName: "Asia",
  },
  {
    abbreviation: "BO",
    country: "Bolivia",
    currencyCode: "BOB",
    population: "9947418",
    capital: "Sucre",
    continentName: "South America",
  },
  {
    abbreviation: "BQ",
    country: "Bonaire",
    currencyCode: "USD",
    population: "18012",
    capital: "Kralendijk",
    continentName: "North America",
  },
  {
    abbreviation: "BR",
    country: "Brazil",
    currencyCode: "BRL",
    population: "201103330",
    capital: "Brasília",
    continentName: "South America",
  },
  {
    abbreviation: "BS",
    country: "Bahamas",
    currencyCode: "BSD",
    population: "301790",
    capital: "Nassau",
    continentName: "North America",
  },
  {
    abbreviation: "BT",
    country: "Bhutan",
    currencyCode: "BTN",
    population: "699847",
    capital: "Thimphu",
    continentName: "Asia",
  },
  {
    abbreviation: "BV",
    country: "Bouvet Island",
    currencyCode: "NOK",
    population: "0",
    capital: "",
    continentName: "Antarctica",
  },
  {
    abbreviation: "BW",
    country: "Botswana",
    currencyCode: "BWP",
    population: "2029307",
    capital: "Gaborone",
    continentName: "Africa",
  },
  {
    abbreviation: "BY",
    country: "Belarus",
    currencyCode: "BYR",
    population: "9685000",
    capital: "Minsk",
    continentName: "Europe",
  },
  {
    abbreviation: "BZ",
    country: "Belize",
    currencyCode: "BZD",
    population: "314522",
    capital: "Belmopan",
    continentName: "North America",
  },
  {
    abbreviation: "CA",
    country: "Canada",
    currencyCode: "CAD",
    population: "33679000",
    capital: "Ottawa",
    continentName: "North America",
  },
  {
    abbreviation: "CC",
    country: "Cocos [Keeling] Islands",
    currencyCode: "AUD",
    population: "628",
    capital: "West Island",
    continentName: "Asia",
  },
  {
    abbreviation: "CD",
    country: "Democratic Republic of the Congo",
    currencyCode: "CDF",
    population: "70916439",
    capital: "Kinshasa",
    continentName: "Africa",
  },
  {
    abbreviation: "CF",
    country: "Central African Republic",
    currencyCode: "XAF",
    population: "4844927",
    capital: "Bangui",
    continentName: "Africa",
  },
  {
    abbreviation: "CG",
    country: "Republic of the Congo",
    currencyCode: "XAF",
    population: "3039126",
    capital: "Brazzaville",
    continentName: "Africa",
  },
  {
    abbreviation: "CH",
    country: "Switzerland",
    currencyCode: "CHF",
    population: "7581000",
    capital: "Bern",
    continentName: "Europe",
  },
  {
    abbreviation: "CI",
    country: "Ivory Coast",
    currencyCode: "XOF",
    population: "21058798",
    capital: "Yamoussoukro",
    continentName: "Africa",
  },
  {
    abbreviation: "CK",
    country: "Cook Islands",
    currencyCode: "NZD",
    population: "21388",
    capital: "Avarua",
    continentName: "Oceania",
  },
  {
    abbreviation: "CL",
    country: "Chile",
    currencyCode: "CLP",
    population: "16746491",
    capital: "Santiago",
    continentName: "South America",
  },
  {
    abbreviation: "CM",
    country: "Cameroon",
    currencyCode: "XAF",
    population: "19294149",
    capital: "Yaoundé",
    continentName: "Africa",
  },
  {
    abbreviation: "CN",
    country: "China",
    currencyCode: "CNY",
    population: "1330044000",
    capital: "Beijing",
    continentName: "Asia",
  },
  {
    abbreviation: "CO",
    country: "Colombia",
    currencyCode: "COP",
    population: "47790000",
    capital: "Bogotá",
    continentName: "South America",
  },
  {
    abbreviation: "CR",
    country: "Costa Rica",
    currencyCode: "CRC",
    population: "4516220",
    capital: "San José",
    continentName: "North America",
  },
  {
    abbreviation: "CU",
    country: "Cuba",
    currencyCode: "CUP",
    population: "11423000",
    capital: "Havana",
    continentName: "North America",
  },
  {
    abbreviation: "CV",
    country: "Cape Verde",
    currencyCode: "CVE",
    population: "508659",
    capital: "Praia",
    continentName: "Africa",
  },
  {
    abbreviation: "CW",
    country: "Curacao",
    currencyCode: "ANG",
    population: "141766",
    capital: "Willemstad",
    continentName: "North America",
  },
  {
    abbreviation: "CX",
    country: "Christmas Island",
    currencyCode: "AUD",
    population: "1500",
    capital: "Flying Fish Cove",
    continentName: "Asia",
  },
  {
    abbreviation: "CY",
    country: "Cyprus",
    currencyCode: "EUR",
    population: "1102677",
    capital: "Nicosia",
    continentName: "Europe",
  },
  {
    abbreviation: "CZ",
    country: "Czechia",
    currencyCode: "CZK",
    population: "10476000",
    capital: "Prague",
    continentName: "Europe",
  },
  {
    abbreviation: "DE",
    country: "Germany",
    currencyCode: "EUR",
    population: "81802257",
    capital: "Berlin",
    continentName: "Europe",
  },
  {
    abbreviation: "DJ",
    country: "Djibouti",
    currencyCode: "DJF",
    population: "740528",
    capital: "Djibouti",
    continentName: "Africa",
  },
  {
    abbreviation: "DK",
    country: "Denmark",
    currencyCode: "DKK",
    population: "5484000",
    capital: "Copenhagen",
    continentName: "Europe",
  },
  {
    abbreviation: "DM",
    country: "Dominica",
    currencyCode: "XCD",
    population: "72813",
    capital: "Roseau",
    continentName: "North America",
  },
  {
    abbreviation: "DO",
    country: "Dominican Republic",
    currencyCode: "DOP",
    population: "9823821",
    capital: "Santo Domingo",
    continentName: "North America",
  },
  {
    abbreviation: "DZ",
    country: "Algeria",
    currencyCode: "DZD",
    population: "34586184",
    capital: "Algiers",
    continentName: "Africa",
  },
  {
    abbreviation: "EC",
    country: "Ecuador",
    currencyCode: "USD",
    population: "14790608",
    capital: "Quito",
    continentName: "South America",
  },
  {
    abbreviation: "EE",
    country: "Estonia",
    currencyCode: "EUR",
    population: "1291170",
    capital: "Tallinn",
    continentName: "Europe",
  },
  {
    abbreviation: "EG",
    country: "Egypt",
    currencyCode: "EGP",
    population: "80471869",
    capital: "Cairo",
    continentName: "Africa",
  },
  {
    abbreviation: "EH",
    country: "Western Sahara",
    currencyCode: "MAD",
    population: "273008",
    capital: "Laâyoune / El Aaiún",
    continentName: "Africa",
  },
  {
    abbreviation: "ER",
    country: "Eritrea",
    currencyCode: "ERN",
    population: "5792984",
    capital: "Asmara",
    continentName: "Africa",
  },
  {
    abbreviation: "ES",
    country: "Spain",
    currencyCode: "EUR",
    population: "46505963",
    capital: "Madrid",
    continentName: "Europe",
  },
  {
    abbreviation: "ET",
    country: "Ethiopia",
    currencyCode: "ETB",
    population: "88013491",
    capital: "Addis Ababa",
    continentName: "Africa",
  },
  {
    abbreviation: "FI",
    country: "Finland",
    currencyCode: "EUR",
    population: "5244000",
    capital: "Helsinki",
    continentName: "Europe",
  },
  {
    abbreviation: "FJ",
    country: "Fiji",
    currencyCode: "FJD",
    population: "875983",
    capital: "Suva",
    continentName: "Oceania",
  },
  {
    abbreviation: "FK",
    country: "Falkland Islands",
    currencyCode: "FKP",
    population: "2638",
    capital: "Stanley",
    continentName: "South America",
  },
  {
    abbreviation: "FM",
    country: "Micronesia",
    currencyCode: "USD",
    population: "107708",
    capital: "Palikir",
    continentName: "Oceania",
  },
  {
    abbreviation: "FO",
    country: "Faroe Islands",
    currencyCode: "DKK",
    population: "48228",
    capital: "Tórshavn",
    continentName: "Europe",
  },
  {
    abbreviation: "FR",
    country: "France",
    currencyCode: "EUR",
    population: "64768389",
    capital: "Paris",
    continentName: "Europe",
  },
  {
    abbreviation: "GA",
    country: "Gabon",
    currencyCode: "XAF",
    population: "1545255",
    capital: "Libreville",
    continentName: "Africa",
  },
  {
    abbreviation: "GB",
    country: "United Kingdom",
    currencyCode: "GBP",
    population: "62348447",
    capital: "London",
    continentName: "Europe",
  },
  {
    abbreviation: "GD",
    country: "Grenada",
    currencyCode: "XCD",
    population: "107818",
    capital: "St. George's",
    continentName: "North America",
  },
  {
    abbreviation: "GE",
    country: "Georgia",
    currencyCode: "GEL",
    population: "4630000",
    capital: "Tbilisi",
    continentName: "Asia",
  },
  {
    abbreviation: "GF",
    country: "French Guiana",
    currencyCode: "EUR",
    population: "195506",
    capital: "Cayenne",
    continentName: "South America",
  },
  {
    abbreviation: "GG",
    country: "Guernsey",
    currencyCode: "GBP",
    population: "65228",
    capital: "St Peter Port",
    continentName: "Europe",
  },
  {
    abbreviation: "GH",
    country: "Ghana",
    currencyCode: "GHS",
    population: "24339838",
    capital: "Accra",
    continentName: "Africa",
  },
  {
    abbreviation: "GI",
    country: "Gibraltar",
    currencyCode: "GIP",
    population: "27884",
    capital: "Gibraltar",
    continentName: "Europe",
  },
  {
    abbreviation: "GL",
    country: "Greenland",
    currencyCode: "DKK",
    population: "56375",
    capital: "Nuuk",
    continentName: "North America",
  },
  {
    abbreviation: "GM",
    country: "Gambia",
    currencyCode: "GMD",
    population: "1593256",
    capital: "Bathurst",
    continentName: "Africa",
  },
  {
    abbreviation: "GN",
    country: "Guinea",
    currencyCode: "GNF",
    population: "10324025",
    capital: "Conakry",
    continentName: "Africa",
  },
  {
    abbreviation: "GP",
    country: "Guadeloupe",
    currencyCode: "EUR",
    population: "443000",
    capital: "Basse-Terre",
    continentName: "North America",
  },
  {
    abbreviation: "GQ",
    country: "Equatorial Guinea",
    currencyCode: "XAF",
    population: "1014999",
    capital: "Malabo",
    continentName: "Africa",
  },
  {
    abbreviation: "GR",
    country: "Greece",
    currencyCode: "EUR",
    population: "11000000",
    capital: "Athens",
    continentName: "Europe",
  },
  {
    abbreviation: "GS",
    country: "South Georgia and the South Sandwich Islands",
    currencyCode: "GBP",
    population: "30",
    capital: "Grytviken",
    continentName: "Antarctica",
  },
  {
    abbreviation: "GT",
    country: "Guatemala",
    currencyCode: "GTQ",
    population: "13550440",
    capital: "Guatemala City",
    continentName: "North America",
  },
  {
    abbreviation: "GU",
    country: "Guam",
    currencyCode: "USD",
    population: "159358",
    capital: "Hagåtña",
    continentName: "Oceania",
  },
  {
    abbreviation: "GW",
    country: "Guinea-Bissau",
    currencyCode: "XOF",
    population: "1565126",
    capital: "Bissau",
    continentName: "Africa",
  },
  {
    abbreviation: "GY",
    country: "Guyana",
    currencyCode: "GYD",
    population: "748486",
    capital: "Georgetown",
    continentName: "South America",
  },
  {
    abbreviation: "HK",
    country: "Hong Kong",
    currencyCode: "HKD",
    population: "6898686",
    capital: "Hong Kong",
    continentName: "Asia",
  },
  {
    abbreviation: "HM",
    country: "Heard Island and McDonald Islands",
    currencyCode: "AUD",
    population: "0",
    capital: "",
    continentName: "Antarctica",
  },
  {
    abbreviation: "HN",
    country: "Honduras",
    currencyCode: "HNL",
    population: "7989415",
    capital: "Tegucigalpa",
    continentName: "North America",
  },
  {
    abbreviation: "HR",
    country: "Croatia",
    currencyCode: "HRK",
    population: "4284889",
    capital: "Zagreb",
    continentName: "Europe",
  },
  {
    abbreviation: "HT",
    country: "Haiti",
    currencyCode: "HTG",
    population: "9648924",
    capital: "Port-au-Prince",
    continentName: "North America",
  },
  {
    abbreviation: "HU",
    country: "Hungary",
    currencyCode: "HUF",
    population: "9982000",
    capital: "Budapest",
    continentName: "Europe",
  },
  {
    abbreviation: "ID",
    country: "Indonesia",
    currencyCode: "IDR",
    population: "242968342",
    capital: "Jakarta",
    continentName: "Asia",
  },
  {
    abbreviation: "IE",
    country: "Ireland",
    currencyCode: "EUR",
    population: "4622917",
    capital: "Dublin",
    continentName: "Europe",
  },
  {
    abbreviation: "IL",
    country: "Israel",
    currencyCode: "ILS",
    population: "7353985",
    capital: "",
    continentName: "Asia",
  },
  {
    abbreviation: "IM",
    country: "Isle of Man",
    currencyCode: "GBP",
    population: "75049",
    capital: "Douglas",
    continentName: "Europe",
  },
  {
    abbreviation: "IN",
    country: "India",
    currencyCode: "INR",
    population: "1173108018",
    capital: "New Delhi",
    continentName: "Asia",
  },
  {
    abbreviation: "IO",
    country: "British Indian Ocean Territory",
    currencyCode: "USD",
    population: "4000",
    capital: "",
    continentName: "Asia",
  },
  {
    abbreviation: "IQ",
    country: "Iraq",
    currencyCode: "IQD",
    population: "29671605",
    capital: "Baghdad",
    continentName: "Asia",
  },
  {
    abbreviation: "IR",
    country: "Iran",
    currencyCode: "IRR",
    population: "76923300",
    capital: "Tehran",
    continentName: "Asia",
  },
  {
    abbreviation: "IS",
    country: "Iceland",
    currencyCode: "ISK",
    population: "308910",
    capital: "Reykjavik",
    continentName: "Europe",
  },
  {
    abbreviation: "IT",
    country: "Italy",
    currencyCode: "EUR",
    population: "60340328",
    capital: "Rome",
    continentName: "Europe",
  },
  {
    abbreviation: "JE",
    country: "Jersey",
    currencyCode: "GBP",
    population: "90812",
    capital: "Saint Helier",
    continentName: "Europe",
  },
  {
    abbreviation: "JM",
    country: "Jamaica",
    currencyCode: "JMD",
    population: "2847232",
    capital: "Kingston",
    continentName: "North America",
  },
  {
    abbreviation: "JO",
    country: "Jordan",
    currencyCode: "JOD",
    population: "6407085",
    capital: "Amman",
    continentName: "Asia",
  },
  {
    abbreviation: "JP",
    country: "Japan",
    currencyCode: "JPY",
    population: "127288000",
    capital: "Tokyo",
    continentName: "Asia",
  },
  {
    abbreviation: "KE",
    country: "Kenya",
    currencyCode: "KES",
    population: "40046566",
    capital: "Nairobi",
    continentName: "Africa",
  },
  {
    abbreviation: "KG",
    country: "Kyrgyzstan",
    currencyCode: "KGS",
    population: "5776500",
    capital: "Bishkek",
    continentName: "Asia",
  },
  {
    abbreviation: "KH",
    country: "Cambodia",
    currencyCode: "KHR",
    population: "14453680",
    capital: "Phnom Penh",
    continentName: "Asia",
  },
  {
    abbreviation: "KI",
    country: "Kiribati",
    currencyCode: "AUD",
    population: "92533",
    capital: "Tarawa",
    continentName: "Oceania",
  },
  {
    abbreviation: "KM",
    country: "Comoros",
    currencyCode: "KMF",
    population: "773407",
    capital: "Moroni",
    continentName: "Africa",
  },
  {
    abbreviation: "KN",
    country: "Saint Kitts and Nevis",
    currencyCode: "XCD",
    population: "51134",
    capital: "Basseterre",
    continentName: "North America",
  },
  {
    abbreviation: "KP",
    country: "North Korea",
    currencyCode: "KPW",
    population: "22912177",
    capital: "Pyongyang",
    continentName: "Asia",
  },
  {
    abbreviation: "KR",
    country: "South Korea",
    currencyCode: "KRW",
    population: "48422644",
    capital: "Seoul",
    continentName: "Asia",
  },
  {
    abbreviation: "KW",
    country: "Kuwait",
    currencyCode: "KWD",
    population: "2789132",
    capital: "Kuwait City",
    continentName: "Asia",
  },
  {
    abbreviation: "KY",
    country: "Cayman Islands",
    currencyCode: "KYD",
    population: "44270",
    capital: "George Town",
    continentName: "North America",
  },
  {
    abbreviation: "KZ",
    country: "Kazakhstan",
    currencyCode: "KZT",
    population: "15340000",
    capital: "Astana",
    continentName: "Asia",
  },
  {
    abbreviation: "LA",
    country: "Laos",
    currencyCode: "LAK",
    population: "6368162",
    capital: "Vientiane",
    continentName: "Asia",
  },
  {
    abbreviation: "LB",
    country: "Lebanon",
    currencyCode: "LBP",
    population: "4125247",
    capital: "Beirut",
    continentName: "Asia",
  },
  {
    abbreviation: "LC",
    country: "Saint Lucia",
    currencyCode: "XCD",
    population: "160922",
    capital: "Castries",
    continentName: "North America",
  },
  {
    abbreviation: "LI",
    country: "Liechtenstein",
    currencyCode: "CHF",
    population: "35000",
    capital: "Vaduz",
    continentName: "Europe",
  },
  {
    abbreviation: "LK",
    country: "Sri Lanka",
    currencyCode: "LKR",
    population: "21513990",
    capital: "Colombo",
    continentName: "Asia",
  },
  {
    abbreviation: "LR",
    country: "Liberia",
    currencyCode: "LRD",
    population: "3685076",
    capital: "Monrovia",
    continentName: "Africa",
  },
  {
    abbreviation: "LS",
    country: "Lesotho",
    currencyCode: "LSL",
    population: "1919552",
    capital: "Maseru",
    continentName: "Africa",
  },
  {
    abbreviation: "LT",
    country: "Lithuania",
    currencyCode: "EUR",
    population: "2944459",
    capital: "Vilnius",
    continentName: "Europe",
  },
  {
    abbreviation: "LU",
    country: "Luxembourg",
    currencyCode: "EUR",
    population: "497538",
    capital: "Luxembourg",
    continentName: "Europe",
  },
  {
    abbreviation: "LV",
    country: "Latvia",
    currencyCode: "EUR",
    population: "2217969",
    capital: "Riga",
    continentName: "Europe",
  },
  {
    abbreviation: "LY",
    country: "Libya",
    currencyCode: "LYD",
    population: "6461454",
    capital: "Tripoli",
    continentName: "Africa",
  },
  {
    abbreviation: "MA",
    country: "Morocco",
    currencyCode: "MAD",
    population: "33848242",
    capital: "Rabat",
    continentName: "Africa",
  },
  {
    abbreviation: "MC",
    country: "Monaco",
    currencyCode: "EUR",
    population: "32965",
    capital: "Monaco",
    continentName: "Europe",
  },
  {
    abbreviation: "MD",
    country: "Moldova",
    currencyCode: "MDL",
    population: "4324000",
    capital: "Chişinău",
    continentName: "Europe",
  },
  {
    abbreviation: "ME",
    country: "Montenegro",
    currencyCode: "EUR",
    population: "666730",
    capital: "Podgorica",
    continentName: "Europe",
  },
  {
    abbreviation: "MF",
    country: "Saint Martin",
    currencyCode: "EUR",
    population: "35925",
    capital: "Marigot",
    continentName: "North America",
  },
  {
    abbreviation: "MG",
    country: "Madagascar",
    currencyCode: "MGA",
    population: "21281844",
    capital: "Antananarivo",
    continentName: "Africa",
  },
  {
    abbreviation: "MH",
    country: "Marshall Islands",
    currencyCode: "USD",
    population: "65859",
    capital: "Majuro",
    continentName: "Oceania",
  },
  {
    abbreviation: "MK",
    country: "Macedonia",
    currencyCode: "MKD",
    population: "2062294",
    capital: "Skopje",
    continentName: "Europe",
  },
  {
    abbreviation: "ML",
    country: "Mali",
    currencyCode: "XOF",
    population: "13796354",
    capital: "Bamako",
    continentName: "Africa",
  },
  {
    abbreviation: "MM",
    country: "Myanmar [Burma]",
    currencyCode: "MMK",
    population: "53414374",
    capital: "Naypyitaw",
    continentName: "Asia",
  },
  {
    abbreviation: "MN",
    country: "Mongolia",
    currencyCode: "MNT",
    population: "3086918",
    capital: "Ulan Bator",
    continentName: "Asia",
  },
  {
    abbreviation: "MO",
    country: "Macao",
    currencyCode: "MOP",
    population: "449198",
    capital: "Macao",
    continentName: "Asia",
  },
  {
    abbreviation: "MP",
    country: "Northern Mariana Islands",
    currencyCode: "USD",
    population: "53883",
    capital: "Saipan",
    continentName: "Oceania",
  },
  {
    abbreviation: "MQ",
    country: "Martinique",
    currencyCode: "EUR",
    population: "432900",
    capital: "Fort-de-France",
    continentName: "North America",
  },
  {
    abbreviation: "MR",
    country: "Mauritania",
    currencyCode: "MRO",
    population: "3205060",
    capital: "Nouakchott",
    continentName: "Africa",
  },
  {
    abbreviation: "MS",
    country: "Montserrat",
    currencyCode: "XCD",
    population: "9341",
    capital: "Plymouth",
    continentName: "North America",
  },
  {
    abbreviation: "MT",
    country: "Malta",
    currencyCode: "EUR",
    population: "403000",
    capital: "Valletta",
    continentName: "Europe",
  },
  {
    abbreviation: "MU",
    country: "Mauritius",
    currencyCode: "MUR",
    population: "1294104",
    capital: "Port Louis",
    continentName: "Africa",
  },
  {
    abbreviation: "MV",
    country: "Maldives",
    currencyCode: "MVR",
    population: "395650",
    capital: "Malé",
    continentName: "Asia",
  },
  {
    abbreviation: "MW",
    country: "Malawi",
    currencyCode: "MWK",
    population: "15447500",
    capital: "Lilongwe",
    continentName: "Africa",
  },
  {
    abbreviation: "MX",
    country: "Mexico",
    currencyCode: "MXN",
    population: "112468855",
    capital: "Mexico City",
    continentName: "North America",
  },
  {
    abbreviation: "MY",
    country: "Malaysia",
    currencyCode: "MYR",
    population: "28274729",
    capital: "Kuala Lumpur",
    continentName: "Asia",
  },
  {
    abbreviation: "MZ",
    country: "Mozambique",
    currencyCode: "MZN",
    population: "22061451",
    capital: "Maputo",
    continentName: "Africa",
  },
  {
    abbreviation: "NA",
    country: "Namibia",
    currencyCode: "NAD",
    population: "2128471",
    capital: "Windhoek",
    continentName: "Africa",
  },
  {
    abbreviation: "NC",
    country: "New Caledonia",
    currencyCode: "XPF",
    population: "216494",
    capital: "Noumea",
    continentName: "Oceania",
  },
  {
    abbreviation: "NE",
    country: "Niger",
    currencyCode: "XOF",
    population: "15878271",
    capital: "Niamey",
    continentName: "Africa",
  },
  {
    abbreviation: "NF",
    country: "Norfolk Island",
    currencyCode: "AUD",
    population: "1828",
    capital: "Kingston",
    continentName: "Oceania",
  },
  {
    abbreviation: "NG",
    country: "Nigeria",
    currencyCode: "NGN",
    population: "154000000",
    capital: "Abuja",
    continentName: "Africa",
  },
  {
    abbreviation: "NI",
    country: "Nicaragua",
    currencyCode: "NIO",
    population: "5995928",
    capital: "Managua",
    continentName: "North America",
  },
  {
    abbreviation: "NL",
    country: "Netherlands",
    currencyCode: "EUR",
    population: "16645000",
    capital: "Amsterdam",
    continentName: "Europe",
  },
  {
    abbreviation: "NO",
    country: "Norway",
    currencyCode: "NOK",
    population: "5009150",
    capital: "Oslo",
    continentName: "Europe",
  },
  {
    abbreviation: "NP",
    country: "Nepal",
    currencyCode: "NPR",
    population: "28951852",
    capital: "Kathmandu",
    continentName: "Asia",
  },
  {
    abbreviation: "NR",
    country: "Nauru",
    currencyCode: "AUD",
    population: "10065",
    capital: "Yaren",
    continentName: "Oceania",
  },
  {
    abbreviation: "NU",
    country: "Niue",
    currencyCode: "NZD",
    population: "2166",
    capital: "Alofi",
    continentName: "Oceania",
  },
  {
    abbreviation: "NZ",
    country: "New Zealand",
    currencyCode: "NZD",
    population: "4252277",
    capital: "Wellington",
    continentName: "Oceania",
  },
  {
    abbreviation: "OM",
    country: "Oman",
    currencyCode: "OMR",
    population: "2967717",
    capital: "Muscat",
    continentName: "Asia",
  },
  {
    abbreviation: "PA",
    country: "Panama",
    currencyCode: "PAB",
    population: "3410676",
    capital: "Panama City",
    continentName: "North America",
  },
  {
    abbreviation: "PE",
    country: "Peru",
    currencyCode: "PEN",
    population: "29907003",
    capital: "Lima",
    continentName: "South America",
  },
  {
    abbreviation: "PF",
    country: "French Polynesia",
    currencyCode: "XPF",
    population: "270485",
    capital: "Papeete",
    continentName: "Oceania",
  },
  {
    abbreviation: "PG",
    country: "Papua New Guinea",
    currencyCode: "PGK",
    population: "6064515",
    capital: "Port Moresby",
    continentName: "Oceania",
  },
  {
    abbreviation: "PH",
    country: "Philippines",
    currencyCode: "PHP",
    population: "99900177",
    capital: "Manila",
    continentName: "Asia",
  },
  {
    abbreviation: "PK",
    country: "Pakistan",
    currencyCode: "PKR",
    population: "184404791",
    capital: "Islamabad",
    continentName: "Asia",
  },
  {
    abbreviation: "PL",
    country: "Poland",
    currencyCode: "PLN",
    population: "38500000",
    capital: "Warsaw",
    continentName: "Europe",
  },
  {
    abbreviation: "PM",
    country: "Saint Pierre and Miquelon",
    currencyCode: "EUR",
    population: "7012",
    capital: "Saint-Pierre",
    continentName: "North America",
  },
  {
    abbreviation: "PN",
    country: "Pitcairn Islands",
    currencyCode: "NZD",
    population: "46",
    capital: "Adamstown",
    continentName: "Oceania",
  },
  {
    abbreviation: "PR",
    country: "Puerto Rico",
    currencyCode: "USD",
    population: "3916632",
    capital: "San Juan",
    continentName: "North America",
  },
  {
    abbreviation: "PS",
    country: "Palestine",
    currencyCode: "ILS",
    population: "3800000",
    capital: "",
    continentName: "Asia",
  },
  {
    abbreviation: "PT",
    country: "Portugal",
    currencyCode: "EUR",
    population: "10676000",
    capital: "Lisbon",
    continentName: "Europe",
  },
  {
    abbreviation: "PW",
    country: "Palau",
    currencyCode: "USD",
    population: "19907",
    capital: "Melekeok",
    continentName: "Oceania",
  },
  {
    abbreviation: "PY",
    country: "Paraguay",
    currencyCode: "PYG",
    population: "6375830",
    capital: "Asunción",
    continentName: "South America",
  },
  {
    abbreviation: "QA",
    country: "Qatar",
    currencyCode: "QAR",
    population: "840926",
    capital: "Doha",
    continentName: "Asia",
  },
  {
    abbreviation: "RE",
    country: "Réunion",
    currencyCode: "EUR",
    population: "776948",
    capital: "Saint-Denis",
    continentName: "Africa",
  },
  {
    abbreviation: "RO",
    country: "Romania",
    currencyCode: "RON",
    population: "21959278",
    capital: "Bucharest",
    continentName: "Europe",
  },
  {
    abbreviation: "RS",
    country: "Serbia",
    currencyCode: "RSD",
    population: "7344847",
    capital: "Belgrade",
    continentName: "Europe",
  },
  {
    abbreviation: "RU",
    country: "Russia",
    currencyCode: "RUB",
    population: "140702000",
    capital: "Moscow",
    continentName: "Europe",
  },
  {
    abbreviation: "RW",
    country: "Rwanda",
    currencyCode: "RWF",
    population: "11055976",
    capital: "Kigali",
    continentName: "Africa",
  },
  {
    abbreviation: "SA",
    country: "Saudi Arabia",
    currencyCode: "SAR",
    population: "25731776",
    capital: "Riyadh",
    continentName: "Asia",
  },
  {
    abbreviation: "SB",
    country: "Solomon Islands",
    currencyCode: "SBD",
    population: "559198",
    capital: "Honiara",
    continentName: "Oceania",
  },
  {
    abbreviation: "SC",
    country: "Seychelles",
    currencyCode: "SCR",
    population: "88340",
    capital: "Victoria",
    continentName: "Africa",
  },
  {
    abbreviation: "SD",
    country: "Sudan",
    currencyCode: "SDG",
    population: "35000000",
    capital: "Khartoum",
    continentName: "Africa",
  },
  {
    abbreviation: "SE",
    country: "Sweden",
    currencyCode: "SEK",
    population: "9828655",
    capital: "Stockholm",
    continentName: "Europe",
  },
  {
    abbreviation: "SG",
    country: "Singapore",
    currencyCode: "SGD",
    population: "4701069",
    capital: "Singapore",
    continentName: "Asia",
  },
  {
    abbreviation: "SH",
    country: "Saint Helena",
    currencyCode: "SHP",
    population: "7460",
    capital: "Jamestown",
    continentName: "Africa",
  },
  {
    abbreviation: "SI",
    country: "Slovenia",
    currencyCode: "EUR",
    population: "2007000",
    capital: "Ljubljana",
    continentName: "Europe",
  },
  {
    abbreviation: "SJ",
    country: "Svalbard and Jan Mayen",
    currencyCode: "NOK",
    population: "2550",
    capital: "Longyearbyen",
    continentName: "Europe",
  },
  {
    abbreviation: "SK",
    country: "Slovakia",
    currencyCode: "EUR",
    population: "5455000",
    capital: "Bratislava",
    continentName: "Europe",
  },
  {
    abbreviation: "SL",
    country: "Sierra Leone",
    currencyCode: "SLL",
    population: "5245695",
    capital: "Freetown",
    continentName: "Africa",
  },
  {
    abbreviation: "SM",
    country: "San Marino",
    currencyCode: "EUR",
    population: "31477",
    capital: "San Marino",
    continentName: "Europe",
  },
  {
    abbreviation: "SN",
    country: "Senegal",
    currencyCode: "XOF",
    population: "12323252",
    capital: "Dakar",
    continentName: "Africa",
  },
  {
    abbreviation: "SO",
    country: "Somalia",
    currencyCode: "SOS",
    population: "10112453",
    capital: "Mogadishu",
    continentName: "Africa",
  },
  {
    abbreviation: "SR",
    country: "Suriname",
    currencyCode: "SRD",
    population: "492829",
    capital: "Paramaribo",
    continentName: "South America",
  },
  {
    abbreviation: "SS",
    country: "South Sudan",
    currencyCode: "SSP",
    population: "8260490",
    capital: "Juba",
    continentName: "Africa",
  },
  {
    abbreviation: "ST",
    country: "São Tomé and Príncipe",
    currencyCode: "STD",
    population: "175808",
    capital: "São Tomé",
    continentName: "Africa",
  },
  {
    abbreviation: "SV",
    country: "El Salvador",
    currencyCode: "USD",
    population: "6052064",
    capital: "San Salvador",
    continentName: "North America",
  },
  {
    abbreviation: "SX",
    country: "Sint Maarten",
    currencyCode: "ANG",
    population: "37429",
    capital: "Philipsburg",
    continentName: "North America",
  },
  {
    abbreviation: "SY",
    country: "Syria",
    currencyCode: "SYP",
    population: "22198110",
    capital: "Damascus",
    continentName: "Asia",
  },
  {
    abbreviation: "SZ",
    country: "Swaziland",
    currencyCode: "SZL",
    population: "1354051",
    capital: "Mbabane",
    continentName: "Africa",
  },
  {
    abbreviation: "TC",
    country: "Turks and Caicos Islands",
    currencyCode: "USD",
    population: "20556",
    capital: "Cockburn Town",
    continentName: "North America",
  },
  {
    abbreviation: "TD",
    country: "Chad",
    currencyCode: "XAF",
    population: "10543464",
    capital: "N'Djamena",
    continentName: "Africa",
  },
  {
    abbreviation: "TF",
    country: "French Southern Territories",
    currencyCode: "EUR",
    population: "140",
    capital: "Port-aux-Français",
    continentName: "Antarctica",
  },
  {
    abbreviation: "TG",
    country: "Togo",
    currencyCode: "XOF",
    population: "6587239",
    capital: "Lomé",
    continentName: "Africa",
  },
  {
    abbreviation: "TH",
    country: "Thailand",
    currencyCode: "THB",
    population: "67089500",
    capital: "Bangkok",
    continentName: "Asia",
  },
  {
    abbreviation: "TJ",
    country: "Tajikistan",
    currencyCode: "TJS",
    population: "7487489",
    capital: "Dushanbe",
    continentName: "Asia",
  },
  {
    abbreviation: "TK",
    country: "Tokelau",
    currencyCode: "NZD",
    population: "1466",
    capital: "",
    continentName: "Oceania",
  },
  {
    abbreviation: "TL",
    country: "East Timor",
    currencyCode: "USD",
    population: "1154625",
    capital: "Dili",
    continentName: "Oceania",
  },
  {
    abbreviation: "TM",
    country: "Turkmenistan",
    currencyCode: "TMT",
    population: "4940916",
    capital: "Ashgabat",
    continentName: "Asia",
  },
  {
    abbreviation: "TN",
    country: "Tunisia",
    currencyCode: "TND",
    population: "10589025",
    capital: "Tunis",
    continentName: "Africa",
  },
  {
    abbreviation: "TO",
    country: "Tonga",
    currencyCode: "TOP",
    population: "122580",
    capital: "Nuku'alofa",
    continentName: "Oceania",
  },
  {
    abbreviation: "TR",
    country: "Turkey",
    currencyCode: "TRY",
    population: "77804122",
    capital: "Ankara",
    continentName: "Asia",
  },
  {
    abbreviation: "TT",
    country: "Trinidad and Tobago",
    currencyCode: "TTD",
    population: "1228691",
    capital: "Port of Spain",
    continentName: "North America",
  },
  {
    abbreviation: "TV",
    country: "Tuvalu",
    currencyCode: "AUD",
    population: "10472",
    capital: "Funafuti",
    continentName: "Oceania",
  },
  {
    abbreviation: "TW",
    country: "Taiwan",
    currencyCode: "TWD",
    population: "22894384",
    capital: "Taipei",
    continentName: "Asia",
  },
  {
    abbreviation: "TZ",
    country: "Tanzania",
    currencyCode: "TZS",
    population: "41892895",
    capital: "Dodoma",
    continentName: "Africa",
  },
  {
    abbreviation: "UA",
    country: "Ukraine",
    currencyCode: "UAH",
    population: "45415596",
    capital: "Kiev",
    continentName: "Europe",
  },
  {
    abbreviation: "UG",
    country: "Uganda",
    currencyCode: "UGX",
    population: "33398682",
    capital: "Kampala",
    continentName: "Africa",
  },
  {
    abbreviation: "UM",
    country: "U.S. Minor Outlying Islands",
    currencyCode: "USD",
    population: "0",
    capital: "",
    continentName: "Oceania",
  },
  {
    abbreviation: "US",
    country: "United States",
    currencyCode: "USD",
    population: "310232863",
    capital: "Washington",
    continentName: "North America",
  },
  {
    abbreviation: "UY",
    country: "Uruguay",
    currencyCode: "UYU",
    population: "3477000",
    capital: "Montevideo",
    continentName: "South America",
  },
  {
    abbreviation: "UZ",
    country: "Uzbekistan",
    currencyCode: "UZS",
    population: "27865738",
    capital: "Tashkent",
    continentName: "Asia",
  },
  {
    abbreviation: "VA",
    country: "Vatican City",
    currencyCode: "EUR",
    population: "921",
    capital: "Vatican City",
    continentName: "Europe",
  },
  {
    abbreviation: "VC",
    country: "Saint Vincent and the Grenadines",
    currencyCode: "XCD",
    population: "104217",
    capital: "Kingstown",
    continentName: "North America",
  },
  {
    abbreviation: "VE",
    country: "Venezuela",
    currencyCode: "VEF",
    population: "27223228",
    capital: "Caracas",
    continentName: "South America",
  },
  {
    abbreviation: "VG",
    country: "British Virgin Islands",
    currencyCode: "USD",
    population: "21730",
    capital: "Road Town",
    continentName: "North America",
  },
  {
    abbreviation: "VI",
    country: "U.S. Virgin Islands",
    currencyCode: "USD",
    population: "108708",
    capital: "Charlotte Amalie",
    continentName: "North America",
  },
  {
    abbreviation: "VN",
    country: "Vietnam",
    currencyCode: "VND",
    population: "89571130",
    capital: "Hanoi",
    continentName: "Asia",
  },
  {
    abbreviation: "VU",
    country: "Vanuatu",
    currencyCode: "VUV",
    population: "221552",
    capital: "Port Vila",
    continentName: "Oceania",
  },
  {
    abbreviation: "WF",
    country: "Wallis and Futuna",
    currencyCode: "XPF",
    population: "16025",
    capital: "Mata-Utu",
    continentName: "Oceania",
  },
  {
    abbreviation: "WS",
    country: "Samoa",
    currencyCode: "WST",
    population: "192001",
    capital: "Apia",
    continentName: "Oceania",
  },
  {
    abbreviation: "XK",
    country: "Kosovo",
    currencyCode: "EUR",
    population: "1800000",
    capital: "Pristina",
    continentName: "Europe",
  },
  {
    abbreviation: "YE",
    country: "Yemen",
    currencyCode: "YER",
    population: "23495361",
    capital: "Sanaa",
    continentName: "Asia",
  },
  {
    abbreviation: "YT",
    country: "Mayotte",
    currencyCode: "EUR",
    population: "159042",
    capital: "Mamoudzou",
    continentName: "Africa",
  },
  {
    abbreviation: "ZA",
    country: "South Africa",
    currencyCode: "ZAR",
    population: "49000000",
    capital: "Pretoria",
    continentName: "Africa",
  },
  {
    abbreviation: "ZM",
    country: "Zambia",
    currencyCode: "ZMW",
    population: "13460305",
    capital: "Lusaka",
    continentName: "Africa",
  },
  {
    abbreviation: "ZW",
    country: "Zimbabwe",
    currencyCode: "ZWL",
    population: "13061000",
    capital: "Harare",
    continentName: "Africa",
  },
];

export default currencies;
