import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cas_next_url: "/",
  session_expired: false,
};

const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setRedirectUrl: (state, { payload }) => {
      state.cas_next_url = payload;
    },
    setSessionExpire: (state, { payload }) => {
      state.session_expired = payload;
    },
  },
});

export const { setRedirectUrl, setSessionExpire } = appSlice.actions;

export const getAppState = (state) => state.app;

export default appSlice.reducer;
