import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./rootReducer";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// Redux dev tools configuration
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persisConfig = {
  storage,
  key: "root",
};

const persistedReducer = persistReducer(persisConfig, rootReducer);

export const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(thunk)));
export const persistor = persistStore(store);

