import React, { useCallback, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

const FileUploader = ({
  multiple = false,
  required = false,
  filePickHandler = (files) => {},
}) => {
  const fileRef = useRef();
  const [files, setFiles] = useState([]);

  const browserFiles = useCallback(() => {
    fileRef.current && fileRef.current.click();
  }, []);

  const onFileChange = (e) => {
    const files = [...e.target.files];
    setFiles(files);
    filePickHandler(files);
  };

  return (
    <>
      <h4>
        <span hidden={!required} className="text-red-400">
          *
        </span>{" "}
        File
      </h4>
      <div className="flex items-center justify-center bg-gray-100 rounded-2xl p-4 h-[80px] cursor-pointer group">
        <div className="flex justify-center">
          <FontAwesomeIcon
            icon={faFileExcel}
            className="text-xl"
            color=" #ccc"
          />
          <h4
            onClick={browserFiles}
            className="group-hover:underline group-hover:font-bold text-primary px-2 "
          >
            Browse
          </h4>
        </div>
        <input
          ref={fileRef}
          multiple={multiple}
          onChange={onFileChange}
          hidden
          type="file"
          accept=".xls, .xlsx, .xlsm, application/vnd.ms-excel"
        />
      </div>
      <ul className="file-upload-output">
        {files.map((file, index) => (
          <li key={index}>{file?.name}</li>
        ))}
      </ul>
      {files.length <= 0 && <p className="text-danger">No file selected</p>}
    </>
  );
};

export default FileUploader;
