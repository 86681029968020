const setLocalItems = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const getLocalItem = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    return null;
  }
};

// export const getLoggedInUser = () => {
//   console.log(getLocalItem("loggedUser"));
//   return getLocalItem("loggedUser");
// };

export const getUsername = () => {
  return getLocalItem("username");
};

export const setUsername = (username) => {
  setLocalItems("username", username);
};

export const getSessionId = () => {
  return getLocalItem("sessionId");
};

export const setSessionId = (sessionId) => {
  setLocalItems("sessionId", sessionId);
};

export const getAccessToken = () => {
  return getLocalItem("accessToken");
};

export const setAccessToken = (accessToken) => {
  setLocalItems("accessToken", accessToken);
};

export const getBookingService = () => {
  return getLocalItem("bookingService");
};

export const setBookingService = (service) => {
  setLocalItems("bookingService", service);
};
export const getUserProfile = () => {
  return getLocalItem("userProfile");
};

export const setUserProfile = (userProfile) => {
  setLocalItems("userProfile", userProfile);
};

export const setUserIpLocation = (location) => {
  setLocalItems("userIpLocation", location);
};

export const getUserIpLocation = () => getLocalItem("userIpLocation");

const Keys = {
  SESSION_EXPIRED: "session_has_expired",
};

export default class CustomLocalStorage {
  static _APP_NAME = "_alkemy_";

  static getKey = (key) => {
    return CustomLocalStorage._APP_NAME?.concat(key);
  };

  static setLocalItem = (key, data) => {
    const _data = JSON.stringify(data);
    try {
      localStorage.setItem(CustomLocalStorage.getKey(key), _data);
    } catch (error) {
      console.log(error);
    }
    return _data;
  };

  static getLocalItem = (key) => {
    try {
      return JSON.parse(localStorage.getItem(CustomLocalStorage.getKey(key)));
    } catch (e) {
      return null;
    }
  };

  static setSessionHasExpired = (flag = true) => {
    CustomLocalStorage.setLocalItem(
      Keys.SESSION_EXPIRED,
      flag
        ? {
            expired: flag,
            createdAt: new Date().toISOString(),
          }
        : null
    );
  };

  static getSessionHasExpired = () =>
    CustomLocalStorage.getLocalItem(Keys.SESSION_EXPIRED);
}
