import { Table, Tag, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentWrapper from "@components/ContentWrapper";
import { fetchKoldChainApprovedServicesThunk } from "@store/thunks/service.thunk";
import useCustomSelector from "@utils/custom_hooks/useCustomSelector";

const KoldChainListApprovedServices = () => {
  const serviceState = useCustomSelector((state) => state.service);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchKoldChainApprovedServicesThunk());
  }, [dispatch]);

  return (
    <ContentWrapper>
      <Typography.Title level={3} className="heading">
        List of Services
      </Typography.Title>

      <Table
        loading={serviceState.loading}
        dataSource={serviceState.state.koldchain.approved}
        columns={[
          {
            title: "Name",
            dataIndex: "service",
            key: "name",
            render: (value) => <p>{value.details.name}</p>,
          },
          {
            title: "Entity Name",
            dataIndex: "bookable_entity_name",
            key: "bookable_entity_name",
          },
          {
            title: "Country",
            dataIndex: "bookable_entity_country",
            key: "bookable_entity_country",
          },
          {
            title: "Price",
            dataIndex: "price_currency",
            key: "currency",
            render: (value, record) => <p>{`${value} ${record.price}`}</p>,
          },
          {
            title: "Payment required",
            dataIndex: "payment_required",
            key: "payment_required",
            render: (value, _) => (
              <p>
                {value ? (
                  <Tag color="green">true</Tag>
                ) : (
                  <Tag color="red">false</Tag>
                )}
              </p>
            ),
          },
        ]}
      />
    </ContentWrapper>
  );
};

export default KoldChainListApprovedServices;
