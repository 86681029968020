import React from "react";
import { Button, Card, DatePicker, List, message } from "antd";
import CustomLoader from "@components/CustomLoader";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableBookings } from "../../../../store/actions/bookNowServicesActions";
import { useParams } from "react-router";
import useMediaquery from "@utils/custom_hooks/useMediaquery.hook";
import { getRawDayMonthYear, getTime } from "@utils/date_time_format";
import { ArrowRight } from "react-bootstrap-icons";
import moment from "moment";

const ChooseTimeSlotForm = ({ onNext, setScheduleDateAndTime, formData }) => {
  const dispatch = useDispatch();
  const match = useParams();

  const bookNowService = useSelector((state) => state.bookNowService);

  const getTimeSlots = (date) => {
    setScheduleDateAndTime({ schedule_date: date });
    dispatch(getAvailableBookings(match.serviceId, getRawDayMonthYear(date)));
  };

  const width = useMediaquery();

  const chooseTimeSlot = (timeslot) => {
    setScheduleDateAndTime({ timeslot });
    onNext();
  };

  return (
    <>
      <CustomLoader loading={false}>
        <p>
          Choose date for service and select available time slots for the date
          provided{" "}
        </p>

        <DatePicker
          disabled={bookNowService.timeSlotsLoading}
          disabledDate={(current) => {
            let customDate = moment().format("YYYY-MM-DD");
            return current && current < moment(customDate, "YYYY-MM-DD");
          }}
          style={{ height: 55, width: width < 500 ? "90%" : "400px" }}
          onChange={(momentDate, dateString) => {
            if (momentDate?.isValid()) {
              if (momentDate.isBefore(moment.utc().subtract(1, "day"))) {
                message.error("Date cannot be in the past");
              } else {
                getTimeSlots(dateString);
              }
            }

            // getTimeSlots(dateString);
          }}
          placeholder="Select date search for time slots"
        />
      </CustomLoader>

      {formData?.schedule_date && (
        <div className="time-slots my-5">
          <h1>Available time slots</h1>
          <List
            pagination={10}
            loading={bookNowService.timeSlotsLoading}
            dataSource={bookNowService.timeSlots}
            renderItem={(item, index) => (
              <Card key={index} className="my-2 hover:bg-gray-100">
                <div className="flex justify-between items-center ">
                  <p> {getTime(item.time_slot.start_time)} </p>
                  <ArrowRight style={{ fontSize: "1.5rem" }} />
                  <p>{getTime(item.time_slot.end_time)} </p>
                  <Button
                    onClick={() => {
                      chooseTimeSlot(item);
                    }}
                    shape="round"
                    type="primary"
                    className="btn btn-primary"
                  >
                    Select
                  </Button>
                </div>
              </Card>
            )}
          />
        </div>
      )}
    </>
  );
};

export default ChooseTimeSlotForm;
