import { Row, Col, Typography, Empty, Spin, Pagination } from "antd";
import React from "react";
import { Container } from "reactstrap";
import ServiceComponent from "@components/ServiceComponent";
import ListServicesLayout from "@components/layouts/ListServicesLayout";
import useOrganizationProfile from "@utils/custom_hooks/useOrganizationProfile.hook";
import useOrganizationServices from "@utils/custom_hooks/useOrganizationServices";

const OrganizationServicesPage = () => {
  const [services, servicesLoading, onPaginate] = useOrganizationServices();
  const { profile, loading: profileLoading } = useOrganizationProfile();

  return (
    <ListServicesLayout>
      <Container>
        <Typography.Title level={4}>Organization Information</Typography.Title>
        {profileLoading ? (
          <Spin tip="Fetching information">
            <div style={{ width: "100%", height: "150px" }} />
          </Spin>
        ) : (
          <Row className="my-3">
            {/* <Col xs={24}>
            <img src="" alt="Organization logo" />
          </Col> */}
            <Col xs={24} sm={12} lg={2}>
              <Typography style={{ fontWeight: "bold" }}>Name:</Typography>
            </Col>
            <Col xs={24} sm={12} lg={10}>
              <Typography style={{ color: "#cccccc" }}>
                {profile?.name}
              </Typography>
            </Col>
            <Col xs={24} sm={12} lg={2}>
              <Typography style={{ fontWeight: "bold" }}>E-Mail:</Typography>
            </Col>
            <Col xs={24} sm={12} lg={10}>
              <Typography style={{ color: "#cccccc" }}>
                {profile?.email}
              </Typography>
            </Col>
            <Col xs={24} sm={12} lg={3}>
              <Typography style={{ fontWeight: "bold" }}>
                Phone Number:
              </Typography>
            </Col>
            <Col xs={24} sm={12} lg={9}>
              <Typography style={{ color: "#cccccc" }}>
                {profile?.phoneNumber}
              </Typography>
            </Col>
            <Col xs={24} sm={12} lg={3}>
              <Typography style={{ fontWeight: "bold" }}>Location:</Typography>
            </Col>
            <Col xs={24} sm={12} lg={9}>
              <Typography style={{ color: "#cccccc" }}>
                {`${profile?.city} - ${profile?.country}`}
              </Typography>
            </Col>
          </Row>
        )}

        <Typography.Title level={4}>Services Offered</Typography.Title>

        {servicesLoading ? (
          <Spin spinning tip="Fetching Services">
            <div style={{ width: "100%", height: "300px" }}>
              <Empty />
            </div>
          </Spin>
        ) : !servicesLoading && services.list.length === 0 ? (
          <div
            style={{ height: 300 }}
            className="d-flex justify-content-center align-items-center"
          >
            <Empty description="No Service found for this organization" />
          </div>
        ) : (
          <Row style={{ columnGap: "1em", rowGap: "1em" }}>
            {services.paginated?.map((service, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={6}>
                <ServiceComponent service={service} />
              </Col>
            ))}
          </Row>
        )}
        <div className="d-flex justify-content-center my-5">
          <Pagination
            onChange={onPaginate}
            total={services.list.length}
            size="small"
            pageSize={10}
          />
        </div>
      </Container>
    </ListServicesLayout>
  );
};

export default OrganizationServicesPage;
