import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { UnorderedListOutlined, SwapOutlined } from "@ant-design/icons";
import pageRoutes from "@utils/page-routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faCubes, faUsers } from "@fortawesome/free-solid-svg-icons";

const style = { fontSize: "1rem", backgroundColor: "transparent" };

function KoldChainAdminSidebarMenu() {
  return (
    <Menu className="custom-menu-list" style={{ backgroundColor: "#f4f4f4" }}>
      <Menu.Item style={style} key="1">
        <div className="custom-menu-item">
          <span className="custom-menu-icon">
            <FontAwesomeIcon icon={faCubes} />
          </span>
          <Link style={{ fontSize: 18 }} to={pageRoutes.dashboard}>
            Dashboard
          </Link>
        </div>
      </Menu.Item>
      <Menu.Item style={style} key="2">
        <div className="custom-menu-item">
          <span className="custom-menu-icon">
            <FontAwesomeIcon icon={faUsers} />
          </span>
          <Link to={pageRoutes.external_entity}>External Entities</Link>
        </div>
      </Menu.Item>
      <Menu.SubMenu
        style={{ fontSize: "1rem" }}
        icon={<UnorderedListOutlined style={{ fontSize: "1.5rem" }} />}
        title="Entity Services"
        key="3"
      >
        <Menu.Item key="3.1">
          <Link to={pageRoutes.entity_services}>Approved</Link>
        </Menu.Item>
        <Menu.Item key="3.2">
          <Link to={pageRoutes.entity_services_unapproved}>Unapproved</Link>
        </Menu.Item>
        <Menu.Item style={style} key="3.3">
          <Link to={pageRoutes.entity_services_upload}>Upload</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        style={{ fontSize: "1rem" }}
        icon={<SwapOutlined style={{ fontSize: "1.5rem" }} />}
        title="Tasks"
        key="4"
      >
        <Menu.Item style={style} key="4.1">
          <Link to={pageRoutes.task_services}>Services</Link>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        style={{ fontSize: "1rem" }}
        icon={<FontAwesomeIcon icon={faCog} style={{ fontSize: "1.5rem" }} />}
        title="Settings"
        key="5"
      >
        <Menu.Item style={style} key="5.1">
          <Link to={pageRoutes.commission_rate}>Commission Rate</Link>
        </Menu.Item>
        <Menu.Item style={style} key="5.2">
          <Link to={pageRoutes.sub_user}>Sub User</Link>
        </Menu.Item>
        <Menu.Item style={style} key="5.3">
          <Link to={pageRoutes.lab_notifications}>Lab Notifications</Link>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
}

export default KoldChainAdminSidebarMenu;
