import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCheck,
  faListUl,
  faPlus,
  faCubes,
  // faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import pageRoutes from "@utils/page-routes.js";

const style = { fontSize: "1rem", backgroundColor: "transparent" };

function DoctorSidebarMenu() {
  return (
    <Menu className="custom-menu-list">
      <Menu.Item key={"0"}>
        <div className="custom-menu-item">
          <span className="custom-menu-icon">
            <FontAwesomeIcon icon={faCubes} />
          </span>
          <Link style={{ fontSize: 18 }} to={pageRoutes.dashboard}>
            Dashboard
          </Link>
        </div>
      </Menu.Item>
      <Menu.SubMenu
        style={style}
        icon={<FontAwesomeIcon icon={faUserCheck} />}
        title="Bookings"
        key="1"
      >
        <Menu.Item
          style={{ backgroundColor: "transparent" }}
          key="1.2"
          icon={<FontAwesomeIcon icon={faPlus} />}
        >
          <Link to={pageRoutes.bookings_book_now}> Book now</Link>
        </Menu.Item>
        {/* <Menu.Item
            style={{ backgroundColor: "transparent" }}
            key="1.3"
            icon={<FontAwesomeIcon icon={faListUl} />}
          >
            <Link to={pageRoutes.bookings_history}> History</Link>
          </Menu.Item> */}
      </Menu.SubMenu>

      <Menu.SubMenu
        style={style}
        icon={<FontAwesomeIcon icon={faUserCheck} />}
        title="Referral"
        key="2"
      >
        <Menu.Item
          style={{ backgroundColor: "transparent" }}
          key="2.2"
          icon={<FontAwesomeIcon icon={faPlus} />}
        >
          <Link to={pageRoutes.referral}> Refer</Link>
        </Menu.Item>
        <Menu.Item
          style={{ backgroundColor: "transparent" }}
          key="2.3"
          icon={<FontAwesomeIcon icon={faListUl} />}
        >
          <Link to={pageRoutes.referral_history}> History</Link>
        </Menu.Item>
        {/* <Menu.Item
          style={{ backgroundColor: "transparent" }}
          key="2.4"
          icon={<FontAwesomeIcon icon={faDollarSign} />}
        >
          <Link to={pageRoutes.referral_bonus}>Bonus</Link>
        </Menu.Item> */}
      </Menu.SubMenu>
    </Menu>
  );
}

export default DoctorSidebarMenu;
