import { Typography, Table, Tag } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { EyeOutlined } from "@ant-design/icons";

import { fetchAttendedBookingsThunk } from "@store/thunks/booking.thunk";
import useCustomSelector from "@utils/custom_hooks/useCustomSelector";
import BookingFilterSearchHOC from "@utils/hocs/booking.hoc";

const AttendedBookings = ({ setCurrentBooking }) => {
  const dispatch = useDispatch();
  const bookingState = useCustomSelector((state) => state.booking);

  useEffect(() => {
    dispatch(fetchAttendedBookingsThunk());
  }, [dispatch]);

  return (
    <Table
      loading={bookingState.loading}
      dataSource={bookingState.state.filtered}
      // dataSource={bookingState.state.filtered?.sort((a, b) =>
      //   moment(a.created_at).diff(b.created_at)
      // )}
      pagination={10}
      style={{ overflowX: "auto" }}
      columns={[
        {
          title: "Service Name",
          key: "booked_entity_service",
          dataIndex: "booked_entity_service",
          render: (value) => (
            <Typography>{value.service_name || "Not specified"}</Typography>
          ),
        },
        {
          title: "Service Type",
          key: "booked_entity_service",
          dataIndex: "booked_entity_service",
          render: (value) => <Typography>{value.service}</Typography>,
        },
        {
          title: "Booking code",
          key: "code",
          dataIndex: "code",
        },
        {
          title: "Client Name",
          key: "booked_by",
          dataIndex: "booked_by",
          render: (value) => (
            <Typography>{`${value.firstName} ${value.lastName} `}</Typography>
          ),
        },
        {
          title: "Price",
          key: "booked_entity_service_price",
          dataIndex: "booked_entity_service",
          render: (value) => (
            <Typography>{`${value.price.currency} ${value.price.amount}`}</Typography>
          ),
        },
        {
          title: "Payment status",
          key: "payed",
          dataIndex: "payed",
          render: (value) =>
            value ? (
              <Tag color="green" title="Has paid">
                Paid
              </Tag>
            ) : (
              <Tag color="red" title="Not paid">
                Not paid
              </Tag>
            ),
        },
        // {
        //   title: "Action",
        //   key: "service_name",
        //   render: (value) => (
        //     <div
        //       style={{
        //         display: "flex",
        //         columnGap: ".5em",

        //         alignItems: "center",
        //       }}
        //     >
        //       <EyeOutlined
        //         onClick={() => {
        //           setCurrentBooking(value);
        //         }}
        //         style={{ fontSize: "1.2rem" }}
        //       />
        //     </div>
        //   ),
        // },
      ]}
    />
  );
};

export default BookingFilterSearchHOC(AttendedBookings, "attended bookings");
