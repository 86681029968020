import { Button, Table, Tag, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentWrapper from "@components/ContentWrapper";
import { fetchKoldChainUnApprovedServicesThunk } from "@store/thunks/service.thunk";
import useCustomSelector from "@utils/custom_hooks/useCustomSelector";
import KoldchainApproveModal from "@components/modals/KoldchainApproveModal";

const KoldChainListUnapprovedServices = () => {
  const [selectedService, setSelectedService] = React.useState(null);
  const serviceState = useCustomSelector((state) => state.service);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchKoldChainUnApprovedServicesThunk());
  }, [dispatch]);
  return (
    <>
      <ContentWrapper>
        <Typography.Title level={3} className="heading">
          List of Unapproved Services
        </Typography.Title>

        <Table
          loading={serviceState.loading}
          dataSource={serviceState.state.koldchain.unapproved}
          columns={[
            {
              title: "Name",
              dataIndex: "service",
              key: "name",
              render: (value) => (
                <p>{value?.details.name || "Service name not provided"}</p>
              ),
            },
            {
              title: "Entity Name",
              dataIndex: "bookable_entity_name",
              key: "bookable_entity_name",
            },
            {
              title: "Country",
              dataIndex: "bookable_entity_country",
              key: "bookable_entity_country",
            },
            {
              title: "Price",
              dataIndex: "price_currency",
              key: "currency",
              render: (value, record) => <p>{`${value} ${record.price}`}</p>,
            },
            {
              title: "Payment required",
              dataIndex: "payment_required",
              key: "payment_required",
              render: (value, record) => (
                <p>
                  {value ? (
                    <Tag color="green">true</Tag>
                  ) : (
                    <Tag color="red">false</Tag>
                  )}
                </p>
              ),
            },

            {
              title: "Action",
              dataIndex: "status",
              key: "action",
              render: (value, record) => (
                <div>
                  {value.toLowerCase() !== "archived" &&
                    value.toLowerCase() !== "active" && (
                      <Button
                        onClick={() => setSelectedService(record)}
                        shape="round"
                        type="primary"
                      >
                        Approve
                      </Button>
                    )}
                </div>
              ),
            },
          ]}
        />
      </ContentWrapper>
      <KoldchainApproveModal
        service={selectedService}
        onClose={() => setSelectedService(null)}
        open={!!selectedService}
      />
    </>
  );
};

export default KoldChainListUnapprovedServices;
