import  { useEffect, useState } from 'react'
 const useMediaquery = () => {
    const [width,setWidth] = useState(window.innerWidth)
    useEffect(()=>{
        window.addEventListener("resize",size=>{
            setWidth(window.innerWidth)
        })
    },[])
    return width
}

export default useMediaquery;
